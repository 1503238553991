import { useShow } from "@refinedev/core";

import {
    Show,
    NumberField,
    DateField,
    TextFieldComponent as TextField,
    BooleanField,
} from "@refinedev/mui";

import { Typography, Grid, Box } from "@mui/material";
import React, { useEffect } from "react";

export const AnnouncementsShow = () => {
    useEffect(() => {
        document.title = 'Whtexch - Announcements';
    }, []);
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show title={<Typography variant="h5">Back</Typography>} isLoading={isLoading}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Announcements ID </Typography>
                </Grid>
                <Grid item xs={10}>
                    <NumberField value={record?.announcement_id ?? ""} />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Title </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField value={record?.title} />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Valid Until </Typography>
                </Grid>
                <Grid item xs={10}>
                    <DateField value={record?.valid_until} format={"YYYY/MM/DD HH:MM:ssZ"} />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Content </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Box sx={{ maxWidth: '85vw' }} dangerouslySetInnerHTML={{ __html: record?.content }} />
                </Grid>
            </Grid>
        </Show>
    );
};
