import { Create } from "@refinedev/mui";
import { Box, TextField, Typography } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import '../pages.scss'
import {useEffect} from "react";
import { useCreate, useNavigation } from "@refinedev/core";

export const TicketsCreate = () => {
    useEffect(() => {
        document.title = 'Whtexch - Tickets';
    }, []);
    const {list} = useNavigation()
    const {mutate: create} = useCreate();
    const {
        saveButtonProps,
        refineCore: {formLoading},
        register,
        formState: {errors},
        handleSubmit
    } = useForm();

    const onFinish = (params) => {
        // @ts-ignore
        // TODO only 1 topic
        params.topic_id = 1
        create({
            resource: "tickets",
            values: params
        }, {
            onSuccess: (data, variables, context) => {
                list("tickets")
            }
        })
    }
    return (
        <Create title={<Typography variant="h5">Back</Typography>} isLoading={formLoading} saveButtonProps={{...saveButtonProps, onClick: handleSubmit(onFinish)}}>
            <Box
                component="form"
                className={"input-form"}
                autoComplete="off"
            >
                <TextField
                    {...register("subject", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.subject}
                    helperText={(errors as any)?.subject?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    type="text"
                    label="Subject"
                    name="subject"
                />

                <TextField
                    {...register("text", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.text}
                    helperText={(errors as any)?.text?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    multiline={true}
                    minRows={10}
                    type="text"
                    label="Text"
                    name="text"
                />
            </Box>
        </Create>
    );
};
