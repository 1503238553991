import { Create } from "@refinedev/mui";
import { Box, TextField, Stack, Typography, Switch } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useEffect, useRef } from "react";
import { IAnnouncements } from "shared-libs/src/interfaces";
import BundledEditor from "shared-libs/src/components/BundledEditor";
import { useCreate, useNavigation } from "@refinedev/core";
import '../pages.scss'


export const AnnouncementsCreate = () => {
    useEffect(() => {
        document.title = 'Whtexch - Announcements';
    }, []);
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm<IAnnouncements>();
    const { list } = useNavigation()
    const editorRef = useRef(null);
    const { mutate: create } = useCreate<IAnnouncements>();
    const onFinish = (params) => {
        // @ts-ignore
        params.content = editorRef?.current.getContent()
        create({
            resource: "announcements",
            values: params
        }, {
            onSuccess: (data, variables, context) => {
                list("announcements")
            }
        })
    }

    return (
        <Create title={<Typography variant="h5">Back</Typography>} isLoading={formLoading} saveButtonProps={{ ...saveButtonProps, onClick: handleSubmit(onFinish) }}>
            <Box
                component="form"
                className={"input-form"}
                autoComplete="off"
            >
                <TextField
                    {...register("title", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Title"
                    name="title"
                />


                <Controller
                    name={"content"}
                    control={control}
                    render={({ field }) => {
                        return <BundledEditor
                            {...field}
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={field.value}
                        />
                    }
                    }
                />

                <TextField
                    {...register("valid_until", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.valid_until}
                    helperText={(errors as any)?.valid_until?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="datetime-local"
                    label="Valid Until"
                    name="valid_until"
                />

            </Box>
        </Create>
    );
};
