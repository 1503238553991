import { Box, Button, IconButton, List, Tab, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useCustomMutation, useModal } from '@refinedev/core';
import { DateField, useDataGrid } from '@refinedev/mui'
import React, { useEffect, useState } from 'react'
import { GlobalConfig } from 'shared-libs/src/GlobalConfig';
import { Done, DoneAll, DoNotDisturb } from '@mui/icons-material';
import { User } from 'shared-libs/src/interfaces';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DownloadedAndBurnedList from './DownloadedAndBurnedList';
import ManuallyBurnedList from './ManuallyBurnedList';
import PreviewRequestList from 'pages/preview_requests/list';
import OriginalRequestErrorsList from 'pages/original_requests_errors/list';
import BurnList from 'pages/burn_list/list';
import { BurnAudit } from './BurnedLogs'

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}



export default function BurnedList({ }) {

    const [value, setValue] = useState('3');

    const handleChange = (_evt, value) => {
        setValue(value);
    }


    return <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Download requests" value="1" />
                    <Tab label="Request errors" value="2" />
                    <Tab label="Burn request" value="3" />
                    <Tab label="Downloaded & Burned" value="4" />
                    <Tab label="Manually Burned" value="5" />
                    <Tab label="Audit log" value="6" />
                </TabList>
            </Box>
            <TabPanel value="1"><PreviewRequestList /></TabPanel>
            <TabPanel value="2"><OriginalRequestErrorsList /></TabPanel>
            <TabPanel value="3"><BurnList /></TabPanel>
            <TabPanel value="4"><DownloadedAndBurnedList /></TabPanel>
            <TabPanel value="5"><ManuallyBurnedList /></TabPanel>
            <TabPanel value="6"><BurnAudit /></TabPanel>
        </TabContext>
    </Box>

}