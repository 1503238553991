import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { CircularDeterminate, DateField, List, NumberField, useDataGrid } from "@refinedev/mui";
import React, { useEffect, useState } from "react";

import { Done, DoneAll, RemoveDone } from '@mui/icons-material';
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { useCustomMutation, useNotification } from "@refinedev/core";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { User } from "shared-libs/src/interfaces";
import { useCurrencyStore } from "hooks/useCurrency";
import { isLabeledStatement } from "typescript";



const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm" value={params.value} style={{marginBottom: 0}}/>;
}
const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}

export const BankList = ({ movement_type }: { movement_type: 'buy' | 'sell' }) => {

    const { open } = useNotification()

    const { getValue, init, currency } = useCurrencyStore()

    const { mutate: approve, isLoading: isLoadingApprove } = useCustomMutation()
    const { mutate: confirm, isLoading: isLoadingConfirmed } = useCustomMutation()
    const { mutate: reject, isLoading: isLoadingRejected } = useCustomMutation()
    console.log('isLoadingConfirmed', isLoadingConfirmed)


    const handleConfirmButton = id => () => {
        confirm({
            url: '/bank/confirm_movement',
            values: {
                movement_id: id
            },
            method: 'post'
        }, {
            onSuccess: ({ }) => {
                open?.({ message: 'Confirmed', type: 'success' })
                refetch()
            },
            onError: () => {
                refetch()
            }

        })
    }
    const handleApproveButton = id => () => {
        approve({
            url: '/bank/approve_movement',
            values: {
                movement_id: id
            },
            method: 'post'
        }, {
            onSuccess: ({ }) => {
                open?.({ message: 'Approved', type: 'success' })
                refetch()
            },
            onError: () => {
                refetch()
            }
        })

    }


    const handleRejectButton = id => () => {
        reject({
            url: '/bank/reject_movement',
            values: {
                movement_id: id
            },
            method: 'post'
        }, {
            onSuccess: ({ }) => {
                open?.({ message: 'Rejectd', type: 'success' })
                refetch()
            },
            onError: () => {
                refetch()
            }
        })

    }



    const columns = React.useMemo<GridColumns<{
        movement_id: number,
        is_executed: number,
        bcid_user_data?: User,
        created_by_user_data?: User,
        approved_by_user_data?: User,
        confirmed_by_user_data?: User,
        rejected_by_user_data?: User,
    }>>(() => [
        { field: "movement_id", headerName: "Movement ID", flex: 0.6, type: "number", headerAlign: "left" },
        {
            field: "user", headerName: "User", flex: 1,
            valueGetter: ({ row }) => `${row.bcid_user_data?.profile_data?.firstname} ${row.bcid_user_data?.profile_data?.lastname}`,
            renderCell: ({ row }) => `${row.bcid_user_data?.profile_data?.firstname} ${row.bcid_user_data?.profile_data?.lastname}`
        },
        { field: "bcid", headerName: "BCID", flex: 0.5 },
        { field: "movement_description", headerName: "Description", flex: 2 },
        { field: "created", headerName: "CREATION DATE & TIME (UTC)", type: 'date', flex: 0.75, renderCell: dateRender },
        { field: "wht_amount", headerName: "Amount", flex: 1.25, renderCell: ({ value }) => getValue(value) },
        { field: "movement_type", headerName: "Type", flex: 0.25 },

        {
            field: "approved_by_user_data", valueGetter: ({ row }) => row.approved_by_user_data ? `${row.approved_by_user_data?.profile_data?.firstname} ${row.approved_by_user_data?.profile_data?.lastname}` : '-',
            headerName: "Approved by", flex: 0.75, renderCell: ({ row }) => row.approved_by_user_data ? `${row.approved_by_user_data?.profile_data?.firstname} ${row.approved_by_user_data?.profile_data?.lastname}` : '-'
        },
        {
            field: "confirmed_by_user_data", valueGetter: ({ row }) => row.confirmed_by_user_data ? `${row.confirmed_by_user_data?.profile_data?.firstname} ${row.confirmed_by_user_data?.profile_data?.lastname}` : '-',
            headerName: "Confirmed by", flex: 0.75, renderCell: ({ row }) => row.confirmed_by_user_data ? `${row.confirmed_by_user_data?.profile_data?.firstname} ${row.confirmed_by_user_data?.profile_data?.lastname}` : '-'
        },

        {
            headerName: "Actions",
            field: "actions",
            flex: 0.5,
            renderCell: function render(params) {
                if (params?.row?.is_executed) {
                    return <Typography >Approved</Typography>;
                } else if (params?.row?.rejected_by_user_data) {
                    return <Typography >Rejected</Typography>;
                } {
                    return (isLoadingApprove || isLoadingConfirmed || isLoadingRejected) ? <CircularProgress size={10} /> : <>
                        <IconButton color={!!params?.row?.approved_by_user_data ? 'success' : undefined} disabled={isLoadingApprove || isLoadingConfirmed || isLoadingRejected || !!params?.row?.approved_by_user_data} onClick={handleApproveButton(params?.row?.movement_id)} ><Done /></IconButton>
                        {params?.row?.approved_by_user_data ? <IconButton disabled={isLoadingApprove || isLoadingConfirmed || isLoadingRejected} onClick={handleConfirmButton(params?.row?.movement_id)} ><DoneAll /></IconButton> : null}
                        <IconButton disabled={isLoadingApprove || isLoadingConfirmed || isLoadingRejected} onClick={handleRejectButton(params?.row?.movement_id)} ><RemoveDone /></IconButton>

                    </>;
                }
            },
            ...GlobalConfig.actionColumnConfig
        },
    ], [isLoadingConfirmed, isLoadingApprove, isLoadingRejected]);





    useEffect(() => {
        document.title = 'Whtexch - Bank';
    }, []);


    const { dataGridProps, tableQueryResult: { refetch }, filters, setFilters } = useDataGrid({
        pagination: {
            mode: "off"
        },
        resource: 'bank',
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            // refetchInterval: 10000,
            queryKeyHashFn: () => `bank_${movement_type}`
        },
        sorters: {
            initial: [
                { field: 'movement_id', order: 'desc' }
            ]
        },
        filters: {
            permanent: [
                { field: 'movement_type', operator: 'eq', value: movement_type }
            ]
        }  
    });
    useEffect(() => {
        if (filters.length > 1) {
            setFilters([])
        }
    }, [filters])
    useEffect(() => {
        console.log('aaaa')
        setInterval(() => {
            console.log(`filters.length = ${filters.length}`)
            refetch()
        }, 10000)
    }, [])
    return (
        <List>
            {init}
            <DataGrid getRowId={({ movement_id }) => movement_id}  {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};

