import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, 100, users, (list)|(create),
p, 100, users/*, (edit)|(show)|(delete)

p, 100, customers, (list)|(create),
p, 100, customers/*, (edit)|(show)|(delete)

p, 100, bank, (list)|(create),
p, 100, bank/*, (edit)|(show)|(delete)

p, 100, preview_requests, (list)
p, 100, burn_list, (list)
p, 100, original_requests_errors, (list)
p, 100, burned, (list)

p, 100, orders, (list),
p, 100, trading, (list),

p, 100, news, (list)|(create),
p, 100, news/*, (edit)|(show)|(delete)
p, 100, announcements, (list)|(create),
p, 100, announcements/*, (edit)|(show)|(delete)


p, 99, staticlink, (list)|(create),
p, 99, staticlink/*, (edit)|(show)|(delete)

p, 100, tickets, (list)|(create),
p, 100, tickets/*, (edit)|(show)|(delete)

p, 100, aft, (list)|(create),
p, 100, aft, (burn)|(togglevalidity),
p, 40, aft, (list)|(create),
p, 100, aft/*, (show),

p, 100, white_list, (show)|(list)|(create)|(edit),
p, 100, squares, (show)|(list)|(create)|(edit),
p, 100, squares/*, (show)|(list)|(create)|(edit),


p, 20, customers, list,
p, 20, customers/*, (edit)|(show)

p, 21, customers, list,
p, 21, customers/*, (edit)|(show)

p, 22, news, (list)|(create),
p, 22, news/*, (edit)|(show)|(delete)

p, 23, tickets, (list)|(create),
p, 23, tickets/*, (edit)|(show)|(delete)

p, 30, bank, (list)|(create),
p, 30, bank/*, (edit)|(show)|(delete)

p, 31, bank, (list)|(create),
p, 31, bank/*, (edit)|(show)|(delete)

`);