import { Box, Button, Card, CardContent, CardHeader, IconButton, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { CanAccess, useCustom, useCustomMutation, useModal } from "@refinedev/core";
import { CreateButton, DateField, ShowButton, TagField, useDataGrid } from "@refinedev/mui";
import React, { useEffect, useState } from "react";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import ListWithErrors from "./ListWithErrors";
import OTPModal from "components/OtpModal";
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment'

import ToggleOn from '@mui/icons-material/ToggleOnOutlined'
import ToggleOff from '@mui/icons-material/ToggleOffTwoTone'
import { LoadingButton } from "@mui/lab";
import ListCompletedToAddToExchange from "./ListCompletedToAddToExchange";
// import ListCompletedToAddToExchange from "./ListCompletedToAddToExchange";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, minWidth: 235 }}>
                <LinearProgress variant="determinate" color={"secondary"} {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


function PremarketTagsCell({ value, row }) {
    const { mutate, data } = useCustomMutation({})

    useEffect(() => {
        if (!row.is_tradable && !data) {
            // might be linked to the fact that there's a download pending
            mutate({
                method: 'post',
                url: '/aftoriginal/search',
                values: {
                    aftcontract_id: row.aftcontract_id
                }
            })
        }
    }, [])
    return <>
        {row.premarket_sold_tokens == 0 && value?.length ? <TagField color="warning" value={'Primary expired'} /> : null}
        {row.is_tradable && row.premarket_sold_tokens > 0 && value?.length ? <TagField color="primary" value={'Secondary'} /> : null}
        {!value?.length ? <TagField color="primary" value={'Primary'} /> : null}
        {!row.is_tradable && data?.data?.length ? <TagField color="error" value={'Download pending'} /> : null}
    </>
}

function LinearProgressLabel({ value }) {
    const label = {
        start_process: 'Initialization',
        get_image_from_repository: 'Get Image From Repository',
        create_image_pieces: 'Fragmentation',
        create_smartcontract: 'Smart Contract installation',
        mint_smartcontract_tokens: 'Token Mining',
        check_smartcontract_tokens_integrity: 'Check Smart Contract Token Integrity',
        close_smartcontract_mint: 'Closing Mint',
        end_process: 'Completion',
    }
    return (<TagField value={label[value] ?? ''} />)
}

export const AftList:
    React.FC = () => {
        const { mutate: discovery } = useCustomMutation()
        const [currentData, setCurrentData] = useState<{ aftcontract_id: string, action: 'burn' | 'setTradable', is_tradable?: boolean }>()
        useEffect(() => {
            document.title = 'Whtexch - AFT List';
            discovery({
                url: '/tokenizer/discover_masterpieces',
                method: "post",
                values: {}
            })
        }, []);

        const { dataGridProps, tableQueryResult: { refetch, isRefetching } } = useDataGrid({
            resource: "aft_admin",
            pagination: {
                mode: "off"
            },
            queryOptions: {
                ...GlobalConfig.adminQueryOptions
            },
            filters: {
                permanent: [{
                    field: 'show_artwork',
                    operator: 'eq',
                    value: true
                }]
            }
        })

        const { dataGridProps: dataGridPropsMasterpiece } = useDataGrid({
            resource: "masterpieces",
            pagination: {
                mode: "off"
            },
            queryOptions: {
                ...GlobalConfig.adminQueryOptions,
                queryKeyHashFn: () => "is_started_tokenizer=1&is_ended_tokenizer=0",
                queryHash: "is_started_tokenizer=1&is_ended_tokenizer=0",
                refetchInterval: 5000
            },
            filters: {
                permanent: [
                    {
                        field: "is_started_tokenizer",
                        operator: "eq",
                        value: "1",
                    },
                    {
                        field: "is_ended_tokenizer",
                        operator: "eq",
                        value: "0"
                    }
                ]
            }
        })

        const isMintInProgress = dataGridPropsMasterpiece.rowCount > 0


        const modal = useModal();
        const { mutate: burn, isLoading: burning, variables } = useCustomMutation();
        const { mutate: setIsTradable, isLoading: settingTradable, variables: settingVariables } = useCustomMutation();
        const handleBurn = (aftcontract_id) => async (otp) => {
            burn({
                url: '/utility/unsafe_aft_burn',
                method: 'post',
                values: {
                    aftcontract_id,
                    otp
                }
            }, {
                onSuccess: () => {
                    refetch()
                }
            })
        }

        const handleSetIsTradable = (aftcontract_id, isTradable) => async (otp) => {
            setIsTradable({
                url: '/utility/set_is_tradable',
                method: 'post',
                values: {
                    aftcontract_id,
                    otp,
                    is_tradable: isTradable
                }
            }, {
                onSuccess: () => {
                    refetch()
                }
            })
        }

        const columns = React.useMemo<GridColumns<any>>(
            () => [
                {
                    field: "aftcontract_id", headerName: "AFT id", flex: 1, valueGetter: ({ row }) => row.artwork.work_of_ingenuity || row.aftcontract_id
                },
                {
                    field: "creation_date", headerName: "Date of Upload", type: "date", flex: 1,
                    renderCell: ({ value }) => {
                        return <DateField format="DD/MM/YYYY" value={value} />
                    }
                },
                {
                    field: "premarket_closing_date", headerName: "Market", flex: 1,
                    renderCell: ({ value, row }) => <PremarketTagsCell row={row} value={value} />
                },
                {
                    headerName: "Actions", field: "actions", flex: 1,
                    renderCell: function render({ row }) {
                        return <>
                            <ShowButton variant="actionTable" hideText recordItemId={row.aftcontract_id} />
                            <CanAccess resource="aft" action="burn">
                                <LoadingButton disabled={burning || isMintInProgress}
                                    loading={burning && (variables?.values as any).aftcontract_id == row.aftcontract_id}
                                    onClick={() => {
                                        setCurrentData({ ...row, action: 'burn' })
                                        modal.show()
                                    }}><LocalFireDepartment /></LoadingButton>
                            </CanAccess>
                            <CanAccess resource="aft" action="togglevalidity">
                                <LoadingButton disabled={burning || (row.premarket_sold_tokens == 0 && row.premarket_closing_date !== '')} loading={(settingTradable && (settingVariables?.values as any).aftcontract_id == row.aftcontract_id) || isRefetching} onClick={() => {
                                    setCurrentData({ ...row, action: 'setTradable' })
                                    modal.show()
                                }}>{row.is_tradable ? <ToggleOn /> : <ToggleOff />}</LoadingButton>
                            </CanAccess>

                        </>;
                    },
                    ...GlobalConfig.actionColumnConfig
                },
            ], [dataGridProps]);
        const currentState = useCustom({
            url: '/tokenizer/masterpiece/current_state',
            method: 'post',
            queryOptions: {
                refetchInterval: 5000,
                onError: () => {
                    // silent error
                }
            }
        })
        const { mutate: pauseMint, ...pauseMintRest } = useCustomMutation();
        const { mutate: resumeMint, ...resumeMintRest } = useCustomMutation();
        const pause = () => {
            pauseMint({
                url: '/tokenizer/masterpiece/pause_mint',
                method: 'post',
                values: {}
            }, {
                onSuccess: () => {
                    currentState.refetch()
                }
            })
        }

        const resume = () => {
            resumeMint({
                url: '/tokenizer/masterpiece/resume_mint',
                method: 'post',
                values: {}
            }, {
                onSuccess: () => {
                    currentState.refetch()
                }
            })
        }
        const columnsMasterpieces = React.useMemo<GridColumns<any>>(
            () => [
                {
                    field: "masterpiece_filename", headerName: "Masterpiece Filename", flex: 1,
                },
                {
                    field: "created", headerName: "Date of Upload", type: "date", flex: 1,
                    renderCell: ({ value }) => <DateField format="DD/MM/YYYY" value={value} />
                },
                {
                    field: "tokenizer_status_name", headerName: "Status", flex: 1,
                    renderCell: ({ value }) => <LinearProgressLabel value={value} />
                },
                {
                    field: "tokenizer_status_percentage", headerName: "Progress", flex: 1,
                    renderCell: ({ value }) => {


                        return (
                            <>
                                {/* <LoadingButton loading={currentState.isLoading || currentState.isRefetching || pauseMintRest.isLoading || resumeMintRest.isLoading} variant="text" size="small">
                                    {currentState.data?.data.result == "0" ? <PauseCircleIcon onClick={pause} /> : <PlayCircleIcon onClick={resume} />}
                                </LoadingButton> */}
                                <LinearProgressWithLabel value={value} />
                            </>)
                    }
                },

            ], [dataGridPropsMasterpiece]);

        return (
            <>
                <Box sx={{ displax: 'flex' }} className="page-title-container">
                    <Box>
                        <Typography variant="h1">AFT</Typography>
                    </Box>
                </Box>
                {currentData && <OTPModal confirmText="Confirm?" message={currentData.action === 'burn' ? "Burn" : "Suspend"} modal={modal} title={currentData && ((currentData.action === 'burn' ? "Burn " : "Suspend ") + currentData.aftcontract_id)} onConfirm={async (otp) => {
                    if (currentData.action === 'burn') {
                        await handleBurn(currentData.aftcontract_id)(otp);
                    } else {
                        await handleSetIsTradable(currentData.aftcontract_id, !currentData.is_tradable)(otp);

                    }
                }} />}






                {!dataGridPropsMasterpiece.loading && dataGridPropsMasterpiece.rows.length > 0 &&
                    <Card sx={{ mb: 4 }} title={'AFT in progress'}>
                        <CardHeader title={'AFT in progress'} />
                        <CardContent>
                            <DataGrid getRowId={(row) => row.masterpiece_filename} {...dataGridPropsMasterpiece}
                                columns={columnsMasterpieces} autoHeight {...GlobalConfig.dataGridConfig}
                                hideFooter={true} />
                        </CardContent>
                    </Card>
                }
                <ListWithErrors />
                <ListCompletedToAddToExchange onAddComplete={() => {
                    refetch();
                }} />

                <Card sx={{ mb: 2 }}>
                    <CardHeader className="panel-header" title="List"></CardHeader>
                    <CardContent className="card-whtex">
                        <Box sx={{ display: 'flex', justifyContent: 'end', margin: '20px 0' }}>
                            <CreateButton variant="create" />
                        </Box>
                        <DataGrid {...dataGridProps} getRowId={(row) => row.aftcontract_id} columns={columns} autoHeight {...GlobalConfig.dataGridConfig} />
                    </CardContent>
                </Card>
            </>
        );
    };