import { Button, IconButton, List, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useCustomMutation, useModal } from '@refinedev/core';
import { DateField, useDataGrid } from '@refinedev/mui'
import React, { useEffect, useState } from 'react'
import { GlobalConfig } from 'shared-libs/src/GlobalConfig';
import { Done, DoneAll, DoNotDisturb } from '@mui/icons-material';
import { User } from 'shared-libs/src/interfaces';

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}


const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.value} />;
}

export default function ManuallyBurnedList({ }) {
    const { dataGridProps, tableQueryResult: { refetch, ...tableQueryResult } } = useDataGrid({
        resource: 'burned',
        pagination: {
            mode: 'off'
        },
        filters: {
        },
        sorters: {
            initial: [
                { field: 'removed', order: 'desc' }
            ]
        },

    })





    const columns: GridColumns<{
        aftcontract_id: string,
        removed: string
    }> = React.useMemo(() => [
        { field: "aftcontract_id", headerName: "Masterpiece ID", flex: 1, type: "number", headerAlign: "left", },
        { field: "removed", headerName: "Removed", flex: 1, renderCell: dateRender },


    ], []);

    return (<>

        <List>
            <DataGrid getRowId={({ aftcontract_id, bcid }) => `${aftcontract_id}-${bcid}`}  {...dataGridProps} {...dataGridCommonProps} columns={columns} loading={tableQueryResult.isLoading || tableQueryResult.isRefetching} />
        </List>
    </>
    );
}