import React, { useEffect } from "react";
import { useDataGrid, DateField, BooleanField, ShowButton, EditButton, List } from "@refinedev/mui";
import { Stack } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { IAnnouncements } from "shared-libs/src/interfaces";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";

const dateRender = function render(params) {
    return <DateField format="YYYY/MM/DD" value={params.value} />;
}

const columns: GridColumns<IAnnouncements> = [
    { field: "announcements_id", headerName: "Announcements ID", flex: 1, type: "number", headerAlign: "left" },
    { field: "title", headerName: "Title", flex: 1 },
    { field: "content", headerName: "Content", flex: 1 },

    { field: "valid_until", headerName: "Valid Until", flex: 1, type: "date", renderCell: dateRender },
    {
        headerName: "Actions",
        field: "actions",
        minWidth: 200,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        renderCell: function render(params) {
            return <Stack gap={2} direction={"row"}>
                <ShowButton variant="actionTable" hideText recordItemId={params.row.announcement_id} />
                <EditButton variant="actionTable" hideText recordItemId={params.row.announcement_id} />
            </Stack>;
        },
    },
];

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}

export const AnnouncementsList: React.FC = () => {
    useEffect(() => {
        document.title = 'Whtexch - Announcements';
    }, []);
    const { dataGridProps } = useDataGrid<IAnnouncements>({
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions
        }
    });
    return (
        <List canCreate={true} createButtonProps={{ variant: 'actionDetailPage' }}>
            <DataGrid {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};