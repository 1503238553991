export const LocalStorageProvider = {
    signatureId: "",
    signatureKey: "",
    wsToken: "",
    env: (process.env.REACT_APP_TYPE_APP as string) + "_",
    getSignatureId: () => {
        if (!LocalStorageProvider.signatureId) {
            LocalStorageProvider.signatureId = LocalStorageProvider.getSessionData("signatureId")
        }
        return LocalStorageProvider.signatureId
    },
    getSignatureKey: () => {
        if (!LocalStorageProvider.signatureKey) {
            LocalStorageProvider.signatureKey = LocalStorageProvider.getSessionData("signatureKey")
        }
        return LocalStorageProvider.signatureKey
    },
    getWsToken: () => {
        if (!LocalStorageProvider.wsToken) {
            LocalStorageProvider.wsToken = LocalStorageProvider.getSessionData("wsToken")
        }
        return LocalStorageProvider.wsToken
    },
    getSessionData: (name: string) => {
        const env_name = LocalStorageProvider.env + name;
        if (!sessionStorage) {
            return LocalStorageProvider.getCookie(name)
        }
        const userString = sessionStorage.getItem(env_name);
        if (userString) {
            try {
                return JSON.parse(userString)
            } catch (e) {
                sessionStorage.removeItem(env_name)
                return null
            }
        }
        return null
    },
    getCookie: (cname: string) => {
        cname = LocalStorageProvider.env + cname;
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(";");
        let i;
        let c;
        for (i = 0; i < ca.length; i += 1) {
            c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    },

    setCookie: (cname: string, cvalue: string) => {
        cname = LocalStorageProvider.env + cname
        document.cookie = cname + "=" + cvalue + ";path=/;SameSite=Strict";
    },
    setSessionData: (name: string, value: string) => {
        const env_name = LocalStorageProvider.env + name;
        if (!sessionStorage) {
            LocalStorageProvider.setCookie(name, value)
        } else {
            sessionStorage.setItem(env_name, JSON.stringify(value))
        }
        if (name === "signatureId") {
            LocalStorageProvider.signatureId = value
        }
        if (name === "signatureKey") {
            LocalStorageProvider.signatureKey = value
        }
    },
    delSessionData: (name: string) => {
        const env_name = LocalStorageProvider.env + name
        if (!sessionStorage) {
            LocalStorageProvider.delCookie(name)
        } else {
            sessionStorage.removeItem(env_name)
        }
        if (name === "signatureId") {
            LocalStorageProvider.signatureId = ""
        }
        if (name === "signatureKey") {
            LocalStorageProvider.signatureKey = ""
        }
    },

    delCookie: (cname: string) => document.cookie = LocalStorageProvider.env + cname + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;SameSite=Strict",

    twoDigits: (v: number) => ("0" + v).slice(-2),

    stamp: (len: number = 14) => {
        const date = new Date();
        return [
            date.getUTCFullYear(),
            LocalStorageProvider.twoDigits(date.getUTCMonth() + 1),
            LocalStorageProvider.twoDigits(date.getUTCDate()),
            LocalStorageProvider.twoDigits(date.getUTCHours()),
            LocalStorageProvider.twoDigits(date.getUTCMinutes()),
            LocalStorageProvider.twoDigits(date.getUTCSeconds())
        ].join("").substring(0, len);
    }
}