import {AuthProvider} from "@pankod/refine-core";
import {RequestProvider} from './RequestProvider'
import {TLoginData, TLogoutData, TRegisterData} from "@pankod/refine-core/dist/contexts/auth/IAuthContext";
import {Role, Status} from '../models'

export const Auth: AuthProvider = {
    getUserIdentity: (params?: any) => {
        return new Promise<any>((resolve, reject) => {
            resolve(RequestProvider.getUser())
        })
    },
    login: ({email, password, otp_now}) => {
        console.log("login")
        // console.log(email, password, otp_now)
        let promiseReturn = RequestProvider.signin(email, password, otp_now);
        return new Promise<TLoginData>((resolve, reject) => {
            //console.log("login return")
            promiseReturn.then((response) => {
                //console.log("login return 2")
                if (response.data.user) {
                    resolve(false)
                } else {
                    reject(response)
                }
            }).catch(reject)
            return promiseReturn
        });
    },
    logout: () => {
        let returnPromise = RequestProvider.signout();
        return new Promise<TLogoutData>((resolve, reject) => {
            returnPromise.then(() => {
                resolve()
                location.replace('/')
            }).catch(reject)
            return returnPromise;
        });
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const permission = Auth.getPermissions()
        return permission.then((params) => {
            return (!params.admin && params.valid) ? Promise.resolve() : Promise.reject()
        })
    },
    getPermissions: () => {
        const user = RequestProvider.getUser()
        let user_status = 0,
            user_role: number[] = [],
            email_is_verified = 0;
        if (user) {
            user_status = user.user_status ?? 0;
            email_is_verified = user.email_is_verified ?? 0;
            user_role = user.roles ?? [];
        }

        return Promise.resolve({
            'loggedin': !!user,
            'valid_email': !!email_is_verified,
            'valid_pending_user': !!(user_status && user_status > Status.Revoked && user_status <= Status.Manager_validated),
            'valid': !!(user_status && user_status == Status.Active),
            'admin': user_role.includes(Role.Admin_Manager_Validator) || user_role.includes(Role.Admin_Board_Validator) || user_role.includes(Role.Super_Admin),
            'super_admin': user_role.includes(Role.Super_Admin),
            'originator': user_role.includes(Role.Originator)
        })
    },
    register: (params: any) => {
        let promiseReturn = RequestProvider.signup(params, true)
        return new Promise<TRegisterData>((resolve, reject) => {
            promiseReturn.then((request) => {
                if (request.data.user) {
                    resolve(false)
                } else {
                    reject()
                }
            }).catch(reject)
            return promiseReturn
        });
    }
};