import { IconButton, List, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useCustomMutation, useModal } from '@refinedev/core';
import { DateField, useDataGrid } from '@refinedev/mui'
import React, { useEffect, useState } from 'react'
import { GlobalConfig } from 'shared-libs/src/GlobalConfig';
import { Done, DoneAll, DoNotDisturb } from '@mui/icons-material';
import { User } from 'shared-libs/src/interfaces';
import OTPModal from 'components/OtpModal';

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}

const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY" value={params.value} />;
}


export default function PreviewRequestList() {


    const { dataGridProps, tableQueryResult: { refetch, ...tableQueryResult } } = useDataGrid({
        resource: 'preview_requests',
        queryOptions: {
            refetchInterval: 10000
        },
        pagination: {
            mode: 'off'
        },
        filters: {
            permanent: [
                { field: 'status', value: 0, operator: 'eq' }
            ]
        },
        sorters: {
            initial: [
                { field: 'updated', order: 'desc' }
            ]
        },

    })

    const { mutate: approve, isLoading } = useCustomMutation()

    const handleApproveButton = (aftcontract_id, bcid, otp) => {
        approve({
            url: '/aftoriginal/approve_download',
            method: 'post',
            values: {
                aftcontract_id,
                bcid,
                otp
            }
        }, {
            onSuccess: () => {
                refetch()
            },
            onError: () => {
                refetch()
            }
        })
    }
    const handleDenyButton = (aftcontract_id, bcid, otp) => {
        approve({
            url: '/aftoriginal/deny_download',
            method: 'post',
            values: {
                aftcontract_id,
                bcid,
                otp
            }
        }, {
            onSuccess: () => {
                return refetch()
            }
        })
    }

    const [currentData, setCurrentData] = useState<{ aftcontract_id?: string, bcid?: number, type?: 'approve' | 'deny' }>({})
    const modal = useModal();
    useEffect(() => {
        if (currentData?.aftcontract_id && currentData?.bcid) {
            modal.show()
        }
    }, [currentData])



    const columns: GridColumns<{
        aftcontract_id: string,
        bcid: number,
        is_executed: number,
        bcid_data?: User,
        approved_one_by_data?: User,
        approved_two_by_data?: User,
        created: string,
        updated: string,
        artwork?: any,
    }> = React.useMemo(() => [
        { field: "aftcontract_id", valueGetter: ({ row }) => row.artwork?.work_of_ingenuity || row?.aftcontract_id, headerName: "Masterpiece ID", flex: 1, type: "number", headerAlign: "left" },
        { field: "bcid_data", headerName: "User", flex: 1, renderCell: ({ row }) => `${row.bcid_data?.profile_data?.firstname} ${row.bcid_data?.profile_data?.lastname}` },
        { field: "approved_one_by_data", headerName: "Approved by", flex: 1, renderCell: ({ row }) => row.approved_one_by_data ? `${row.approved_one_by_data?.profile_data?.firstname} ${row.approved_one_by_data?.profile_data?.lastname}` : '-' },
        { field: "approved_two_by_data", headerName: "Confirmed by", flex: 1, renderCell: ({ row }) => row.approved_two_by_data ? `${row.approved_two_by_data?.profile_data?.firstname} ${row.approved_two_by_data?.profile_data?.lastname}` : '-' },
        { field: "created", headerName: "Created", flex: 1, renderCell: dateRender },
        { field: "updated", headerName: "Last update", flex: 1, renderCell: dateRender },

        {
            headerName: "Actions",
            field: "actions",
            renderCell: function render(params) {
                if (params?.row?.is_executed) {
                    return <Typography >Approved</Typography>;
                } else {
                    return <>
                        <IconButton color={!!params?.row?.approved_one_by_data ? 'success' : undefined} disabled={isLoading || !!params?.row?.approved_one_by_data} onClick={() => setCurrentData({ aftcontract_id: params.row?.aftcontract_id, bcid: params?.row?.bcid, type: 'approve' })} ><Done /></IconButton>
                        {params?.row?.approved_one_by_data ? <IconButton disabled={isLoading || !!params?.row?.approved_two_by_data} onClick={() => setCurrentData({ aftcontract_id: params.row?.aftcontract_id, bcid: params?.row?.bcid, type: 'approve' })} ><DoneAll /></IconButton> : null}


                        <IconButton disabled={isLoading || !!params?.row?.approved_two_by_data} onClick={() => setCurrentData({ aftcontract_id: params.row?.aftcontract_id, bcid: params?.row?.bcid, type: 'deny' })} ><DoNotDisturb /></IconButton>



                    </>;
                }
            },
            ...GlobalConfig.actionColumnConfig
        },
    ], []);

    return (<>
        <OTPModal confirmText='Confirm' message='Insert OTP to continue' modal={modal} onConfirm={async (otp) => {
            switch (currentData.type) {
                case 'approve': handleApproveButton(currentData?.aftcontract_id, currentData?.bcid, otp); break;
                case 'deny': handleDenyButton(currentData?.aftcontract_id, currentData?.bcid, otp); break;
            }

        }} title='OTP' />
        <List>
            <DataGrid getRowId={({ aftcontract_id, bcid }) => `${aftcontract_id}-${bcid}`}  {...dataGridProps} {...dataGridCommonProps} columns={columns} loading={tableQueryResult.isLoading || isLoading || tableQueryResult.isRefetching} />
        </List>
    </>
    );
}