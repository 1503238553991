import {AppBar, Avatar, Typography, Toolbar, Stack, Button} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useGetIdentity, useNavigation} from "@refinedev/core";
import { useCurrencyStore } from "hooks/useCurrency";

export const Header: React.FC = () => {
    const {data: user} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const {show} = useNavigation()
    const gotoProfile = () => {
        show('users', user.id)
    }

    const { toggleCurrency, currency } = useCurrencyStore();
    return (
        <AppBar color="default" position="static" elevation={1}>
            <Toolbar>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Stack direction="row" alignItems="center">
                        <Stack
                            direction="row"
                            gap="4px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {user?.id && <Button variant="text" color="info" onClick={toggleCurrency}>{currency}</Button>}
                            <Button onClick={gotoProfile} variant={"text"} sx={{color: '#000'}}
                                    endIcon={user?.avatar ?
                                        <Avatar src={user?.avatar} alt={user?.firstname}/> :
                                        <AccountCircleIcon sx={{color: '#334155'}}/>
                                    } placeholder={"Profile"}>
                                <Typography variant="subtitle2" sx={{mr: 1}}>
                                    {user?.firstname} {user?.lastname}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};