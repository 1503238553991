import { Box, Button, Card, CardContent, Divider, TextField, Typography } from "@mui/material";
import { useUpdate } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useFormState, useWatch } from "react-hook-form";
import { IStaticLink } from "shared-libs/src/interfaces";

export function EditStaticLink() {

    const {
        saveButtonProps,
        register,
        control,
        formState: { errors, defaultValues },
        handleSubmit
    } = useForm<IStaticLink>();

    const { mutate: update } = useUpdate<IStaticLink>();

    const watch = useWatch({ control })

    const data = { ...watch, }

    const onFinish = (params) => {
        return update({
            resource: "staticlink",
            id: params.staticlink,
            values: params,
            mutationMode: "pessimistic"
        })
    }

    return <Edit title={<Typography variant="h5">Back</Typography>} saveButtonProps={{ ...saveButtonProps, onClick: handleSubmit(onFinish) }} canDelete={true} headerButtons={() => null} >
        <Box display="flex" flexDirection={'column'} justifyContent="space-between" p={3} gap={3}>

        <Box
                width="70%" // Adjust the width as needed
                border="1px solid #ccc"
                borderRadius="8px"
                padding={3}
            component="form"
            autoComplete="off"
            className={"input-form"}
        >
                <Typography variant="h5" mb={2}>
                    Form
                </Typography>
            <TextField
                {...register("staticlink", {
                    required: "This field is required",
                })}
                error={!!(errors as any)?.id}
                helperText={(errors as any)?.id?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                label="ID"
                name="staticlink"
                disabled
            />

            <TextField
                {...register("text", {
                    required: "This field is required",
                })}
                error={!!(errors as any)?.text}
                helperText={(errors as any)?.text?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Text"
                name="text"
            />

            <TextField
                {...register("button_text", {
                    required: "This field is required",
                })}
                error={!!(errors as any)?.button_text}
                helperText={(errors as any)?.button_text?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Button Text"
                name="button_text"
            />

            <TextField
                {...register("button_url", {
                    required: "This field is required",
                })}
                error={!!(errors as any)?.button_url}
                helperText={(errors as any)?.button_url?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Button URL"
                name="button_url"
            />


        </Box>
            <Box
                width="70%" // Adjust the width as needed
                border="1px solid #ccc"
                borderRadius="8px"
                padding={3}
            >
                <Typography variant="h5" mb={2}>
                    Preview
                </Typography>
                <Card>
                    <CardContent>

                        {data?.staticlink ? <>
                            <>
                                <p>{data.text}</p>
                                <Button target="_blank" download={true} href={data?.button_url}>{data.button_text}</Button>&nbsp;
                            </>
                        </> : null}
                    </CardContent>
                </Card>
            </Box>
        </Box>
    </Edit>
}