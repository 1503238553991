import {Create} from "@refinedev/mui";
import {Box, Button, Dialog, DialogContent, TextField, Typography} from "@mui/material";
import React, { useRef, useState } from "react";
import "./AftOverview.scss"
import {useCreate, useModal} from "@refinedev/core";
import UploadIcon from '@mui/icons-material/Upload';
import {useForm} from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";

export const AftUploadButton = ({ aft_id, label, isLoadingDoc, ...props }) => {
    const {show, close, visible} = useModal()
    const {
        saveButtonProps,
        refineCore: {formLoading},
        control,
        formState: {errors},
        handleSubmit
    } = useForm();
    const fileRef = useRef(null);
    const { mutate: create } = useCreate({
        mutationOptions: {
            onMutate: (vars) => {
                console.log('onmutate', vars)
            }
        }
    });

    const [type, setType] = useState(props.type);
    const onFinish = (params) => {
        // @ts-ignore
        params.file = fileRef.current?.firstChild?.firstChild?.files[0]
        let extension = params.file?.name?.split('.')
        params.savename = type + '.' + (extension[1] || '')
        params.aftcontract_id = aft_id
        console.log(params)
        let response = create({
            resource: "documents",
            values: params,
            invalidates: [],
        }, {
            onSuccess: (data, variables, context) => {
                close()
            }
        })
    }
    return (
        <>
            <Button className='upload-button' variant="actionDetailPage" disabled={isLoadingDoc} onClick={show}>
                <UploadIcon/>
                <Box>Upload</Box>
            </Button>
            <Dialog open={visible} onClose={close}>
                <DialogContent>
                    <Create isLoading={formLoading}
                            saveButtonProps={{
                                ...saveButtonProps,
                                disabled: false,
                                onClick: handleSubmit(onFinish)
                            }} breadcrumb={false}
                            wrapperProps={{elevation: 0}} goBack={false}
                            title={<Typography variant={'h5'}>Upload {label} document</Typography>}>
                        <Box
                            component="form"
                            className={"input-form"}
                            autoComplete="off">
                            <TextField
                                label="Type"
                                value={type}
                                inputProps={{ inputMode: 'text', pattern: '[0-9a-zA-Z]*' }}
                                onChange={evt => {
                                    setType(evt.target.value)
                                }} />
                        </Box>
                        <Box
                            component="form"
                            className={"input-form"}
                            autoComplete="off"
                            encType="multipart/form-data">
                            <Controller
                                control={control}
                                name={'file'}
                                rules={{required: true}}
                                render={({field: {value, ...field}}) => {
                                    let error = (errors.file) ? {error: true, helperText: "required field"} : {}
                                    return <><TextField {...field}
                                                      {...error}
                                                      id="file"
                                                      type={"file"}
                                                      ref={fileRef}
                                                      onChange={(e) => {
                                                          console.log(e);
                                                        // @ts-ignore
                                                          field.onChange(e.target?.files);
                                        }} /></>
                                }}
                            />
                        </Box>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    )
}