import { Create } from "@refinedev/mui";
import { Box, TextField, Stack, Typography, Switch } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import {useEffect, useRef} from "react";
import {INews} from "shared-libs/src/interfaces";
import BundledEditor from "shared-libs/src/components/BundledEditor";
import { useCreate, useNavigation } from "@refinedev/core";
import '../pages.scss'


export const NewsCreate = () => {
    useEffect(() => {
        document.title = 'Whtexch - News';
    }, []);
    const {
        saveButtonProps,
        refineCore: {formLoading},
        register,
        control,
        formState: {errors},
        handleSubmit
    } = useForm<INews>();
    const {list} = useNavigation()
    const editorRef = useRef(null);
    const {mutate: create} = useCreate<INews>();
    const onFinish = (params) => {
        // @ts-ignore
        params.body = editorRef?.current.getContent()
        params.is_public = params.is_public === true
        create({
            resource: "news",
            values: params
        }, {
            onSuccess: (data, variables, context) => {
                list("news")
            }
        })
    }

    return (
        <Create title={<Typography variant="h5">Back</Typography>} isLoading={formLoading} saveButtonProps={{...saveButtonProps, onClick: handleSubmit(onFinish)}}>
            <Box
                component="form"
                className={"input-form"}
                autoComplete="off"
            >
                <TextField
                    {...register("title", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    type="text"
                    label="Title"
                    name="title"
                />
                <Controller
                    control={control}
                    name="is_public"
                    render={({field}) => (
                        <Stack className={"stack-switch"} direction={"row"} gap={4}>
                            <Typography variant={"body1"} className={"label"}>Is Public</Typography>
                            <Switch id={"is_public"}
                                    {...field}
                                    checked={field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked);
                                    }}
                            />
                        </Stack>
                    )}
                />

                <Controller
                    name={"body"}
                    control={control}
                    render={({field}) => {
                        return <BundledEditor
                            {...field}
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={field.value}
                        />
                    }
                    }
                />
            </Box>
        </Create>
    );
};
