import React, {useEffect} from "react";
import { useDataGrid, EditButton, List, DateField, TagField } from "@refinedev/mui";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useList } from "@refinedev/core";
import {TicketStatus} from "shared-libs/src/models";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";
import { User } from "shared-libs/src/interfaces";

export const TicketsList = () => {
    useEffect(() => {
        document.title = 'Whtexch - Tickets';
    }, []);
    const {dataGridProps} = useDataGrid({
        pagination: {
            mode: "off"
        },
        queryOptions:{
            ...GlobalConfig.adminQueryOptions
        }
    });

    const { data: userIdData, isLoading: userIdIsLoading } = useList<User>({
        resource: "users",
        queryOptions: {
            enabled: !dataGridProps?.loading,
            ...GlobalConfig.adminQueryOptions
        },
        pagination: {
            mode: "off"
        }
    });

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "ticket_id",
                headerName: "Ticket ID", type: "number", headerAlign: "left", flex: 1,
            },
            {
                field: "ticket_status",
                headerName: "Status",
                flex: 1,
                renderCell: function render({value}) {
                    return <TagField value={TicketStatus.getLabel(value)}/>
                },
            },
            {
                field: "subject",
                headerName: "Subject",
                flex: 1,
            },
            {
                field: "message",
                headerName: "# messages",
                type: "number",
                headerAlign: "left",
                flex: 1,
                renderCell: function render({value}) {
                    return value.length;
                },
                sortable: false,
                filterable: false
            },
            {
                field: "user_id",
                headerName: "User",
                minWidth: 200,
                renderCell: function render({value}) {
                    let user = userIdData?.data?.find((item) => item.id === value)
                    return userIdIsLoading ? <>Loading...</> : <>{user?.profile_data?.firstname} {user?.profile_data?.lastname}</>;
                },
            },
            {
                field: "under_review_by",
                headerName: "Under Review By",
                minWidth: 200,
                renderCell: function render({value}) {
                    let user = userIdData?.data?.find((item) => item.id === value)
                    return userIdIsLoading ? <>Loading...</> : <>{user?.profile_data?.firstname} {user?.profile_data?.lastname}</>;
                },
            },
            {
                field: "created",
                headerName: "Created",
                flex: 1,
                renderCell: function render({value}) {
                    return <DateField  format="YYYY/MM/DD" value={value}/>;
                },
            },
            {
                field: "updated",
                headerName: "Updated",
                flex: 1,
                renderCell: function render({value}) {
                    return <DateField  format="YYYY/MM/DD" value={value}/>;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                align: "center",
                headerAlign: "center",
                renderCell: function render({row}) {
                    return <EditButton variant="actionTable" hideText recordItemId={row.id}/>;
                },
                ...GlobalConfig.actionColumnConfig
            },
        ],
        [userIdIsLoading, userIdData?.data],
    );

    return (
        <List createButtonProps={{ variant: 'actionDetailPage' }}>
            <DataGrid {...dataGridProps} columns={columns} autoHeight {...GlobalConfig.dataGridConfig}/>
        </List>
    );
};
