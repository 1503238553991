import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useCreate, useNavigation } from '@refinedev/core';
import { Create, DateField, NumberField, SaveButton } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}


const columns: GridColumns<{ date: number, is_executed: number, eur_amount: number, wht_amount: number, description: string }> = [
    {
        field: "date", headerName: "Data", flex: 1, type: "number", headerAlign: "left", renderCell: ({ value }) => {
            return <DateField value={value} format='DD/MM/YYYY' />
        }
    },
    { field: "bcid", headerName: "BCID", flex: 1 },
    { field: "wht_amount", headerName: "Amount", flex: 1, renderCell: value => <NumberField value={value.value} /> },
    { field: "description", headerName: "Description", flex: 1 },
]


export const BankUpload: React.FC = () => {
    const { list } = useNavigation()

    const { mutate: preview, data: dataPreview, isLoading: isLoadingPreview, reset: resetPreview } = useCreate();
    const { mutate: create, data, isLoading, reset: resetCreate } = useCreate();
    const {
        saveButtonProps,
        refineCore: { formLoading },
        control,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm();
    const fileRef = useRef(null);


    const onPreview = (params) => {
        // @ts-ignore
        params.file = fileRef.current?.firstChild?.firstChild?.files[0]
        let extension = params.file?.name?.split('.')
        console.log(params)

        preview({
            resource: 'bank_preview',
            values: params
        })

    }
    const onConfirm = (params) => {
        // @ts-ignore
        params.file = fileRef.current?.firstChild?.firstChild?.files[0]
        let extension = params.file?.name?.split('.')
        console.log(params)

        create({
            resource: 'bank_import',
            values: params
        }, {
            onError: (response) => {
                console.error(response.message)
            },
            onSuccess: () => {
                list('bank')
            }
        })

    }

    console.log('bank preview data', dataPreview?.data?.result?.movements);
    console.log('bank data', data);

    const previewErrors = dataPreview?.data?.result?.error
    const isValid = dataPreview?.data?.result?.is_valid

    const file = useWatch({ control, name: 'file' })

    const [expanded, setExpanded] = React.useState<string | false>();
    useEffect(() => {
        if (previewErrors) {
            setExpanded('panel0');
        }
        if (isValid) {
            setExpanded('panel1')
        }
    }, [previewErrors, isValid])

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };




    const buttons = [
        <Button
            color='warning'
            onClick={() => {
                if ((fileRef?.current as any)?.firstChild?.firstChild?.value) {
                    (fileRef?.current as any).firstChild.firstChild.value = null
                }
                reset();
                resetPreview()
                resetCreate()
            }}
            disabled={!file}>Reset</Button>,
        <SaveButton
            color='primary'
            loading={isLoadingPreview}
            {...saveButtonProps}
            disabled={!file || !!dataPreview}
            onClick={handleSubmit(onPreview)}>Preview</SaveButton>,
        <SaveButton color='success' loading={isLoading} {...saveButtonProps} disabled={!dataPreview || !isValid}
            onClick={handleSubmit(onConfirm)}>Confirm</SaveButton>]


    return (<>
        {isLoading ? 'Loading' : null}
        <Create isLoading={formLoading}
            footerButtons={buttons}
            headerButtons={buttons}
            saveButtonProps={{}} breadcrumb={false}
            wrapperProps={{ elevation: 0 }} goBack={false}
            title={<Typography variant={'h5'}>Upload document</Typography>}>
            <Box
                component="form"
                display={dataPreview ? 'none' : undefined}
                className={"input-form"}
                autoComplete="off"
                encType="multipart/form-data">
                <Controller
                    control={control}
                    name={'file'}

                    rules={{ required: true }}
                    render={({ field: { value, ...field } }) => {
                        let error = (errors.file) ? { error: true, helperText: "required field" } : {}
                        return <TextField {...field}
                            {...error}
                            id="file"
                            type={"file"}
                            ref={fileRef}
                            onChange={(e) => {
                                // @ts-ignore
                                field.onChange(e.target?.files);
                            }} />
                    }}
                />
            </Box>
            {previewErrors?.length ? <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography color={'red'} variant='h6'>Errors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {previewErrors.map(el => <li>{normalizeDates(el)}</li>)}
                </AccordionDetails>
            </Accordion> : null}
            {dataPreview ? <>
                <Accordion disabled={previewErrors?.length} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography variant='h6'>Incoming transfers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DataGrid getRowId={({ date, eur_amount, wht_amount, description }) => date + eur_amount + " " + wht_amount + " " + description} rows={dataPreview?.data?.result?.movements?.buy || []} {...dataGridCommonProps} columns={columns} />
                    </AccordionDetails>
                </Accordion>
                <Accordion disabled={previewErrors?.length}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel2a-header">
                        <Typography variant='h6'>Ignored</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <DataGrid getRowId={({ date }) => date} rows={dataPreview?.data?.result?.movements?.ignored || []} {...dataGridCommonProps} columns={columns} />
                    </AccordionDetails>
                </Accordion>
            </> : null}
        </Create>

    </>)
}


const normalizeDates = (str: string) => {
    const regex = /([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/gm;
    // The substituted value will be contained in the result variable
    return str.replace(regex, (substring, ...args) => {
        return new Date(`${args[0]}-${args[1]}-${args[2]} ${args[3]}:${args[4]}:${args[5]}`).toLocaleString()
    });
}