import { useEffect, useMemo, useState, useSyncExternalStore } from 'react';

export const CURRENCY_EUR = 'EUR';
export const CURRENCY_WHT = 'WHT';


export const MULTIPLIER = {
    [CURRENCY_EUR]: 0.01,
    [CURRENCY_WHT]: 1
}

export const LABELS = {
    [CURRENCY_EUR]: "€",
    [CURRENCY_WHT]: "WHT"
}

const LOCAL_STORAGE_KEY = 'WHT_CURRENT_CURRENCY'

let listeners: any = [];
let currency: 'EUR' | 'WHT' | null = null;//localStorage.getItem(LOCAL_STORAGE_KEY) == CURRENCY_EUR ? CURRENCY_EUR : CURRENCY_WHT

const setCurrency = (_currency) => {
    currency = _currency
}
export const useCurrencyStore = () => {

    // const [currency, setCurrency] = useState(_currency)
    useEffect(() => {
        if (currency) {
            localStorage.setItem(LOCAL_STORAGE_KEY, currency)
        }
    }, [currency])



    const store = {
        toggleCurrency() {
            setCurrency(currency == CURRENCY_EUR ? CURRENCY_WHT : CURRENCY_EUR)
            localStorage.setItem(LOCAL_STORAGE_KEY, currency == CURRENCY_EUR ? CURRENCY_WHT : CURRENCY_EUR)
            emitChange();
        },
        init() {
            currency = localStorage.getItem(LOCAL_STORAGE_KEY) == CURRENCY_EUR ? CURRENCY_EUR : CURRENCY_WHT
            localStorage.setItem(LOCAL_STORAGE_KEY, currency)
            emitChange();
        },

        subscribe(listener) {
            listeners = [...listeners, listener];
            return () => {
                listeners = listeners.filter(l => l !== listener);
            };
        },
        getSnapshot() {
            const _curr = currency;
            return _curr;
        },
        getValue(valueWht) {
            if (currency) {
                return (valueWht * MULTIPLIER[currency]).toLocaleString() + " " + LABELS[currency]
            }
            else {
                return valueWht;
            }
        },
    }



    const Init = ({ store }) => {
        useEffect(() => {
            store.init();
            return () => {
                currency = null;
            }
        }, [])
        return <></>
    }

    const init = useMemo(() => <Init store={store} />, [])

    const currencySynced = useSyncExternalStore(store.subscribe, store.getSnapshot)

    return { ...store, currency: currencySynced, init }
};

function emitChange() {
    for (let listener of listeners) {
        listener();
    }
}
