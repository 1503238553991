import { Button, Typography } from "@mui/material";
import { useShow } from "@refinedev/core";
import { Show } from "@refinedev/mui";
import { IStaticLink } from "shared-libs/src/interfaces";

export function ShowStaticLink() {

    const { queryResult: { data } } = useShow<IStaticLink>()


    console.log('show static link', data)
    return <Show title={<Typography variant="h5">Preview</Typography>}>

        {data ? <>
            <>
                <p>{data.data.text}</p>
                <Button target="_blank" download={true} href={data?.data.button_url}>{data.data.button_text}</Button>&nbsp;
            </>
        </> : null}


    </Show>
}