import {AftHistory} from "./AftHistory";
import {AftReporting} from "./AftReporting";
import {Grid, Stack, Typography} from "@mui/material";
import {AftOverview} from "./AftOverview";
import {useParams} from "react-router-dom";
import {Show} from "@refinedev/mui";
import React from "react";

export const AftShow = () => {
    const {id: aft_id} = useParams()
    return (
        <Show title={<Typography variant="h5">Back</Typography>} breadcrumb={""} headerButtons={<></>}>
            <Stack spacing={2}>
                <Grid>
                    <Grid item xs={12} lg={4}>
                        <AftOverview aft_id={aft_id}/>
                        {/* <AftReporting aft_id={aft_id}/> */}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <AftHistory aftcontract_id={aft_id}/>
                    </Grid>
                </Grid>
            </Stack>
        </Show>
    )
}