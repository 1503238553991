import { DateField, NumberField } from "@refinedev/mui";

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Skeleton,
    Stack,
    Typography,
    Collapse,
} from "@mui/material";

import React from "react";
import "./AftOverview.scss"
import {AftActionButton} from "./AftActionButton";
import { useShow } from "@refinedev/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './AftOverview.scss'
import { AftFiles } from "./AftFiles";
import { useCurrencyStore } from "hooks/useCurrency";

export const AftOverview = ({aft_id}) => {
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const rowLabelXs = 8
    const rowValueXs = 2
    const {queryResult} = useShow({
        resource: 'aft',
        id: aft_id
    });
    const {data, isLoading} = queryResult;
    const record = data?.data;

    const microfractions_constant = 100
    const { getValue } = useCurrencyStore()
    return (
        <Card className='aft-overview' sx={{mb: 2}}>
            <CardHeader className="panel-header" title={record?.work_of_ingenuity || aft_id}></CardHeader>
            <CardContent>

                <Typography sx={{ fontSize: "1.3rem" }}>Documentation</Typography>
                <Stack spacing={2}>
                    <AftFiles aftcontract_id={aft_id} />
                </Stack>

                <Box className="box-collapse" onClick={handleToggle}>
                    <Typography className="column-label">Overview </Typography> {open ? <KeyboardArrowDownIcon/> :
                    <KeyboardArrowUpIcon/>}
                </Box>
                <Collapse className="box-collapse-in" in={open}>
                    {!isLoading ? <>
                        <Grid container spacing={2}>
                            <Grid item xs={rowLabelXs}>
                                <Typography>DATE:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <DateField value={record?.creation_date} format={"YYYY/MM/DD"}/>
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>MASTERPIECE ID:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>{record?.work_of_ingenuity || aft_id}</Typography>
                            </Grid>

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>Value:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.number_of_tokens * record?.price}
                                             options={{style: 'currency', currency: "EUR"}}/>
                            </Grid> */}

                            <Grid item xs={12}>
                            </Grid>

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>Micro-fractions:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.tokens_deposited * microfractions_constant}/>
                            </Grid> */}

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>1 Unit:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.min_token_quantity}/>
                            </Grid> */}

                            <Grid item xs={rowLabelXs}>
                                <Typography>PRICE PER UNIT:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>{getValue(record?.price)}</Typography>
                            </Grid>

                            {record?.number_of_tokens && <>
                                <Grid item xs={rowLabelXs}>
                                    <Typography>TOTAL UNITS DEPOSITED:</Typography>
                                </Grid>
                                <Grid item xs={rowValueXs}>
                                    <NumberField value={record?.number_of_tokens} />
                                </Grid>
                            </>}
                            

                            {record?.number_of_tokens && record?.price && 
                            <>
                                <Grid item xs={rowLabelXs}>
                                    <Typography>UNITS ORIGINAL DEPOSITED VALUE:</Typography>
                                </Grid>
                                <Grid item xs={rowValueXs}>
                                    <Typography>{getValue(record?.number_of_tokens * record?.price)} </Typography>
                                </Grid>
                            </>}

                            

                            <Grid item xs={rowLabelXs}>
                                <Typography>UNITS TOTAL QTY BOUGHT BY PARTICIPANTS:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.tokens_bought_by_mkt}/>
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>UNITS RESIDUAL AVAILABLE IN MKT:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.tokens_residual_available}/>
                            </Grid>

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>Unit Held:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>n/a</Typography>
                            </Grid> */}

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>Financial Instrument Price:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>n/a</Typography>
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>Value of AFT & Financial Instruments:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>n/a</Typography>
                            </Grid> */}

                        </Grid>
                    </> : <Skeleton/>}
                </Collapse>

            </CardContent>
        </Card>
    )
}