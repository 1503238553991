import {Box, Button, CardActions, IconButton, Menu, MenuItem} from "@mui/material";
import React, {useEffect, useState} from "react";
import DownloadIcon from '@mui/icons-material/Download';
import "./AftOverview.scss"
import {useCustomMutation, useList} from "@refinedev/core";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AftUploadButton} from "./AftUploadButton";
import { LoadingButton } from "@mui/lab";

const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    //@ts-ignore
    a.style = "display: none";
    return function (data, fileName) {
        const blob = new Blob([data]),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

export const AftActionButton = ({ aft_id, label, type, onRefetch = () => { } }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentFilename, setCurrentFilename] = useState("");
    const open = Boolean(anchorEl);
    const { mutate: custom, isLoading: mutationIsLoadind } = useCustomMutation()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { data: dataDoc, isLoading: isLoadingDoc, refetch } = useList({
        resource: "documents",

        filters: [
            {
                field: "aftcontract_id",
                operator: "eq",
                value: aft_id,
            },
            {
                operator: "and",
                value: [
                    {
                        field: "filename",
                        operator: "startswith",
                        value: type
                    }
                ]
            }
        ],

        pagination: {
            mode: "off"
        }
    });
    const handleDownload = (evt) => {
        custom({
            url: `/trading/aftcontract/document/download`,
            method: "post",
            values: {
                aftcontract_id: aft_id,
                filename: currentFilename
            }
        }, {
            onSuccess: (data) => {
                saveData(data?.data, currentFilename)
            }
        })
    }
    const handleRemove = () => {
        custom({
            url: `/trading/admin/aftcontract/document/remove`,
            method: "post",
            values: {
                aftcontract_id: aft_id,
                filename: currentFilename
            }
        }, {
            onSuccess: (data) => {
                console.log(data, 'manage_success remove')
                onRefetch?.()
            }
        })
    }
    useEffect(() => {
        if (!isLoadingDoc && currentFilename === "") {
            // @ts-ignore
            setCurrentFilename(dataDoc?.data[0]?.filename);
        }
    }, [currentFilename, dataDoc, isLoadingDoc])
    return (
        <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', gap: '20px'}}
             alignItems={'center'} justifyContent={'space-between'}>
            <Box sx={{textAlign: 'left !important'}} className="column-label">{label}</Box>
            <Box sx={{display: 'flex'}}>
                <CardActions>
                    <AftUploadButton aft_id={aft_id} isLoadingDoc={isLoadingDoc} label={type} type={type}/>
                    <IconButton
                        sx={dataDoc?.data?.length && type !== '' ? {} : { visibility: 'hidden' }}
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem key={"download-button"}>
                            <Button className='download-button'
                                    variant="actionDetailPage"
                                    disabled={isLoadingDoc || !dataDoc}
                                    onClick={handleDownload}>
                                <DownloadIcon/>
                                <Box>Download</Box>
                            </Button>
                        </MenuItem>
                        <MenuItem>
                            <LoadingButton loading={mutationIsLoadind} className='delete-button' variant="actionDetailPage"
                                    disabled={isLoadingDoc}

                                    onClick={handleRemove}>
                                <DeleteIcon/>
                                <Box>Remove</Box>
                            </LoadingButton>
                        </MenuItem>
                    </Menu>

                </CardActions>
            </Box>
        </Box>

    )
}