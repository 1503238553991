import React, {useEffect} from "react";
import {DateField, List, NumberField, TagField, useDataGrid} from "@refinedev/mui";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";

import './list.scss'
import { useCurrencyStore } from "hooks/useCurrency";
import { TextField } from "@mui/material";

export const OrdersList: React.FC = () => {

    const { getValue, init } = useCurrencyStore()

    useEffect(() => {
        document.title = 'Whtexch - Trading';
    }, []);

    const {dataGridProps} = useDataGrid({
        resource: 'trading_audit',
        syncWithLocation: false,
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions
        }
    });

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "id",
                headerName: "ID",
                flex: 0.75,
            },
            {
                field: "timestamp",
                headerName: ("DATE & TIME (UTC)"),
                description: ("date_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                minWidth: 160,
                flex: 1.25,
                valueFormatter: ({value}) => {
                    return value && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8)
                },
                renderCell: ({value}) => value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"}/> : ""
            },
            {
                field: "aftcontract_id",
                valueGetter: ({ row }) => row.artwork?.work_of_ingenuity || row.aftcontract_id,
                headerName: "AFT id",
                flex: 1,
            },
            {
                field: "order.type",
                headerName: "Order Type",
                flex: 1,
                valueGetter: ({ row: { order: { type } } }) => {
                    return type
                },
                renderCell: ({value}) => <TagField value={value}/>
            },
            {
                field: "entry_type",
                headerName: ("Status"),
                headerAlign: "center",
                align: "center",
                flex: 1,
            },
            {
                field: "order_size",
                headerName: "Order Size",
                type: 'number',
                headerAlign: "left",
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { order: { init_quantity } } }) => init_quantity,
                renderCell: ({value}) => value ? <NumberField value={value}/> : ""
            },
            {
                field: "quantity",
                headerName: ("executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : "-"
                }
            },
            {
                field: "not_executed",
                headerName: ("not executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: (params) => {
                    return params.row.order.quantity
                },
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : "-"
                }
            },
            {
                field: "order.avg_price",
                headerName: "Average purchase price",
                flex: 1,
                valueGetter: ({ row: { order: { avg_price } } }) => avg_price,
                renderCell: ({value}) => {
                    return getValue(value)
                }
            },
            { 
                field: "bcid", 
                headerName: "BCID", 
                flex: 0.5,
                valueGetter: ({ row: { order: { user_bcid } } }) => user_bcid,
                renderCell: ({ value }) => {
                    return value
                }
             },
            {
                field: "order.user.profile_data.firstname user.profile_data.lastname",
                headerName: "Customer",
                flex: 1.5,
                valueGetter: ({ row: { order: { user } } }) => user?.profile_data ? user.profile_data.firstname + " " + user.profile_data.lastname : "n/a",
                whiteSpace: 'wrap',
            },
            {
                field: "order.user.hlf[0].company_name",
                headerName: "Company",
                valueGetter: ({ row: { order: { user } } }) => user?.hlf ? user.hlf[0].company_name : "n/a",
                flex: 1,
            },
            {
                field: "market",
                headerName: "Market",
                flex: 1,
                renderCell: ({value}) => <TagField value={value}/>
            },
            {
                field: "order.price_for_unit",
                headerName: "Limit Price (WHTEXs)",
                type: 'number',
                minWidth: 50,
                headerAlign: "left",
                flex: 1,
                valueGetter: ({ row: { order: { price_for_unit } } }) => price_for_unit,
                renderCell: ({ value }) => getValue(value)
            },
            {
                field: "expiration_date",
                headerName: ("validity period"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                minWidth: 50,
                flex: 2,
                valueFormatter: ({ value }) => {
                    return value && value != 'GTC' && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8) + " " + [value.substring(8, 10), value.substring(10, 12), value.substring(12, 14),].join(':')
                },
                valueGetter: ({ row: { order: { expiration_date } } }) => {
                    return expiration_date?.length ? expiration_date : "GTC"
                },
                renderCell: ({ value }) => value === 'GTC' ? 'GTC' : (value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"} /> : "")
            },
        ],
        [],
    );

    return (
        <List title={'Trading'}>
            {init}
            <DataGrid className='trading-grid' {...dataGridProps} columns={columns}
                      autoHeight {...GlobalConfig.dataGridConfig}/>
        </List>
    );
};
