import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useCustomMutation } from "@refinedev/core"
import { useDataGrid } from "@refinedev/mui"
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import React, { useEffect } from "react";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { LoadingButton } from "@mui/lab";

export function WhitelistManager({ bcid, title }: IWhitelistManager) {


    const { mutate: addEmail, isLoading: addEmailIsLoading, data: addEmailData } = useCustomMutation({})
    const { mutate: removeEmail, isLoading: removeEmailIsLoading, data: removeEmailData } = useCustomMutation({})
    const [email, setEmail] = React.useState('')

    const { dataGridProps, tableQueryResult: { isLoading, isRefetching, refetch, isFetched } } = useDataGrid({
        resource: 'whitelist',
        queryOptions: {
            cacheTime: 0
        },
        filters: {
            permanent: [
                { field: 'bcid', operator: 'eq', value: bcid }
            ]
        }
    })

    useEffect(() => {
        if (addEmailData?.data?.result && removeEmailData?.data?.result) {
            refetch()
        }
    }, [addEmailData?.data, removeEmailData?.data])
    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                align: 'right',
                renderCell: ({ row }) => {
                    return <Typography>{row}</Typography>
                },
                field: 'Email', flex: 9, valueGetter: ({ row }) => {
                    return row
                }
            },
            {
                field: 'actions', flex: 1, renderCell: ({ row }) => {
                    return <LoadingButton loading={removeEmailIsLoading} onClick={() => handleRemoveEmail(row)}><RemoveIcon /></LoadingButton>
                }
            }
        ], [dataGridProps]);

    const handleAddEmail = () => {
        addEmail({
            url: '/bcid/bind/add_email_to_allowed_list',
            method: 'post',
            values: {
                bcid,
                email
            }
        }, {
            onSettled: async () => {
                setEmail('')
            }
        })

    }
    const handleRemoveEmail = (email) => {
        removeEmail({
            url: '/bcid/bind/remove_email_to_allowed_list',
            method: 'post',
            values: {
                bcid,
                email
            }
        })
    }




    return <Grid xs={12}>
        {title && <Typography variant="h4">{title}</Typography>}

        <TextField disabled={addEmailIsLoading} label="Add an email" name="email" value={email}
            onKeyDown={({ key }) => {
                if (key == 'Enter') {
                    handleAddEmail();
                }
            }}
            InputProps={{ endAdornment: <LoadingButton loading={addEmailIsLoading} disabled={!email} onClick={handleAddEmail}> <AddIcon /></LoadingButton> }}
            onChange={(evt) => {
                setEmail(evt.target.value)
            }} />
        <DataGrid autoHeight autoPageSize getRowId={el => el} {...dataGridProps} columns={columns} {...GlobalConfig.dataGridConfig} loading={isLoading} />
    </Grid>


}

interface IWhitelistManager {
    bcid: number,
    title?: string
}