import {GridFeatureModeConstant} from "@mui/x-data-grid";

const DEFAULT_PAGE_SIZE = 25
export const GlobalConfig = {
    actionColumnConfig: {
        minWidth: 50,
        disableColumnMenu: true,
        filtrable: false,
        sortable: false
    },
    DEFAULT_PAGE_SIZE: DEFAULT_PAGE_SIZE,
    dataGridConfig: {
        sortingMode: GridFeatureModeConstant.client,
        filterMode: GridFeatureModeConstant.client,
        initialState: {
            pagination: {
                pageSize: DEFAULT_PAGE_SIZE
            }
        }
    },
    queryOptions: {
        // 10min
        staleTime: 10 * 60 * 1000,
        queryKeyHashFn: (data) => {
            return data.slice(0, 3).join()
        }
    },
    adminQueryOptions: {
        // 10min
        cacheTime: 0,
        staleTime: 10 * 60 * 1000,
        queryKeyHashFn: (data) => {
            return data.slice(0, 3).join()
        }
    }

}