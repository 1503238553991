import { Box, TextField, Typography } from "@mui/material";
import { useCreate } from "@refinedev/core";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IStaticLink } from "shared-libs/src/interfaces";

export function CreateStaticLink() {


    const {
        saveButtonProps,
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm();

    const { mutate: create } = useCreate<IStaticLink>();

    const onFinish = (params) => {
        // @ts-ignore

        return create({
            resource: "staticlink",
            values: params,
        })
    }



    return <Create title={<Typography variant="h5">Back</Typography>} saveButtonProps={{ ...saveButtonProps, onClick: handleSubmit(onFinish) }} headerButtons={() => null} >


        <Box
            component="form"
            autoComplete="off"
            className={"input-form"}
        >

            <TextField
                {...register("text", {
                    required: "This field is required",
                })}
                error={!!(errors as any)?.text}
                helperText={(errors as any)?.text?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Text"
                name="text"
            />

            <TextField
                {...register("button_text", {
                    required: "This field is required",
                })}
                error={!!(errors as any)?.button_text}
                helperText={(errors as any)?.button_text?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Button Text"
                name="button_text"
            />

            <TextField
                {...register("button_url", {
                    required: "This field is required",
                })}
                error={!!(errors as any)?.button_url}
                helperText={(errors as any)?.button_url?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Button URL"
                name="button_url"
            />


        </Box>


    </Create>
}