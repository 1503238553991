import axios, {AxiosInstance} from "axios";

const querystring = require('querystring');
import {
    DataProvider,
    HttpError,
    CrudOperators,
    CrudFilters,
    CrudSorting,
} from "@pankod/refine-core";

import {RequestMaker} from "./RequestMaker";
import {RequestProvider} from "./RequestProvider";

const mapRetriveResources = (resource: string): string => {
    switch (resource) {
        case "customers":
        case "users":
            return "user/search";
        case "staticpage":
            return "page/list";
        case "staticlink":
            return "static_links/list";
        case "bank":
            return "bank/search_movements";
        case "user_bank":
            return "bank/user/search_movements";
        case "bank_buy_requests":
            return "bank/search_requests";
        case "preview_requests":
            return "aftoriginal/search";
        case "balance_wht":
            return "trading/client/balance/wht"
        case "balance_aft":
            return "trading/client/balance/all_aft"
        case 'documents':
            return "trading/aftcontract/search_documents"
        case 'messages':
            return 'ticket/user_search';
        case 'news':
            return 'news/search';
        case 'orders':
            return 'trading/view/orders';
        case 'masterpieces':
            return 'tokenizer/search_masterpieces';
        case 'tickets':
            return 'ticket/admin_search';
        case 'report_list':
            return 'trading/search_aftcontracts'
        case "onmarket":
        case "premarket":
        case 'aft_admin':
            return 'trading/search_aftcontracts'
        case 'aft':
        case 'trade':
            return 'trading/view/aftcontracts';
        case 'buy_wht_reason':
            return 'utility/generate_bcid_transfer_reason';
        case 'aftpreview':
            return 'aftpreview/aftcontract'
        case 'aftoriginal':
            return 'aftoriginal/details'

        case 'burn_requests':
            return 'aftoriginal/search'
        case 'original_requests_errors':
            return 'aftoriginal/search'
        case 'whitelist':
            return 'bcid/bind/list_of_allowed_emails'
        case 'squares':
            return '/exchange/square/list';
        case 'burned':
            return '/trading/list_of_aftcontracts_removed_from_exchange';
        case 'user_id_history':
            return '/bcid/get_history_of_user_id';
        case 'my_history':
            return '/bcid/get_my_history'
        case 'bcid_history':
            return '/bcid/get_history_of_bcid';
        case 'announcements':
            return '/announcements/public_list'
        case 'announcements_admin':
            return '/announcements/search'
        case 'bank_logs':
            return '/bank/audit'
        case 'trading_audit':
            return '/trading/audit'
        case 'burn_audit':
            return '/aftoriginal/audit'
        default:
            return resource;
    }
};

const mapRetriveSingleResource = (resource: string): string => {
    switch (resource) {
        case "documents":
            return "trading/aftcontract/document/download"
        case 'whitelist':
            return 'bcid/bind/list_of_allowed_emails'
        case 'squares':
            return '/exchange/square/exchange_details'
        default:
            return mapRetriveResources(resource)
    }
}

const mapRetriveSingleFromListResources = (resource: string): boolean => {
    switch (resource) {
        case "documents":
        // case "balance_aft":
        case "aft":
        case "trade":
        case 'aftpreview':
        case 'aftoriginal':
        case "squares":
            return false;
        default:
            return true;
    }
};

const mapCreateResources = (resource: string): string => {
    switch (resource) {
        case "documents":
            return "trading/admin/aftcontract/document/upload";
        case "bank_preview":
            return "bank/import_movements_preview";
        case "bank_import":
            return "bank/import_movements_save";
        case 'news':
            return 'news/create';
        case 'messages':
        case 'tickets':
            return 'ticket/open';
        case 'aft':
            return '/tokenizer/masterpiece/set_options'
        case 'squares':
            return '/exchange/square/create'
        case 'announcements':
            return '/announcements/create'
        case "staticlink":
            return "static_links/create";
        default:
            return resource;
    }
};

const mapUpdateResources = (resource: string): string => {
    switch (resource) {
        case "customers":
        case "users":
            return "user/set_profile_data";
        case "messages":
        case "tickets":
            return 'ticket/add_reply';
        case "news":
            return "news/edit";
        case "squares":
            return "/exchange/square/set_opening_hours";
        case 'announcements':
            return '/announcements/edit'
        case "staticlink":
            return "static_links/edit";
        default:
            return resource;
    }
};

const mapDeleteResources = (resource: string): string => {
    switch (resource) {
        case "documents":
            return "trading/admin/aftcontract/document/remove";
        case "news":
            return "news/delete";
        case 'announcements':
            return '/announcements/delete'
        case "staticlink":
            return "static_links/delete";
        default:
            return resource;
    }
};

const mapPrimaryKey = (resource: string): string => {
    switch (resource) {
        case "aft":
        case "trade":
        case "onmarket":
        case "premarket":
            return "aftcontract_id";
        case "balance_wht":
            return "";
        case "balance_aft":
            return "bcid";
        case "customers":
        case "users":
            return "user_id";
        case "documents":
            return "filename";
        case "messages":
        case "tickets":
            return "ticket_id";
        case "news":
            return "news_id";
        case "orders":
            return "aftcontract_id";
        case "masterpieces":
            return "masterpiece_filename";
        case "aftpreview":
            return "aftcontract_id";
        case "aftoriginal":
            return "aftcontract_id";
        case "staticpage":
            return "staticpage_id";
        case "static_link":
            return "static_link_id";
        case "whitelist":
            return "bcid";
        case "squares":
            return "square_id"; 
        case 'announcements':
            return 'announcement_id'
        default:
            return resource;
    }
};

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "ne":
        case "gte":
        case "gt":
        case "lte":
        case "lt":
        case "endswith":
        case "startswith":
        case "null":
        case "nnull":
            return `_${operator}`;
        case "contains":
            return "_like";
        case "eq":
        default:
            return "";
    }
};

const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        const _sort: string[] = [];
        const _order: string[] = [];

        sort.map((item) => {
            _sort.push(item.field);
            _order.push(item.order);
        });

        return {
            _sort,
            _order,
        };
    }

    return;
};
/**
 * return true if resource can use remote filter
 * @param resource
 */
const mapQueryFilter = (resource: string): boolean | string[] => {
    switch (resource) {
        case 'original_requests_errors': return [
            'status'
        ];
        case 'whitelist': return [
            "bcid"
        ];
        case 'preview_requests': return [
            'status'
        ];
        case 'burn_requests': return [
            'status'
        ];
        case 'orders':
            return ["user_id", "aftcontract_id"]
        case 'report_list':
            return ['show_user_orders', 'show_artwork'];
        case 'aft_admin':
            return ['show_artwork'];
        case "premarket":
        case "onmarket":
            return ["is_tradable", "is_premarket",
                "has_sold_tokens_in_premarket",
                "aftcontract_id",
                'show_premarket',
                'show_originator',
                'show_square',
                'show_orderbook',
                'show_daily_volume',
                'show_last_orders',
                'show_price_history',
                'show_artwork',
                'is_originator',
                'show_user_orders',
                'show_average_purchase_price'
            ]
        case "masterpieces":
            return ["masterpiece_filename", "is_started_tokenizer", "is_ended_tokenizer", "is_error", "is_added_to_exchange"]
        case 'aftpreview':
        case 'aftoriginal':
            return false; //['aftcontract_id'];
        case "news":
        case "documents":
            return true
        case "aft":
        case "customers":
        case 'messages': 
        case "users":
            return ['role_id']
        case 'tickets':
        case 'user_bank':
            return ['movement_type']
        case 'bcid_history':
            return ['bcid']
        case 'user_id_history':
            return ['user_id']
        case 'trading_audit':
            return ['aftcontract_id', 'date_from', 'date_to', 'user_id', 'bcid']
        case 'balance_wht':
            return ['bcid', 'user_id'];
        case 'balance_aft':
            return ['bcid', 'user_id'];
        default:
            return false
    }
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};
    if (filters) {
        filters.map((filter) => {
            if (
                filter.operator !== "or" &&
                filter.operator !== "and" &&
                "field" in filter
            ) {
                const {field, operator, value} = filter;

                if (field === "q") {
                    queryFilters[field] = value;
                    return;
                }

                const mappedOperator = mapOperator(operator);
                if (mappedOperator === "") {
                    queryFilters[`${field}${mappedOperator}`] = value;
                }

            }
        });
    }
    console.log("queryFilters", queryFilters)
    return queryFilters;
};

const applyFilter = (filters: CrudFilters, data: any) => {
    //console.log('insideeeeeeeeeeeeeeeeeeeeeee', filters)
    if (!filters) {
        return data;
    }
    const filterEvaluation = (filterItem, outData) => {
        const {field, operator, value} = filterItem;
        //console.log('operator', operator)
        switch (operator.toLowerCase()) {
            case "ne":
                return outData.filter(element => element[field] !== value)
            case "gte":
                return outData.filter(element => element[field] >= value)
            case "gt":
                return outData.filter(element => element[field] > value)
            case "lte":
                return outData.filter(element => element[field] <= value)
            case "lt":
                return outData.filter(element => element[field] < value)
            case "contains":
                return outData.filter(element => {
                    const arrayCheck = Array.isArray(element[field]) && (element[field].includes(value) || (value === null && element[field].length === 0))
                    const stringCheck = typeof element[field] === "string" && element[field].toLowerCase().indexOf(value?.toLowerCase()) !== -1
                    return arrayCheck || stringCheck
                })
            case "endswith":
                return outData.filter(element => element[field].endsWith(value))
            case "startswith":
                return outData.filter(element => element[field].startsWith(value))
            case "null":
                return outData.filter(element => element === null)
            case "nnull":
                return outData.filter(element => element !== null)
            case "eq":
            default:
                return outData.filter(element => element[field] === value)
        }
    }
    let outData = data;
    // console.log(outData)
    filters.map((filter) => {
        // console.log('filter', filter, outData)
        if (filter.operator === "or" || filter.operator === "and") {
            if (filter.operator === "and") {
                filter.value.forEach((filterItem) => {
                    // console.log(filterItem)
                    outData = filterEvaluation(filterItem, outData)
                    // console.log(outData)
                })
            } else {
                let tempOutData;
                outData = []
                filter.value.forEach((filterItem) => {
                    // console.log(filterItem)
                    tempOutData = filterEvaluation(filterItem, data)
                    // console.log(tempOutData)
                    if (outData.length <= 0) {
                        // console.log('empty outData')
                        outData = tempOutData
                    } else {
                        // console.log('not empty')
                        tempOutData.forEach((tempItem) => {
                            let found = false
                            outData.forEach((outItem) => {
                                if (tempItem.id === outItem.id) {
                                    found = true;
                                    return;
                                }
                            })
                            if (!found) {
                                // console.log('add item')
                                // console.log(tempItem)
                                outData.push(tempItem)
                            }
                        })
                    }
                    // console.log(outData)
                })
            }
        }
        if ("field" in filter) {
            // console.log('field')
            outData = filterEvaluation(filter, outData)
        }
    });
    console.log(outData)
    return outData;
};

const generateList = async ({
                                httpClient,
                                apiUrl,
                                resource,
                                hasPagination = true,
                                pagination,
                                filters,
                                sort,
                            }) => {
    console.log('dpw getList ' + resource)
    const originalResource = resource
    let queryFilter = mapQueryFilter(resource)
    resource = mapRetriveResources(resource)
    const url = `${apiUrl}/${resource}`;
    // const {current = 1, pageSize = 10} = pagination ?? {};
    // console.log('fiiiiilters')
    // console.log(filters)
    let localFilter = filters
    if (filters && typeof queryFilter !== "boolean") {
        // console.log('manage filter')
        localFilter = []
        filters.forEach((value, key) => {
            // console.log(key, value)
            if (typeof queryFilter === "boolean" || !queryFilter?.includes(value?.field)) {
                // console.log(filters[key])
                localFilter.push({...filters[key]})
                delete filters[key]
            }
        })
        queryFilter = true
    }
    // console.log('after  ')
    // console.log(localFilter)
    // console.log(filters)
    // console.log('----')
    const queryFilters = (typeof queryFilter !== "boolean" || queryFilter) ? generateFilter(filters) : {};

    // const query: {
    //     offset?: number;
    //     limit?: number;
    //     _sort?: string;
    //     _order?: string;
    // } = hasPagination
    //     ? {
    //         offset: (current - 1) * pageSize,
    //         limit: current * pageSize,
    //     }
    //     : {};
    //
    // const generatedSort = generateSort(sort);
    // if (generatedSort && hasPagination) {
    //     const {_sort, _order} = generatedSort;
    //     query._sort = _sort.join(",");
    //     query._order = _order.join(",");
    // }
    const query = {}
    httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...{"Content-Type": "multipart/form-data"}
    };

    // @ts-ignore
    const {data} = await RequestMaker.call(httpClient, apiUrl, `${url}`, `${resource}`, [query, queryFilters])

    // let headers = []
    // const total = +headers["x-total-count"];
    if (!Array.isArray(data.result)) {
        data.result = [data.result]
    }
    let dataReturn = data.result
    if (!!dataReturn && dataReturn.length > 0) {
        const generatedSort = generateSort(sort);
        if (generatedSort) {
            const {_sort, _order} = generatedSort;
            dataReturn.sort((a, b) => {
                let result = (a[_sort[0]] < b[_sort[0]]) ? -1 : (a[_sort[0]] > b[_sort[0]]) ? 1 : 0;
                return result * (_order[0] === 'asc' ? 1 : -1);
            })
        }
        if (localFilter) {
            // console.log('ooooooooo')
            // console.log(localFilter)
            if (originalResource === 'documents' && Array.isArray(filters)) {
                filters.map((value, index) => {
                    if ('field' in value && value.field === 'aftcontract_id') {
                        delete localFilter[index]
                    }
                });
                // localFilter = localFilter.filters(n => n)
            }
            // console.log(localFilter)
            // console.log('ooooooooo')
            dataReturn = applyFilter(localFilter, dataReturn);
        }
    }
    // console.log('data reeeeeeeeeeeeeeeeeeeeeeeeeturn', dataReturn)
    return {
        data: dataReturn,
        total: dataReturn.length,
    };
}

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.data?.error === "Invalid signature ID." || (error.response?.data?.error === 'Invalid login' && window.location.pathname != '/login')) {
            RequestProvider.signoutClient();
            window.location.replace("/");
            return Promise.reject();
        }
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.error,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

const WhtexchJsonServer = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): Omit<Required<DataProvider>,
    "createMany" | "updateMany" | "deleteMany"> => ({
    getList: async ({
                        resource,
                        hasPagination = true,
                        pagination = {current: 1, pageSize: 10},
                        filters,
                        sort,
                    }) => {
        return generateList({
            httpClient,
            apiUrl,
            resource,
            hasPagination,
            pagination,
            filters,
            sort
        })
    },

    getMany: async ({resource, ids}) => {
        console.log('dpw getMany ' + resource)
        const primaryKey = mapPrimaryKey(resource)
        resource = mapRetriveResources(resource)
        const variables = {};
        variables[primaryKey] = (ids as ReadonlyArray<number>);
        // {primaryKey: (ids as ReadonlyArray<number>)}
        const {data} = await RequestMaker.call(httpClient, apiUrl, `${apiUrl}/${resource}?${querystring.stringify()}`, `${resource}`, [variables])
        return {
            data,
        };
    },

    create: async ({resource, variables}) => {
        console.log('dpw create ' + resource)
        console.log(variables)
        resource = mapCreateResources(resource)
        const url = `${apiUrl}/${resource}`;
        httpClient.defaults.headers = {
            ...httpClient.defaults.headers,
            ...{"Content-Type": "multipart/form-data"}
        };
        let file: any = null
        if (typeof variables['file'] !== "undefined") {
            file = variables['file']
            delete variables['file']
        }
        const {data} = await RequestMaker.call(httpClient, apiUrl, `${url}`, `${resource}`, [variables], file)
        return {
            data,
        };
    },

    update: async ({resource, id, variables}) => {
        console.log('dpw update ' + resource)
        const primaryKey = mapPrimaryKey(resource)
        resource = mapUpdateResources(resource)
        const url = `${apiUrl}/${resource}`;
        const dataSubmit = {}
        dataSubmit[primaryKey] = id
        const {data} = await RequestMaker.call(httpClient, apiUrl, `${url}`, `${resource}`, [variables, dataSubmit])
        return {
            data,
        };
    },

    getOne: async ({ resource, id, metaData }) => {
        console.log('dpw getOne ' + resource)
        const primaryKey = mapPrimaryKey(resource)
        console.log('dpw getOne ' + resource, primaryKey)

        if (mapRetriveSingleFromListResources(resource)) {
            console.log('get from list')
            const list = await generateList({
                httpClient,
                apiUrl,
                resource,
                hasPagination: false,
                pagination: {current: 1, pageSize: 10},
                filters: metaData?.filters || false,
                sort: false
            })
            let outData = null;

            if (list?.data.length > 0) {
                if (id === "") {
                    outData = list.data[0]
                } else {
                    for (const elementList of list.data) {

                        if (elementList[primaryKey] != id) {
                            continue;
                        }
                        outData = elementList;
                        break;
                    }
                }
            }
            return {
                data: outData
            }
        } else {
            resource = mapRetriveSingleResource(resource)
            const url = `${apiUrl}/${resource}`;
            const dataSubmit = {}
            if (primaryKey != "") {
                dataSubmit[primaryKey] = id
            }
            // @ts-ignore
            const {data} = await RequestMaker.call(httpClient, apiUrl, `${url}`, `${resource}`, [dataSubmit])
            return {
                data: (data.result ? data.result[0] : null) ?? data.result,
            };
        }

    },

    deleteOne: async ({resource, id, variables}) => {
        console.log('dpw deleteOne ' + resource)
        const primaryKey = mapPrimaryKey(resource)
        resource = mapDeleteResources(resource)
        const url = `${apiUrl}/${resource}`;
        const dataSubmit = {}
        dataSubmit[primaryKey] = id
        const {data} = await RequestMaker.call(httpClient, apiUrl, `${url}`, `${resource}`, [dataSubmit])
        return {
            data: data,
        };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({url, method, filters, sort, payload, query, headers}) => {
        console.log("custom", payload, url, method, filters, sort, payload, query, headers, payload)
        let requestUrl = `${apiUrl}${url}?`;
        // const formData = new FormData();
        const externalData = {}

        if (sort) {
            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const {_sort, _order} = generatedSort;
                const sortQuery = {
                    _sort: _sort.join(","),
                    _order: _order.join(","),
                };
                requestUrl = `${requestUrl}&${querystring.stringify(sortQuery)}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${querystring.stringify(filterQuery)}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${querystring.stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        httpClient.defaults.headers = {
            ...httpClient.defaults.headers,
            ...{"Content-Type": "multipart/form-data"}
        };

        if (requestUrl.lastIndexOf('?') === requestUrl.length - 1) {
            requestUrl = requestUrl.replace('?', '')
        }
        let file: any = null
        if (payload && typeof payload['file'] !== "undefined") {
            file = payload['file']
            delete payload['file']
        }



        // @ts-ignore
        const { data } = await RequestMaker.call(httpClient, apiUrl, requestUrl, url, [filters, sort, query, payload], file)

        return Promise.resolve({data});
    },
});

export default WhtexchJsonServer;