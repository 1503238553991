import { useCustomMutation, useShow, useOne, usePermissions, useGetIdentity, useNavigation, useModal, useList, useNotification } from "@refinedev/core";
import {Show, TagField, EditButton} from "@refinedev/mui";
import { Box, Typography, Grid, Button, Divider, Stack, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Autocomplete, Card, CardHeader, CardContent } from "@mui/material";
import {IUser} from "shared-libs/src/interfaces";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, { useEffect, useState } from "react";
import {Role, Status} from 'shared-libs/src/models';
import {UserAction} from "./action";
import { LoadingButton } from "@mui/lab";
import UserHistory from "./UserHistory";
import BCIDHistory from "./BCIDHistory";


export const UserShow: React.FC = () => {
    const {push} = useNavigation()
    const {data: permissionsData} = usePermissions({
        v3LegacyAuthProviderCompatible: true
    })
    const {data: currentUser} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const { queryResult, showId = '' } = useShow<IUser>();
    const {data, isLoading, refetch} = queryResult;
    const record = data?.data;
    let roleElements: any = []
    const transferModal = useModal()
    const isOnlyBcid = data?.data.hlf.length == 1
    const [transferTo, setTransferTo] = useState<number | null>(null)
    const [currentBcid, setCurrentBcid] = useState<string | null>()
    const { open } = useNotification()
    useEffect(() => {
        if (isOnlyBcid && !currentBcid) {
            setCurrentBcid(`${data?.data.hlf[0].bcid}`)
        }
    }, [data?.data.hlf.length])

    useEffect(() => {
        refetch()
    }, [])
    record?.roles?.forEach((role_id) => {
        roleElements.push(
            <TagField value={Role.getLabel(role_id)} className={`role_${role_id}`}/>
        )
    })
    const {data: reviewerUserData} = useOne<IUser>({
        resource: "users",
        id: record?.under_review_by || "",
        queryOptions: {
            enabled: !!record?.under_review_by,
        },
    })

    const { data: allUsers } = useList<IUser>({
        resource: "users",
        queryOptions: {
            queryKeyHashFn: () => `usersreassign`
        },
        filters: [
            { field: 'user_status', value: Status.Active, operator: 'eq' },
            { field: 'role_id', value: 1, operator: 'eq' }
        ]
    })
    useEffect(() => {
        document.title = 'Whtexch - Show User/Customer';
    }, []);
    const { mutate, isLoading: isMutationLoading } = useCustomMutation()
    const suspend = () => {
        mutate({
            url: `/user/suspend`,
            method: "post",
            values: {
                user_id: parseInt(`${showId}`)
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }
    const revoke = () => {
        mutate({
            url: `/user/revoke`,
            method: "post",
            values: {
                user_id: parseInt(`${showId}`)
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }
    const restart = () => {
        mutate({
            url: `/user/validation/restart`,
            method: "post",
            values: {
                user_id: parseInt(showId.toString())
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }
    const startManagerValidation = () => {
        mutate({
            url: `/user/validation/taken_by_manager`,
            method: "post",
            values: {
                user_id: parseInt(showId.toString())
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }
    const validateManager = () => {
        mutate({
            url: `/user/validation/verified_by_manager`,
            method: "post",
            values: {
                user_id: parseInt(showId.toString())
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }
    const startBoardValidation = () => {
        mutate({
            url: `/user/validation/taken_by_board`,
            method: "post",
            values: {
                user_id: parseInt(showId.toString())
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }
    const validateBoard = () => {
        mutate({
            url: `/user/validation/verified_by_board`,
            method: "post",
            values: {
                user_id: parseInt(showId.toString())
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }
    const resign = () => {
        mutate({
            url: `/user/validation/resign`,
            method: "post",
            values: {
                user_id: parseInt(showId.toString())
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }

    const transferOwnership = (bcid: string, transfer_to_user_id: number, company_name: string, iban: string, tax_number: string, revoke_user: boolean) => {
        mutate({
            url: `/bcid/transfer_ownership`,
            method: 'post',
            values: {
                bcid, transfer_to_user_id, company_name, iban, tax_number, revoke_user
            }
        }, {
            onSuccess: () => {
                open?.({ message: 'User was reassigned successfully', type: 'success' })
                transferModal.close()
                refetch()
            }
        })
    }
    let validationAction = <></>, showValidation = false, showAssignRole = false
    const validationDisabled = !record?.email_is_verified;
    if (record?.roles?.includes(Role.Originator) || record?.roles?.length === 0 || record?.roles?.includes(Role.MarketPartecipant)) {
        switch (record?.user_status) {
            case Status.In_charge:
                if (permissionsData.manager || permissionsData.super_admin) {
                    if (!record?.under_review_by) {
                        validationAction =
                            <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="info" onClick={startManagerValidation}>Start
                                Manager Validation</LoadingButton>
                    } else if (currentUser.user_id === record?.under_review_by) {
                        validationAction = <>
                            <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="info" onClick={validateManager}>Manager
                                Validate</LoadingButton>
                            <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="warning" onClick={resign}>Resign</LoadingButton>
                        </>
                    }
                    showValidation = true
                }
                break
            case Status.Manager_validated:
                if (permissionsData.board || permissionsData.super_admin) {
                    if (!record?.under_review_by) {
                        validationAction =
                            <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="info" onClick={startBoardValidation}>Start Board
                                Validation</LoadingButton>
                    } else if (currentUser.user_id === record?.under_review_by) {
                        validationAction = <>
                            <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="info" onClick={validateBoard}>Board
                                Validate</LoadingButton>
                            <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="warning" onClick={resign}>Reset</LoadingButton>
                        </>
                    }
                    showValidation = true
                }
                break
            // case Status.In_validation:
            //     if (permissionsData.board || permissionsData.super_admin) {
            // if (!record?.under_review_by) {
            //     validationAction =
            //         <Button color="primary" onClick={startBoardValidation}>Start Board Validation</Button>
            // } else {
            //     validationAction = <Button color="primary" onClick={validateBoard}>Board Validate</Button>
            // }
            //     showValidation = true
            // }
            // break
            case Status.Active:
                validationAction = <>
                    <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="warning" onClick={suspend}>Suspend</LoadingButton>
                    <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="error" onClick={revoke}>Revoke</LoadingButton>
                    <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="error" onClick={transferModal.show}>Reassign</LoadingButton>

                    <LoadingButton disabled {...{/*disabled={validationDisabled */ }} color="secondary"> <DeleteOutlineIcon /> Delete</LoadingButton>
                </>
                showValidation = true
                break
            case Status.Revoked:
            case Status.Suspended:
                validationAction = <LoadingButton loading={isMutationLoading} disabled={isMutationLoading || validationDisabled} variant="actionDetailPage" color="info" onClick={restart}>Restart</LoadingButton>
                showValidation = true
                break
        }

    }
    const canDisplayHistory = !isLoading && !record?.roles?.includes(Role.Super_Admin) && !record?.roles?.includes(Role.Admin_News_Writer) && !record?.roles?.includes(Role.Admin_Manager_Validator) && !record?.roles?.includes(Role.Admin_Ticket_Assistant) && !record?.roles?.includes(Role.Admin_Board_Validator) && record?.user_id
    return (
        
        <React.Fragment>
        <Stack spacing={4}>
            <Box sx={{displax: 'flex'}} className="page-title-container">
                <Box>
                        <Typography variant="h1">Users</Typography>
                </Box>
            </Box>
            
            {validationDisabled ? <Box><i><b>Note: </b>The user needs to verify the email before any action can be performed</i></Box> : null}
            
            <Box sx={{margin: '0 auto'}}>
                <Show title={<Typography variant="h5">Back</Typography>} isLoading={isLoading} canEdit={true}
                
                    breadcrumb=""
                    headerButtons={() => (
                        <>
                        {(showValidation) ? <> {validationAction}<Divider orientation="vertical" flexItem color="secondary" variant="middle" /> </> : <></>}
                        <EditButton variant="actionDetailPage"/>
                        {currentUser.id === record?.user_id && <Button className={'btn primary'} variant="actionDetailPage" onClick={() => push('/reset_password')}>Reset Password</Button>}
                        </>
                )}>
                    <Grid sx={{margin: '0 auto'}} container spacing={2}>
                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Created:</Typography>
                            <Typography sx={{ml: 2}} variant="body2"> {record?.created} </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">User ID:</Typography>
                            <Typography sx={{ml: 2}} variant="body2"> {record?.user_id}</Typography>
                        </Grid>

                        <Grid item xs={12}> <Divider/></Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">User Status:</Typography>
                            <TagField sx={{ml: 2}} value={Status.getLabel(record?.user_status)}
                                      className={`status_${record?.user_status}`}/>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">User Role:</Typography>
                            <Typography sx={{ml: 2}} variant="body2"> {roleElements}</Typography>
                        </Grid>

                        <Grid item xs={12}> <Divider/></Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Name:</Typography>
                                <Typography sx={{ ml: 2 }} variant="body2"> {record?.profile_data.firstname} </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Last Name:</Typography>
                                <Typography sx={{ ml: 2 }} variant="body2"> {record?.profile_data.lastname} </Typography>
                        </Grid>

                        <Grid item xs={12}> <Divider/></Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Telephone:</Typography>
                                <Typography sx={{ ml: 2 }} variant="body2"> {record?.profile_data.phone} </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">IBAN:</Typography>
                                <Typography sx={{ ml: 2 }} variant="body2"> {record?.profile_data.iban} </Typography>
                        </Grid>

                        <Grid item xs={12}> <Divider/></Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Email:</Typography>
                            <Typography sx={{ml: 2}} variant="body2"> {record?.email} </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography sx={{position: 'relative', top: '-7px'}} className="column-label"
                                        variant="body2">Email Verified:</Typography>
                            <Typography sx={{ml: 2}} variant="body2"> {record?.email_is_verified ?
                                <MarkEmailReadIcon color={"success"}/> :
                                <UnsubscribeIcon color={"warning"}/>} </Typography>
                        </Grid>

                        <Grid item xs={12}> <Divider/></Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Company Name:</Typography>
                                <Typography sx={{ ml: 2 }} variant="body2"> {record?.profile_data.company_name ?? record?.hlf[0]?.company_name} </Typography>
                        </Grid>


                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Company Website:</Typography>
                                <Typography sx={{ ml: 2 }} variant="body2"> {record?.profile_data.company_website} </Typography>
                        </Grid>

                        <Grid item xs={12}> <Divider/></Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Company Registration / Tax
                                Number:</Typography>
                                <Typography sx={{ ml: 2 }} variant="body2"> {record?.profile_data.tax_number ?? record?.hlf[0]?.tax_number} </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Under Review By:</Typography>
                            <Typography sx={{ml: 2}}
                                    variant="body2"> {!!record?.under_review_by ? reviewerUserData?.data.profile_data.lastname + " " + reviewerUserData?.data.profile_data.firstname : ""} </Typography>
                        </Grid>

                        <Grid item xs={12}> <Divider/></Grid>

                        <Grid item xs={6}>
                            <Typography className="column-label" variant="body2">Comments:</Typography>
                            <Typography sx={{ml: 2}} variant="body2"> {record?.comments} </Typography>
                        </Grid>
                    </Grid>
                </Show>
            </Box>
                {canDisplayHistory && record?.hlf.length > 0 && <UserAction hlf={record?.hlf} user_id={record?.user_id} />}
                {record?.user_id && <UserHistory user_id={record?.user_id} />}
                {data?.data.hlf.map(({ bcid }) => <BCIDHistory bcid={`${bcid}`} />)}
                <Dialog fullWidth open={transferModal.visible} onClose={transferModal.close}>
                    <DialogTitle>Reassign user {currentBcid}!</DialogTitle>
                    <DialogContent>
                        <Autocomplete<string>

                            options={data?.data.hlf.map(el => `${el.bcid}`) || []}
                            onChange={(_, value) => {
                                setCurrentBcid(value)
                            }}
                            disabled={isOnlyBcid}
                            value={currentBcid}
                            getOptionLabel={(option) => {
                                const hlf_data = data?.data.hlf.filter(el => `${el.bcid}` == option)[0]
                                if (hlf_data)
                                    return `${hlf_data.bcid}`
                                else return '';
                            }}
                            renderInput={(params) => {
                                return <TextField
                                    {...params}
                                    label="BCID"
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                />
                            }}
                        />
                        <Autocomplete<number>

                            options={allUsers?.data.map((el) => el.user_id) || []}
                            onChange={(_, value) => {
                                setTransferTo(value)
                            }}
                            value={transferTo}
                            getOptionLabel={(option) => {
                                const user = allUsers?.data.filter(el => el.user_id == option)[0]
                                if (user)
                                    return `${user.profile_data.company_name || 'n.a.'} (ID: ${user.user_id} ${user.profile_data.firstname} ${user.profile_data.lastname})`
                                else return '';
                            }}
                            renderInput={(params) => {
                                return <TextField
                                    {...params}
                                    label="Transfer To User"
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                />
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton loading={isMutationLoading} disabled={!transferTo} variant="contained" onClick={() => {
                            const user = allUsers?.data.filter(el => el.user_id == transferTo)[0]
                            if (transferTo && user?.profile_data && data?.data.hlf[0].bcid) {
                                transferOwnership(`${currentBcid}`, transferTo, user?.profile_data.company_name, user?.profile_data.iban || '', user?.profile_data.tax_number || '', true)
                            }
                        }}>Confirm</LoadingButton>
                        <Button onClick={transferModal.close}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Stack>

        </React.Fragment>
    );
};