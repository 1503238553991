export const Role = {
    MarketPartecipant: 1,
    Originator: 10,
    Admin_Manager_Validator: 20,
    Admin_Board_Validator: 21,
    Admin_News_Writer: 22,
    Admin_Ticket_Assistant: 23,
    Admin_Wht_Movements_Validator: 30,
    Admin_Wht_Movements_Approver_Above_Threshold: 31,
    Admin_Minter: 40,
    Super_Admin: 100,
    Generic_Admin: 99,
    getLabel: (role_id) => {
        switch (role_id) {
            case Role.Generic_Admin:
                return "Admin";
            case Role.Originator:
                return "Originator"
            case Role.Admin_Manager_Validator:
                return "Admin Manager Validator"
            case Role.Admin_Board_Validator:
                return "Admin Board Validator";
            case Role.Admin_News_Writer:
                return "Admin News Writer";
            case Role.Admin_Ticket_Assistant:
                return "Admin Ticket Assistant";
            case Role.Super_Admin:
                return "Super Admin";
            case Role.Admin_Minter:
                return "Minter";
            case Role.Admin_Wht_Movements_Validator:
                return "Admin WHT Movements Validator";
            case Role.Admin_Wht_Movements_Approver_Above_Threshold:
                return "Admin WHT Movements Approver Above Threshold";
            default:
                return "User";
        }
    },
    getUserRole: (user) => {
        let roles = user?.roles || []
        if (!user || !roles) {
            return ""
        }
        return Math.max(...roles) + ""
    },
    getUserRoles: (user) => {
        let roles = user?.roles || []
        if (!user || !roles) {
            return ""
        }
        return roles
    }
}