export const Status = {
    Suspended: 0,
    Revoked: 1,
    In_charge: 10,
    In_validation: 20,
    Manager_validated: 21,
    Active: 100,
    getLabel: (status_id) => {
        switch (status_id) {
            case Status.Suspended:
                return "Suspended"
            case Status.Revoked:
                return "Revoked"
            case Status.In_charge:
                return "In charge";
            case Status.In_validation:
                return "In validation";
            case Status.Manager_validated:
                return "Manager validated";
            case Status.Active:
                return "Active";
            default:
                return "Undefined";
        }
    }
}