import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LinkIcon from '@mui/icons-material/Link'
import PersonIcon from '@mui/icons-material/Person';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment';
import { CssBaseline, GlobalStyles, Link } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Refine } from "@refinedev/core";
import { ErrorComponent, Layout, ReadyPage, RefineSnackbarProvider, notificationProvider } from "@refinedev/mui";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { adapter, model } from "accessControl";
import Logo from 'assets/logo-whtexch-light.png';
import LogoMini from 'assets/logo-whtexch-mini.png';
import { newEnforcer } from "casbin";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "pages/news";
import { AuthAdmin, RequestProvider } from "shared-libs/src/providers";
import dataProvider from "shared-libs/src/providers/DataProviderWhtexch";
import liveProvider from "shared-libs/src/providers/LiveProviderWhtexch";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Login } from "./pages/Login";
import { OrdersList } from "./pages/orders";
import { CustomerList, UserEdit, UserShow, UsersList } from "./pages/users";
import { overridedLightTheme } from "./theme";

import ChatIcon from '@mui/icons-material/Chat';
import { BankTabs, BankUpload } from "pages/bank";
import { TicketsCreate, TicketsEdit, TicketsList } from "pages/tickets";
import './App.scss';
import { Header } from "./components";
import { ResetPassword } from "./pages/ResetPassword";
import { AftCreate, AftList, AftShow } from "./pages/aft";
import { BuyRequestList } from 'pages/bank_buy_requests';
import PreviewRequestList from 'pages/preview_requests/list';
import BurnList from 'pages/burn_list/list';
import OriginalRequestErrorsList from 'pages/original_requests_errors/list';
import BurnedList from 'pages/burned/list';
import { Register } from 'pages/Register';
import WhiteListList from 'pages/white_list/list';
import { ListSquare, CreateSquare, ShowSquare, UpdateSquare } from 'pages/square';
import { AnnouncementsCreate, AnnouncementsEdit, AnnouncementsList, AnnouncementsShow } from 'pages/announcemenets';
import { CreateStaticLink, EditStaticLink, ListStaticLink, ShowStaticLink } from 'pages/static_links';




function App() {
    const user = RequestProvider.getUser();
    let userRoles = RequestProvider.getUserRoles();
    return (
        <ThemeProvider theme={overridedLightTheme}>
            <CssBaseline />
            <GlobalStyles styles={{
                html: { WebkitFontSmoothing: "auto" },
                ':root': {
                    '--color-primary': overridedLightTheme.palette.info.main,
                    '--color-primary-text': overridedLightTheme.palette.info.contrastText,
                    '--color-secondary': overridedLightTheme.palette.secondary.main,
                    '--color-secondary-bg': overridedLightTheme.palette.secondary.contrastText,
                    '--color-secondary-text': overridedLightTheme.palette.text.secondary,
                    '--color-warning': overridedLightTheme.palette.warning.main,
                    '--color-warning-text': overridedLightTheme.palette.warning.contrastText,
                    '--breakpoint-xs': overridedLightTheme.breakpoints.values.xs,
                    '--breakpoint-sm': overridedLightTheme.breakpoints.values.sm,
                    '--breakpoint-md': overridedLightTheme.breakpoints.values.md,
                    '--breakpoint-lg': overridedLightTheme.breakpoints.values.lg,
                    '--breakpoint-xl': overridedLightTheme.breakpoints.values.xl,
                },
            }} />
            <RefineSnackbarProvider>
                <Refine
                    notificationProvider={notificationProvider}
                    Layout={Layout}
                    ReadyPage={ReadyPage}
                    catchAll={<ErrorComponent />}
                    legacyAuthProvider={AuthAdmin}
                    Title={({ collapsed }) => (
                        <Link href="/">
                            {collapsed ?
                                (<img src={LogoMini} alt="Whtexch" className="logo" />) :
                                (<img src={Logo} alt="Whtexch" className="logo" width={180} />)
                            }
                        </Link>
                    )}
                    Header={Header}
                    dataProvider={dataProvider(process.env.REACT_APP_API_URL as string)}
                    liveProvider={liveProvider(process.env.REACT_APP_WS_URL as string)}
                    options={{ liveMode: "auto" }}
                    accessControlProvider={{
                        can: async ({ action, params, resource }) => {
                            const enforcer = await newEnforcer(model, adapter);
                            const can = async (userRoles, resource, action) => {
                                for (const userRole of userRoles) {
                                    const result = await enforcer.enforce(userRole, resource, action)
                                    if (result) {
                                        return true
                                    }
                                }
                                return false;
                            }
                            if (!userRoles) {
                                userRoles = RequestProvider.getUserRoles();
                            }
                            if (
                                action === "delete" ||
                                action === "edit" ||
                                action === "show"
                            ) {
                                return Promise.resolve({
                                    can: await can(userRoles, `${resource}/${params?.id}`, action)
                                });
                            }
                            if (action === "field") {
                                return Promise.resolve({
                                    can: await can(userRoles, `${resource}/${params?.field}`, action)
                                });
                            }
                            return Promise.resolve({
                                can: await can(userRoles, `${resource}`, action)
                            });
                        },
                    }}
                    resources={[{
                        name: 'users',
                        list: UsersList,
                        show: UserShow,
                        edit: UserEdit,
                        meta: { label: "Administrators" },
                        icon: <PersonIcon />
                    }, {
                        name: 'customers',
                        list: CustomerList,
                        show: UserShow,
                        edit: UserEdit,
                        meta: { label: "Users" },
                        icon: <SupervisedUserCircleIcon />
                    },
                    {
                        name: 'bank',
                        list: BankTabs,
                        create: BankUpload,
                        meta: { label: "Bank" },
                        icon: <SupervisedUserCircleIcon />
                    },
                    // {
                    //     name: 'preview_requests',
                    //     list: PreviewRequestList,
                    //     meta: { label: "Download Requests" },
                    //     icon: <SupervisedUserCircleIcon />,
                    // },
                    // {
                    //     name: 'original_requests_errors',
                    //     list: OriginalRequestErrorsList,
                    //     meta: { label: "Request Errors" },
                    //     icon: <SupervisedUserCircleIcon />,
                    // },
                    {
                        name: 'burned',
                        list: BurnedList,
                        meta: { label: "Download & Burn" },
                        icon: <LocalFireDepartment />,
                    },
                    // {
                    //     name: 'burn_list',
                    //     list: BurnList,
                    //     meta: { label: "Burn" },
                    //     icon: <LocalFireDepartment />
                    // },
                    {
                        name: 'aft',
                        list: AftList,
                        show: AftShow,
                        create: AftCreate,
                        meta: { label: "Masterpieces" },
                    },
                    // {
                    //     name: 'white_list',
                    //     list: WhiteListList,
                    //     meta: { label: "Whitelist" },
                    // },
                    {
                        name: 'squares',
                        list: ListSquare,
                        // create: CreateSquare,
                        edit: UpdateSquare,
                        show: ShowSquare
                    },
                    {
                        name: 'orders',
                        list: OrdersList,
                        meta: { label: "Trading" },
                        icon: <HistoryToggleOffIcon />
                    }, {
                        name: 'news',
                        list: NewsList,
                        create: NewsCreate,
                        edit: NewsEdit,
                        show: NewsShow,
                        meta: { label: "News" },
                        icon: <NewspaperIcon />
                    }, {
                        name: 'announcements',
                        list: AnnouncementsList,
                        create: AnnouncementsCreate,
                        edit: AnnouncementsEdit,
                        show: AnnouncementsShow,
                        meta: { label: "Announcemenets" },
                        icon: <NewspaperIcon />
                        }, {
                            name: 'staticlink',
                            list: ListStaticLink,
                            create: CreateStaticLink,
                            edit: EditStaticLink,
                            show: ShowStaticLink,
                        meta: { label: "Market Information" },
                            icon: <LinkIcon />
                    }, {
                        name: "tickets",
                        list: TicketsList,
                        create: TicketsCreate,
                        edit: TicketsEdit,
                        meta: { label: 'Tickets' },
                        icon: <ChatIcon />
                    },]}
                    legacyRouterProvider={{
                        ...routerProvider,
                        routes: [
                            {
                                element: <ForgotPassword />,
                                path: "/forgot",
                            },
                            {
                                element: <ResetPassword />,
                                path: "/reset_password",
                            },
                            {
                                element: <Register />,
                                path: "/register",
                            }

                        ]
                    }}
                    LoginPage={Login}

                />
            </RefineSnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
