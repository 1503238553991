import {IUser} from '../interfaces';
import {BaseRecord, HttpError} from "@pankod/refine-core";
import {Role} from '../models'
import {LocalStorageProvider} from "./LocalStorageProvider";
import axios from "axios";
import {RequestMaker} from "./RequestMaker";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.error,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

interface IRequestProvider {
    apiEndpoint: string;
    user: any;
    request: (path: string, data: BaseRecord) => Promise<BaseRecord>;
    signup: (objUser: BaseRecord, persist: boolean) => Promise<BaseRecord>;
    fetchWsToken: () => Promise<BaseRecord>;
    signin: (email: string, password: string, otp_now: string) => Promise<BaseRecord>;
    signout: () => Promise<BaseRecord>;
    signoutClient: () => void;
    getOTP: (email: string) => Promise<BaseRecord>;
    requestEmailVerification: () => Promise<BaseRecord>;
    emailVerification: (authentication_code: string) => Promise<BaseRecord>;
    requestForChangeSignin: (email: string) => Promise<BaseRecord>;
    changeSignin: (email: string, password: string, otp_now: string, otp_secret: string, authcode: string) => Promise<BaseRecord>;
    getUser: () => IUser | null;
    getUserRole: () => string;
    getUserRoles: () => string[];
    setUserProperty: (key: string, value: any) => IUser | null,
    _saveAccessData: (data: BaseRecord) => void;
    _saveWsToken: (token: string) => void;
    _clearAccessData: () => void;
}

// @ts-ignore
export const RequestProvider: IRequestProvider = {

    apiEndpoint: process.env.REACT_APP_API_URL as string,

    user: null,

    getUser: () => {
        if (!RequestProvider.user) {
            RequestProvider.user = LocalStorageProvider.getSessionData("user");
        }
        return RequestProvider.user
    },
    setUserProperty: (key, value) => {
        const user = RequestProvider.getUser();
        if (user) {
            user[key] = value;
            LocalStorageProvider.setSessionData("user", (user as any));
            RequestProvider.user = user;
        }

        return user;
    },

    getUserRole: () => {
        return Role.getUserRole(RequestProvider.getUser())
    },
    getUserRoles: () => {
        return Role.getUserRoles(RequestProvider.getUser())
    },

    request: (path: string, data: BaseRecord) => {
        // NOTA in data la chiave 'file'
        // deve essere usata solo per i binary.
        const file = data['file'];
        data['file'] = undefined;
        return RequestMaker.call(axiosInstance, RequestProvider.apiEndpoint, RequestProvider.apiEndpoint + path, `${path}`, [data], file)
    },

    getOTP: (email: string) => {
        return RequestProvider.request(
            "/utility/otp",
            {"name": email}
        )
    },

    requestEmailVerification: () => {
        return RequestProvider.request(
            "/user/request_for_email_verification",
            {}
        )
    },

    emailVerification: (authentication_code: string) => {
        return RequestProvider.request(
            "/user/email_verification",
            {"authcode": authentication_code}
        )
    },

    signup: (objUser: BaseRecord) => {
        let promiseReturn = RequestProvider.request(
            "/user/signup",
            objUser
        );
        promiseReturn.then((request) => {
            RequestProvider._saveAccessData(request.data)
        });
        return promiseReturn
    },

    fetchWsToken: () => {
        let promiseReturn = RequestProvider.request(
            "/user/wsstoken", {
            bcid: RequestProvider.user?.hlf[0]?.bcid
        }
        );
        promiseReturn.then((request) => {
            //console.log('fetchWsToken')
            //console.log(request)
            RequestProvider._saveWsToken(request?.data?.result?.token?.token);
        });
        return promiseReturn
    },

    signin: (email: string, password: string, otp_now: string) => {
        let returnPromise = RequestProvider.request(
            "/user/signin",
            {
                email: email,
                password: password,
                otp_now: otp_now
            }
        );
        returnPromise.then((request) => {
            RequestProvider._saveAccessData(request.data);
        });
        return returnPromise;
    },

    signout: () => {
        let returnPromise = RequestProvider.request(
            "/user/signout",
            {},
        );
        returnPromise.then(() => {
            RequestProvider._clearAccessData()
        }).catch(() => {
            RequestProvider._clearAccessData()
        })
        return returnPromise;
    },

    signoutClient: () => {
        RequestProvider._clearAccessData()
    },

    requestForChangeSignin: (email: string) => {
        return RequestProvider.request(
            "/user/request_for_change_signin",
            {email: email}
        )
    },

    changeSignin: (email: string, password: string, otp_now: string, otp_secret: string, authcode: string) => {
        return RequestProvider.request(
            "/user/change_signin",
            {
                email: email,
                password: password,
                otp_now: otp_now,
                otp_secret: otp_secret,
                authcode: authcode
            }
        )
    },

    _saveAccessData: (data: BaseRecord) => {
        RequestProvider.user = data.user;
        LocalStorageProvider.setSessionData("signatureId", data.signature_id);
        LocalStorageProvider.setSessionData("signatureKey", data.signature_key);
        LocalStorageProvider.setSessionData("user", data.user);
        if (data.user?.hlf[0]?.bcid) {
            LocalStorageProvider.setSessionData('bcid', data.user?.hlf[0]?.bcid)
        }

    },


    _saveWsToken: (token: string) => {
        LocalStorageProvider.setSessionData('wsToken', token)
        LocalStorageProvider.wsToken = token;
    },

    _clearAccessData: () => {
        RequestProvider.user = null;
        LocalStorageProvider.delSessionData("signatureId");
        LocalStorageProvider.delSessionData("signatureKey");
        LocalStorageProvider.delSessionData("user");
        LocalStorageProvider.delSessionData("wsToken");
        LocalStorageProvider.delSessionData("bcid");
    },


};
