import React, {useEffect, useState} from 'react'

import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";

import './Login.scss'
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useLogin, useNavigation, usePermissions, useNotification, LayoutWrapper } from "@refinedev/core";
import LogoWhtexch from '../assets/logo-whtexch.png'
import { AuthAdmin, RequestProvider } from 'shared-libs/src/providers';
import { Link } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab';

type LoginVariables = {
    email: string;
    password: string;
    otp_now: string;
};

export const FormAuthCode = ({ setMessageType }) => {
    const { open } = useNotification();
    const [loading, setLoading] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm();
    const onFinish = () => {
        let values = getValues()
        setLoading(true)
        RequestProvider.emailVerification(values.authentication_code).then((response) => {
            setLoading(false)
            if (response.data) {
                RequestProvider.setUserProperty('email_is_verified', 1);
                setMessageType(3)
            } else {
                if (open) {
                    open?.({
                        type: 'error',
                        message: response?.data?.message ?? 'Error on sending verification email. Please, contact our customer care'
                    });
                }
            }
        }).catch((response) => {
            setMessageType(3)
            setLoading(false)
            if (open) {
                open?.({
                    type: 'error',
                    message: response?.message ?? response?.data?.message ?? 'Error on sending verification email. Please, contact our customer care'
                });
            }
        })
    };
    return (
        <Box className='login-form login-form-admin login-form-email-code' component={"form"} onSubmit={handleSubmit(onFinish)}>
            <FormControl className='login-form'>
                <Typography className='login-form-code-input'>
                    Insert the authentication code received by email
                </Typography>
                <Controller
                    name="authentication_code"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                        let error = (errors.authentication_code) ? { error: true, helperText: "required field" } : {}
                        return (
                            <TextField fullWidth
                                id="login-authentication_code"
                                label="Authentication Code"
                                variant="outlined"
                                {...error}
                                {...field}
                            />
                        )
                    }}
                />
                <FormControl>
                    <LoadingButton variant='contained' loading={loading} color='info' type="submit">Verify</LoadingButton>
                </FormControl>
            </FormControl>

        </Box>

    )
}


export function Login() {
    useEffect(() => {
        document.title = 'Whtexch - Login';
    }, []);
    const [messageType, setMessageType] = useState(0)
    const [loggedin, setLoggedin] = useState(false)
    const [notificationSent, setNotificationSent] = useState(false)
    const [notificationClick, setNotificationClick] = useState(false)
    const {data: permissionsData} = usePermissions({
        options: {
            notifyOnChangeProps: ['data']
        },
        v3LegacyAuthProviderCompatible: true
    });

    const { mutate: login, isLoading, ...rest } = useLogin<LoginVariables>({
        v3LegacyAuthProviderCompatible: true
    });
    const {open} = useNotification();
    const { push, replace } = useNavigation();


    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues
    } = useForm();
    const onFinish = () => {
        let values = getValues()
        login({
            email: values.email,
            password: values.password,
            otp_now: values.otp_now
        }, {
            onSuccess: async (hasAlreadyValidated, variables, context) => {
                const { valid } = await AuthAdmin.getPermissions()
                if (!valid) {
                    setLoggedin(true);
                    // window.location.replace('/')
                } else {
                    window.location.replace('/')
                }
            }
        })
    };

    useEffect(() => {
        if (!loggedin) {
            return
        }
        if (!permissionsData.valid_email) {
            setMessageType(2)
        } else if (permissionsData.valid_pending_user) {
            setMessageType(3)
        } else if (permissionsData.valid) {
            replace('/')
            if (permissionsData.admin) {
                // push('/')
            }
        } else {
            RequestProvider._clearAccessData();
            open?.({message: 'Invalid credential', type: "error"});
        }
    }, [loggedin])
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <LayoutWrapper Sider={() => <></>}>
            <Box className='logo-admin-container'>
                <img src={LogoWhtexch} alt='Logo'/>
            </Box>
            <Box className='login-form-admin-container'>
                {messageType == 0 && <Box component={"form"} onSubmit={(e) => {
                    handleSubmit(onFinish)(e)
                        // you will have to catch those error and handle them
                        .catch(() => {
                            if (open) {
                                open({message: 'Error on submit login. Contact our customer care', type: "error"});
                            }
                        });
                }}>

                    <FormControl className='login-form login-form-admin'>
                        <Controller
                            name="email"
                            control={control}
                            rules={{required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/}}
                            render={({field}) => {
                                let error = (errors.email) ? {error: true, helperText: "required field"} : {}
                                return (
                                    <TextField fullWidth
                                               id="login-email"
                                               label="Email"
                                               variant="outlined"
                                               {...error}
                                               {...field}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="password"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                let error = (errors.password) ? {
                                    error: true,
                                    helperText: 'required field'
                                } : {}
                                return (
                                    <TextField fullWidth
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        id="login-password"
                                               label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                               variant="outlined"
                                               {...error}
                                               {...field}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="otp_now"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                let error = (errors.otp_now) ? {error: true, helperText: "required field"} : {}
                                return (
                                    <TextField fullWidth
                                               id="login-otp_now"
                                               label="Insert OTP"
                                               variant="outlined"
                                               {...error}
                                               {...field}
                                    />
                                )
                            }}
                        />
                        <FormControl>
                            <LoadingButton loading={isLoading} variant='contained' color='secondary' type="submit">Login</LoadingButton>
                        </FormControl>
                        <FormControl className={"back-bottom-container"}>
                            <ButtonGroup>
                                <Button className='button-link' variant={"text"}>
                                <Link className='link' to={"/forgot"}>Forgot Password? </Link>
                            </Button>
                                <Button className='button-link' variant={"text"}>
                                    <Link className='link' to={"/register"}>Register </Link>
                                </Button>
                            </ButtonGroup>
                        </FormControl>
                    </FormControl>
                </Box>}
                {messageType === 2 &&
                    <Box>
                        <FormAuthCode setMessageType={setMessageType} />
                        {!notificationSent && <Card className='login-form login-form-admin'>
                            <CardContent>
                                <Typography>
                                    If you haven't received the verification email yet,
                                    use the button below to resend it
                                </Typography>
                            </CardContent>
                            <CardActions className='resend-button-card'>
                                <Button color={"secondary"} onClick={(event) => {
                                    if (!notificationClick) {
                                        RequestProvider.requestEmailVerification().then(() => {
                                            setNotificationSent(true)
                                        }).catch((response) => {
                                            if (open) {
                                                open({
                                                    type: 'error',
                                                    message: response.data.message ?? 'Error on sending verification email. Please, contact our customer care'
                                                });
                                            }
                                        })
                                        setNotificationClick(true)
                                    }

                                }}>RESEND</Button>
                            </CardActions>
                        </Card>}
                        {notificationSent && <Card className='login-form login-form-admin'>
                            <CardContent>
                                <Typography>
                                    Please check your email to verify your account. Once verified, our team will contact you
                                    shortly
                                    to start the registration process.
                                </Typography>
                            </CardContent>
                        </Card>}
                </Box>
                }
                {messageType === 3 && <Card className='login-form login-form-admin'>
                    <CardContent>
                        <Typography>
                            Verification account in progress. You will be contacted as soon as possible
                        </Typography>
                    </CardContent>
                </Card>}
            </Box>
        </LayoutWrapper>
    );
}

