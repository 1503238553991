import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { BankList } from "./list";
import { BankAudit } from "./logs";
import { BuyRequestList } from "pages/bank_buy_requests";


export function BankTabs() {
    const [value, setValue] = useState('1');

    const handleChange = (_evt, value) => {
        setValue(value);
    }

    return <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Bank" value="1" />
                    <Tab label="Buy request" value="2" />
                    <Tab label="Sell request" value="3" />
                    <Tab label="Audit log" value="4" />
                </TabList>
            </Box>
            <TabPanel value="1"><BankList movement_type="buy" /></TabPanel>
            <TabPanel value="2"><BuyRequestList /></TabPanel>
            <TabPanel value="3"><BankList movement_type="sell" /></TabPanel>
            <TabPanel value="4"><BankAudit /></TabPanel>
        </TabContext>
    </Box>
}