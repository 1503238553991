import React, {useEffect, useState} from "react";
import {ResetPasswordForm} from "../components/ResetPasswordForm";
import {Edit} from "@refinedev/mui";
import {Card, CardHeader, IconButton, Typography} from "@mui/material";
import {LayoutWrapper, useGetIdentity, useNavigation} from "@refinedev/core";
import './ResetPassword.scss'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ResetPassword = () => {
    const {data: user} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const {show} = useNavigation()
    return (
        <LayoutWrapper>
            <Card sx={{maxWidth: "1100px", margin: '0 auto', padding: "0 50px 50px 50px"}}>
                <CardHeader title={<Typography variant={"h5"}>Reset Password</Typography>}
                            avatar={<IconButton onClick={() => {
                                show('users', user.id)
                            }}>
                                <ArrowBackIcon/>
                            </IconButton>}/>
                <ResetPasswordForm user={user}/>
            </Card>
        </LayoutWrapper>
    )
}