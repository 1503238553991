import {AuthProvider} from "@pankod/refine-core";
import {Auth} from './Auth'
import {RequestProvider} from "./RequestProvider";
import {Role, Status} from '../models'

export const AuthAdmin: AuthProvider = {
    ...Auth,
    checkAuth: () => {
        const permission = AuthAdmin.getPermissions()
        return permission.then((params) => {
            return (params.admin && params.valid) ? Promise.resolve() : Promise.reject()
        })
    },
    getPermissions: () => {
        const user = RequestProvider.getUser()
        let user_role: number[] = [], user_status = 0, email_is_verified = 0;

        if (user) {
            user_status = user.user_status ?? 0;
            user_role = user.roles ?? [];
            email_is_verified = user.email_is_verified ?? 0;
        }

        return Promise.resolve({
            'loggedin': !!user,
            'valid': !!(user_status && user_status === Status.Active),
            'admin': user_role.includes(Role.Admin_Manager_Validator) || user_role.includes(Role.Admin_Board_Validator) || user_role.includes(Role.Generic_Admin) || user_role.includes(Role.Super_Admin),
            'manager': user_role.includes(Role.Admin_Manager_Validator),
            'board': user_role.includes(Role.Admin_Board_Validator),
            'super_admin': user_role.includes(Role.Super_Admin),


            'valid_email': !!email_is_verified,
            'valid_pending_user': !!(user_status && user_status > Status.Revoked && user_status <= Status.Manager_validated),

        })
    },
};