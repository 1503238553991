import { DateField, NumberField, TagField, useDataGrid } from "@refinedev/mui";
import {DataGrid, GridColumns, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {Box, Card, CardContent} from "@mui/material";
import React, { useState } from "react";
import { IAft, IAftLite, ITradingAudit } from "shared-libs/src/interfaces";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";
import { useCurrencyStore } from "hooks/useCurrency";
import { OrderMatchesDialog } from "components/OrderMatchesDialog";

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{justifyContent: 'space-between'}}>
            <Box sx={{ fontSize: '1.5rem' }}>History</Box>
            <Box>History Report<GridToolbarExport aria-label={'Download'} sx={{marginLeft: '16px'}} variant="actionDetailPage"/></Box>
        </GridToolbarContainer>
    );
}


export const AftHistory = ({aftcontract_id}) => {
    const [details, setDetails] = useState<IAftLite | null>(null)
    const { dataGridProps } = useDataGrid<ITradingAudit>({
        resource: "trading_audit",
        syncWithLocation: false,
        queryOptions: {
            ...GlobalConfig.adminQueryOptions
        },
        pagination: {
            mode: "off"
        },
        filters: {
            permanent: [
                {
                    field: "aftcontract_id",
                    operator: "eq",
                    value: aftcontract_id,
                },
            ]
        }
    })
    const { getValue, currency, init } = useCurrencyStore()
    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "id",
                headerName: "ID",
                flex: 0.75,
            },
            {
                field: "timestamp",
                headerName: ("DATE & TIME (UTC)"),
                description: ("date_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                minWidth: 160,
                flex: 1.25,
                valueFormatter: ({value}) => {
                    return value && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8)
                },
                renderCell: ({ value }) => value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"} /> : ""
            },
            {
                field: "aftcontract_id",
                headerName: "AFT id",
                valueGetter: ({ row }) => row.artwork?.work_of_ingenuity || row.id,
                flex: 1,
            },
            {
                field: "order.type",
                headerName: "Order Type",
                flex: 1,
                valueGetter: ({ row: { order: { type } } }) => {
                    return type
                },
                renderCell: ({ value }) => <TagField value={value} />
            },
            {
                field: "entry_type",
                headerName: ("Status"),
                headerAlign: "center",
                align: "center",
                flex: 1,
            },
            {
                field: "order_size",
                headerName: "Order Size",
                type: 'number',
                headerAlign: "left",
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { order: { init_quantity } } }) => init_quantity,
                renderCell: ({ value }) => value ? <NumberField value={value} /> : ""
            },
            {
                field: "quantity",
                headerName: ("executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : "-"
                }
            },
            {
                field: "not_executed",
                headerName: ("not executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: (params) => {
                    return params.row.order.quantity
                },
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : "-"
                }
            },
            {
                field: "order.avg_price",
                headerName: "Average purchase price",
                flex: 1,
                valueGetter: ({ row: { order: { avg_price } } }) => avg_price,
                renderCell: ({ value }) => {
                    return getValue(value)
                }
            },
            { 
                field: "bcid", 
                headerName: "BCID", 
                flex: 0.5,
                valueGetter: ({ row: { order: { user_bcid } } }) => user_bcid,
                renderCell: ({ value }) => {
                    return value
                }
             },
            {
                field: "order.user.profile_data.firstname user.profile_data.lastname",
                headerName: "Customer",
                flex: 1.5,
                valueGetter: ({ row: { order: { user } } }) => user?.profile_data ? user.profile_data.firstname + " " + user.profile_data.lastname : "n/a",
                whiteSpace: 'wrap',
            },
            {
                field: "order.user.hlf[0].company_name",
                headerName: "Company",
                valueGetter: ({ row: { order: { user } } }) => user?.hlf ? user.hlf[0].company_name : "n/a",
                flex: 1,
            },
            {
                field: "market",
                headerName: "Market",
                flex: 1,
                renderCell: ({ value }) => <TagField value={value} />
            },
            {
                field: "order.price_for_unit",
                headerName: "Limit Price (WHTEXs)",
                type: 'number',
                minWidth: 50,
                headerAlign: "left",
                flex: 1,
                valueGetter: ({ row: { order: { price_for_unit } } }) => price_for_unit,
                renderCell: ({ value }) => getValue(value)
            },
            {
                field: "expiration_date",
                headerName: ("validity period"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                minWidth: 50,
                flex: 2,
                valueFormatter: ({ value }) => {
                    return value && value != 'GTC' && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8) + " " + [value.substring(8, 10), value.substring(10, 12), value.substring(12, 14),].join(':')
                },
                valueGetter: ({ row: { order: { expiration_date } } }) => {
                    return expiration_date?.length ? expiration_date : "GTC"
                },
                renderCell: ({ value }) => value === 'GTC' ? 'GTC' : (value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"} /> : "")
            },
        ],
        [],
    );
    // header: 80 - row: 52
    // const totalRow = 80 + (52 * 20)
    return (
        <Card sx={{mb: '30px'}}>
            {init}
            {details && <OrderMatchesDialog setOrder={setDetails} order={details} />}
            <CardContent sx={{pl: '20px', pr: '20px'}}>
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    onRowClick={({ row }) => setDetails(row)}
                    className='buy'
                    headerHeight={80}
                    components={{Toolbar: CustomToolbar}}
                    autoHeight
                    {...GlobalConfig.dataGridConfig}
                />
            </CardContent>
        </Card>
    )
}