import { LiveProvider, LiveEvent } from "@pankod/refine-core";
import { LocalStorageProvider } from "./LocalStorageProvider";
import { RequestProvider } from "./RequestProvider";


let client: WebSocket | null;
const MAX_RETRIES = 7;
let retries = 0;
const channels = {}


const refreshToken = (clientUrl, token) => {
    if (retries <= MAX_RETRIES) {
        client = null;
        setTimeout(() => {


            RequestProvider.fetchWsToken().then(async () => {
                token = await LocalStorageProvider.getWsToken()

                getClient(clientUrl, token);
                retries++;
            })
        }, 2 ** retries * 100)

    }
}

const getClient = (clientUrl: string, token: string) => {
    if (!client) {
        client = new WebSocket(clientUrl + '?token=' + token);
        client.onerror = () => {
            refreshToken(clientUrl, token)
        }
        client.onclose = () => {
            client = null;
            refreshToken(clientUrl, token)
        }
        initializeListeners();
    }

    return client
}

const initializeListeners = () => {
    if (client) {
        client.onopen = _evt => {
            retries = 0;
        }
        client.onmessage = evt => {
            try {
                const _message_data = JSON.parse(evt.data)
                for (const [key, _listener] of Object.entries(channels[_message_data?.channel] || {})) {
                    if (typeof _listener === 'function') {
                        _listener(evt)
                    }
                }
            } catch (e) {
                console.error(e);
                return
            }
        }
    }
}
const LiveProviderWhtexch = (clientUrl: string): LiveProvider => {
    let token = LocalStorageProvider.getWsToken();
    if (token === null) {
        return {
            subscribe: _options => { },
            unsubscribe: _subscription => { }
        }
    }
    getClient(clientUrl, token)

    return {
        publish: (_event: LiveEvent) => { },
        subscribe: ({ channel, types, params, callback }) => {
            channels[channel] = channels[channel] || {};

            const listener = function (message) {
                const message_data = JSON.parse(message.data)
                if (types.includes("*") || types.includes(message_data.type)) {
                    if (
                        message_data.type !== "created" &&
                        params?.ids !== undefined &&
                        message_data?.payload?.ids !== undefined
                    ) {
                        if (
                            params.ids.filter((value) =>
                                message_data.payload.ids!.includes(value),
                            ).length > 0 || (params.ids.length === 0 && message_data.payload.ids.length === 0)
                        ) {
                            callback(message_data as LiveEvent);
                        }
                    } else {
                        callback(message_data);
                    }
                } else {
                    callback(message_data);
                }
            };
            const channel_key = JSON.stringify(params)
            channels[channel][channel_key] = listener
            if (client) {
                if (client.readyState === client.OPEN && channel === 'resources/trade' && params?.id) {
                    client.send(`${params?.id}`)
                }
                initializeListeners()
            }
            return { channel, types, params, callback, listener };
        },
        unsubscribe: ({ channel, types, params, callback }) => {
            const channel_key = JSON.stringify(params)
            try {
                delete channels[channel][channel_key]
            } catch (e) { }
        }
    };
};

export default LiveProviderWhtexch;