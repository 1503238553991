import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { useNavigation } from '@refinedev/core'
import { CreateButton, EditButton, List, ShowButton, useDataGrid } from '@refinedev/mui'
import React from 'react'
import { GlobalConfig } from 'shared-libs/src/GlobalConfig'
import { ISquare } from '.'



export function ListSquare() {
    const { dataGridProps } = useDataGrid<ISquare>({
        resource: 'squares',
    })

    const columns: GridColumns<ISquare> = [
        {
            flex: 1,
            field: 'square_id',
            headerName: 'Square ID'
        },
        {
            flex: 1,
            field: 'is_active',
            headerName: 'Active',
            renderCell: ({ row }) => row.is_active ? 'Yes' : 'No'
        },
        {
            flex: 1,
            field: 'opening',
            headerName: 'Opening Hours',
            renderCell: ({ row: { opening_time, closing_time } }) => {
                return `From ${opening_time} to ${closing_time}`
            }
        },
        {
            flex: 1,
            field: 'actions',
            renderCell: (({ row }) => {
                return <>
                    <EditButton accessControl={{ hideIfUnauthorized: true }} hideText variant='actionTable' disabled={false} recordItemId={row.square_id} />
                </>
            }),
            ...GlobalConfig.actionColumnConfig
        }
    ];



    return <>
        <DataGrid {...dataGridProps} columns={columns} getRowId={(row) => row.square_id} />
    </>
}