import {AxiosInstance, AxiosResponse} from "axios";
import {LocalStorageProvider} from "./LocalStorageProvider";
import * as CryptoJS from "crypto-js";
import {HttpError, useNavigation} from "@pankod/refine-core";
import {RequestProvider} from "./RequestProvider";

interface IRequestMaker {
    call: (httpClient: AxiosInstance, apiUrl: string, url: string, path: string, allData: any[], file?: any) => Promise<AxiosResponse<any, any>>;
}

const bcidAllowed = path => {

    return [
        '/aftoriginal/details',
        '/aftoriginal/download',
        '/aftoriginal/mark_as_downloaded',
        '/aftoriginal/clicked',
        '/aftoriginal/request_for_original',
        '/aftpreview/aftcontract',
        '/aftpreview/close_trading',
        '/aftpreview/create_preview',
        '/aftpreview/get_contents',
        '/aftpreview/invalidate_preview',
        '/aftpreview/mark_as_downloaded',
        '/bcid/get_my_history',
        '/trading/client/aftcontract/buy',
        '/trading/client/aftcontract/order/cancel',
        '/trading/client/aftcontract/sell',
        '/trading/client/balance/aft',
        '/trading/client/balance/all_aft',
        '/trading/client/balance/wht',
        '/trading/client/is_only_owner',
        '/trading/client/is_only_owner_with_committed',
        '/trading/search_aftcontracts ',
        '/trading/search_aftcontracts',
        '/trading/search_orders',
        '/trading/view/aftcontracts',
        '/trading/view/orders',
        '/user/set_bcid_display_name',
        '/user/set_bcid_visibility',
        '/user/wsstoken',
        '/utility/generate_bcid_transfer_reason',
        '/aftoriginal/get_image_backup_info',
        '/bank/user/search_movements',
        '/bank/audit',
        '/trading/audit'

    ].includes(path.startsWith('/') == false ? `/${path}` : path);
}


const isDownload = url => {
    const urlObject = new URL(url);
    console.log('url', url, urlObject)
    switch (urlObject.pathname) {
        case '/aftoriginal/download':
        case '/aftpreview/get_contents':
        case '/trading/aftcontract/document/download':
        case '/utility/decrypt':
            return true;
        default: return false;
    }
}


export const RequestMaker: IRequestMaker = {
    // @ts-ignore
    call: async (httpClient: AxiosInstance, apiUrl: string, url: string, path: string, allData: any[], file?: any) => {
        console.log('allData', url, path, allData)
        if (LocalStorageProvider.getSessionData('bcid') && bcidAllowed(path)) {
            allData.push({ bcid: LocalStorageProvider.getSessionData('bcid') })
        }
        httpClient.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response?.data?.error === "Invalid signature ID." || error.response?.data?.error === "Banned IP") {
                    RequestProvider.signoutClient();
                    window.location.replace("/");
                    return Promise.reject();
                }
                const customError: HttpError = {
                    ...error,
                    message: error.response?.data?.error,
                    statusCode: error.response?.status,
                };

                return Promise.reject(customError);
            },
        );

        const signParams = (path: string, externalData: object) => {


            let toSign = "",
                data = {
                    time: LocalStorageProvider.stamp(),
                    path: path,
                    signature_id: LocalStorageProvider.getSignatureId(),
                    data: JSON.stringify(externalData)
                };
            [
                "time",
                "path",
                "signature_id",
                "data",
            ].forEach(k => toSign += data[k]);

            const signatureKey = LocalStorageProvider.getSignatureKey()
            if (!signatureKey) {
                return data
            }
            return {
                ...data,
                signature: CryptoJS.MD5(toSign + signatureKey) + ''
            }
        }
        const formData = new FormData();
        let prefix = ''
        if (apiUrl.endsWith('/admin')) {
            prefix = '/admin/'
        } else if (!apiUrl.startsWith('/')) {
            prefix = '/'
        }
        if (path.startsWith('/')) {
            path = path.slice(1)
        }
        let dataToSign = {}
        allData.forEach((data) => {
            if (!data) {
                return
            }
            data = JSON.parse(JSON.stringify(data))
            dataToSign = {...dataToSign, ...data}
        })
        allData.push(signParams(`${prefix}${path}`, dataToSign))
        allData.forEach((data) => {
            if (!data) {
                return
            }
            data = JSON.parse(JSON.stringify(data))
            Object.keys(data).forEach(k => formData.append(k, data[k]));
        })
        if (!!file && typeof file !== "undefined") {
            formData.append("file", file);
        }

        return httpClient.post(url, formData, (isDownload(url) ? { responseType: 'blob' } : undefined));
    }
}