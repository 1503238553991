import { useCustomMutation, useShow } from '@refinedev/core'
import { EditButton, Show } from '@refinedev/mui';
import React, { useEffect } from 'react'
import { ISquare } from '.';
import { Typography } from '@mui/material';



export function ShowSquare() {

    const { queryResult: { data }, showId } = useShow<ISquare>({
        resource: 'squares'
    })
    const { mutate: isOpenNow, data: isOpenNowData } = useCustomMutation<{ result: Boolean }>({})
    const square = data?.data

    useEffect(() => {
        isOpenNow({
            url: '/exchange/square/is_open_now',
            values: {
                square_id: showId
            },
            method: 'post'
        })
    }, [])

    return <Show>

        <Typography>{square?.square_id}</Typography>

        <EditButton />
    </Show>
}