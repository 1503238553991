export const TicketStatus = {
    Open: 1,
    Replied: 3,
    Closed: 8,
    getLabel: (status_id) => {
        switch (parseInt(status_id)) {
            case TicketStatus.Open:
                return "Open"
            case TicketStatus.Replied:
                return "Replied"
            case TicketStatus.Closed:
                return "Closed";
            default:
                return "Undefined";
        }
    }
}