import {Edit, TagField, DateField} from "@refinedev/mui";
import {Box, TextField, Typography, Grid, Divider, Button} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import React, {useEffect, useState} from "react";
import {TicketStatus} from "shared-libs/src/models";
import './tickets.scss'
import {useCustomMutation, useGetIdentity, useOne, useUpdate} from "@refinedev/core";
import { IUser } from "shared-libs/src/interfaces";
import { LoadingButton } from "@mui/lab";

export const TicketsEdit = () => {
    useEffect(() => {
        document.title = 'Whtexch - Tickets';
    }, []);
    const {
        saveButtonProps,
        refineCore: {queryResult},
        register,
        formState: {errors},
        handleSubmit,
        resetField,
        reset
    } = useForm();
    const ticketsData = queryResult?.data?.data;
    const {mutate: update} = useUpdate();
    const {data: userIdentity} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    console.log("ticket", queryResult?.isLoading, ticketsData?.under_review_by)

    const {data: reviewerUserData, isLoading: reviewerIsLoading} = useOne<IUser>({
        resource: "users",
        id: ticketsData?.under_review_by || "",
        queryOptions: {
            enabled: !!ticketsData?.under_review_by,
        },
    })

    const { mutate: custom, isLoading } = useCustomMutation()

    const onFinish = (params) => {
        //@ts-ignore
        update({
            resource: "tickets",
            id: ticketsData?.ticket_id,
            values: params,
            mutationMode: "pessimistic"
        }, {
            onSuccess: (data, variables, context) => {
                resetField('text')
            }
        })

    }
    const resign = () => {
        custom({
            url: `/ticket/resign`,
            method: "post",
            values: {
                ticket_id: ticketsData?.ticket_id
            }
        }, {
            onSuccess: () => {
                reset()

                queryResult?.refetch()
            }
        })
    }
    const take = () => {
        custom({
            url: `/ticket/take`,
            method: "post",
            values: {
                ticket_id: ticketsData?.ticket_id
            }
        }, {
            onSuccess: () => {
                reset()
                queryResult?.refetch()
            }
        })
    }
    let lastUserMsg = 0, messageTypeCssClass = ""
    const [ActionButton, setActionButton] = useState(<></>)
    const [disabledOption, setDisabledOption] = useState({})
    const [canEdit, setCanEdit] = useState(true)
    useEffect(() => {
        console.log(reviewerUserData)
        if (userIdentity?.id) {
            if (ticketsData?.under_review_by === null) {
                setActionButton(<LoadingButton loading={queryResult?.isLoading || isLoading || queryResult?.isRefetching} variant="actionDetailPage" onClick={take}>Take</LoadingButton>)
            } else if (userIdentity.id === ticketsData?.under_review_by) {
                setActionButton(<LoadingButton loading={queryResult?.isLoading || isLoading || queryResult?.isRefetching} variant="actionDetailPage" onClick={resign}>Resign</LoadingButton>)
            }
            if (userIdentity.id !== ticketsData?.under_review_by || TicketStatus.Closed === parseInt(ticketsData?.ticket_status)) {
                setDisabledOption({disabled: true})
                setCanEdit(false)
            }
        }
    }, [ticketsData, userIdentity, isLoading, queryResult])
    return (
        <Box sx={{maxWidth: '900px', margin: '0 auto'}}>
            <Edit title={<Typography variant="h5">Back</Typography>}
                  saveButtonProps={{...saveButtonProps, ...disabledOption, onClick: handleSubmit(onFinish)}}
                  headerButtons={({}) => (<>{ActionButton} </>)}>
                <Grid sx={{maxWidth: '800px', margin: '0 auto'}} container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className="column-label" variant="body2">Ticket Status:</Typography>
                        <Typography sx={{ml: 2}} variant="body2"> <TagField
                            value={TicketStatus.getLabel(ticketsData?.ticket_status)}/> </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography className="column-label" variant="body2">Under Review By:</Typography>
                        <Typography sx={{ml: 2}}
                            variant="body2"> {reviewerIsLoading ? '' : reviewerUserData?.data.profile_data.lastname + " " + reviewerUserData?.data.profile_data.firstname} </Typography>
                    </Grid>

                    <Grid item xs={12}> <Divider/></Grid>

                    <Grid item xs={6}>
                        <Typography className="column-label" variant="body2">Created:</Typography>
                        <DateField sx={{ml: 2}} format="YYYY/MM/DD" value={ticketsData?.created}/>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography className="column-label" variant="body2">Updated:</Typography>
                        <DateField sx={{ml: 2}} format="YYYY/MM/DD" value={ticketsData?.updated}/>
                    </Grid>

                    <Grid item xs={12} sx={{mb: 4}}> <Divider/></Grid>

                    <Grid item xs={2}> <Typography className="column-label" variant="body2">Subject</Typography> </Grid>
                    <Grid item xs={10}><Typography variant="body1">{ticketsData?.subject}</Typography></Grid>

                    <Grid item xs={2}> <Typography className="column-label" variant="body2">Messages</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Box className={"message-box"}>
                            {ticketsData?.message.map((msg) => {
                                if (msg.user_id !== lastUserMsg) {
                                    messageTypeCssClass = (msg.user_id === ticketsData?.user_id) ? 'client' : 'admin'
                                }
                                return <Box key={msg.tm_id} className={`message-item ${messageTypeCssClass}`}>
                                    <Box className='message-time'>{msg.created}</Box>
                                    <Box>
                                        {msg.message}
                                    </Box>
                                </Box>
                            })}
                        </Box>
                    </Grid>
                    {
                        canEdit &&
                        <>
                            <Grid item xs={2}> </Grid>
                            <Grid item xs={10}>
                                <Box
                                    component="form"
                                    sx={{display: "flex", flexDirection: "column"}}
                                    autoComplete="off"
                                >
                                    <TextField
                                        {...register("text", {
                                            required: "This field is required",
                                        })}
                                        error={!!(errors as any)?.text}
                                        helperText={(errors as any)?.text?.message}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        label="Reply"
                                        name="text"
                                        multiline={true}
                                        minRows={5}
                                    />
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </Edit>
        </Box>
    );
};
