import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { DateField, List, NumberField, useDataGrid } from "@refinedev/mui";
import React, { useEffect, useState } from "react";

import { Done, DoneAll } from '@mui/icons-material';
import { IconButton, Typography } from "@mui/material";
import { useCustomMutation, useNotification } from "@refinedev/core";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { User } from "shared-libs/src/interfaces";
import { useCurrencyStore } from "hooks/useCurrency";



const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm" value={params.value} />;
}
const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}
const BCID_START_SEQUENCE = "KZ"
const BCID_END_SEQUENCE = "X"

const LENGTH = 8
const PART_LENGTH = 4

export const renderSeedFromBcid = (seed, bcid: string) => {

    const sid = bcid.padStart(LENGTH, "0")
    const first_bcid_char = sid.slice(0, PART_LENGTH)
    const first_last_char = sid.slice(-PART_LENGTH)

    return `${BCID_START_SEQUENCE}-${seed}-${first_bcid_char}-${first_last_char}-${BCID_END_SEQUENCE}`
}

export const BuyRequestList: React.FC = () => {

    const { getValue, init } = useCurrencyStore()
    const [queryKeyHashFn, setQueryKeyHashFn] = useState(`${+new Date}`)


    const columns = React.useMemo<GridColumns<{
        movement_id: number,
        seed: string
        bcid: number
        is_executed: number,
        bcid_user_data?: User,
        approved_by_user_data?: User,
        confirmed_by_user_data?: User,
    }>>(() => [
        { field: "buy_request_id", headerName: "Request ID", flex: 0.75, type: "number", headerAlign: "left" },
        {
            field: "user", headerName: "User", flex: 1,
            valueGetter: ({ row }) => `${row.bcid_user_data?.profile_data?.firstname} ${row.bcid_user_data?.profile_data?.lastname}`,
            renderCell: ({ row }) => `${row.bcid_user_data?.profile_data?.firstname} ${row.bcid_user_data?.profile_data?.lastname}`
        },
        { field: "bcid", headerName: "BCID", flex: 0.5 },
        {
            field: "seed", headerName: "Seed",
            valueGetter: ({ row: { bcid, seed } }) => renderSeedFromBcid(seed, `${bcid}`),
            flex: 1, renderCell: ({ value }) => value
        },
        { field: "created", type: 'date', headerName: "CREATION DATE & TIME (UTC)", flex: 0.75, renderCell: dateRender },
        {
            field: "approved_by_user_data",
            valueGetter: ({ row }) => row.approved_by_user_data ? `${row.approved_by_user_data?.profile_data?.firstname} ${row.approved_by_user_data?.profile_data?.lastname}` : '-',
            headerName: "Approved by", flex: 0.75, renderCell: ({ row }) => row.approved_by_user_data ? `${row.approved_by_user_data?.profile_data?.firstname} ${row.approved_by_user_data?.profile_data?.lastname}` : '-'
        },
        {
            field: "confirmed_by_user_data",
            valueGetter: ({ row }) => row.confirmed_by_user_data ? `${row.confirmed_by_user_data?.profile_data?.firstname} ${row.confirmed_by_user_data?.profile_data?.lastname}` : '-',
            headerName: "Confirmed by", flex: 0.75, renderCell: ({ row }) => row.confirmed_by_user_data ? `${row.confirmed_by_user_data?.profile_data?.firstname} ${row.confirmed_by_user_data?.profile_data?.lastname}` : '-'
        },
        { field: "wht_amount", headerName: "Amount", flex: 1, renderCell: ({ value }) => getValue(value) },
    ], []);





    useEffect(() => {
        document.title = 'Whtexch - Bank';
    }, []);
    const { dataGridProps, tableQueryResult: { refetch }, filters, setFilters } = useDataGrid({
        pagination: {
            mode: "off"
        },
        resource: 'bank_buy_requests',
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            // refetchInterval: 10000,
            queryKeyHashFn: (data) => {
                return queryKeyHashFn
            }
        },
        sorters: {
            initial: [
                { field: 'buy_request_id', order: 'desc' }
            ]
        },
        filters: {
            initial: [
                // { field: 'bcid', operator: 'eq', value: 3602 }
            ]
        }
    });

    const [tempFilters, setTempFilters] = useState<any>(null);

    const runTimeout = () => {
        console.log('timeout_n')
        setTimeout(() => {

            setTempFilters(filters)
            setFilters([])

            // query cache id
        }, 10000)
    }

    useEffect(() => {
        if (filters.length != 0) {
            setFilters([]);
        }
        if (!tempFilters && filters?.length == 0) {
            runTimeout();
        } else {
            filters.length == 0 && refetch({}).then(({ data }) => {
                console.log('changing querykeyhash, data: ', data?.data)
                setTempFilters(null);
            })
        }
    }, [tempFilters, filters])
    useEffect(() => {
        runTimeout()
    }, [])






    console.log(`filterModel `, dataGridProps.filterModel, dataGridProps.rows)
    return (
        <List>
            {init}
            <DataGrid getRowId={({ buy_request_id }) => buy_request_id}  {...dataGridProps} {...dataGridCommonProps} columns={columns} filterMode="client" />
        </List>
    );
};

