import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { DateField, List, useDataGrid } from "@refinedev/mui";
import React, { useEffect } from "react";

import { useNotification } from "@refinedev/core";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { User, IUser } from "shared-libs/src/interfaces";
import { useCurrencyStore } from "hooks/useCurrency";


const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.value} style={{marginBottom: 0}}/>;
}

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}


export const BurnAudit = () => {

    const { getValue, init } = useCurrencyStore()

    const { open } = useNotification()

    useEffect(() => {
        document.title = 'Whtexch - Burn audit';
    }, []);
    const { dataGridProps, tableQueryResult: { refetch }, filters, setFilters } = useDataGrid({
        pagination: {
            mode: "off"
        },
        resource: 'burn_audit',
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            queryKeyHashFn: () => `burned_audit`,
            // refetchInterval: 5000,
        },
    });

    useEffect(() => {
        if (filters.length) {
            setFilters([])
        }
    }, [filters])
    useEffect(() => {
        console.log('aaaa')
        setInterval(() => {
            console.log(`filters.length = ${filters.length}`)
            refetch()
        }, 5000)
    }, [])

    const columns = React.useMemo<GridColumns<{
        user_data?: IUser,
        actor: User,
        profile_data: string, 
        confirmed_by: User,
        approved_by: User,
        action: any,
        aftcontract_id: string,
        timestamp: string,
        artwork?: {
            work_of_ingenuity: string
        }
    }>>(() => [
        {field: "id", headerName: "ID", flex: 1, headerAlign: "left" },
        { field: "timestamp", type: 'date', headerName: "DATE & TIME (UTC)", flex: 2, renderCell: dateRender },
        {
            field: "aftcontract_id", headerName: "AFT ID", flex: 1, headerAlign: "left", valueGetter: ({ row: { aftcontract_id, artwork, ...row } }) => {
                return artwork?.work_of_ingenuity || aftcontract_id;
            },
        },
        {field: "status", headerName: "STATUS", flex: 2, valueGetter: ({row}) => {
            switch(row.action) {
                case "created":
                    return "Download Requested"
                case "approved_one":
                    return "Pending"
                case "approved_two":
                    return "Pending"
                case "burned":
                    return "Burned"
                case "marked_as_downloaded":
                    return "Marked as Downloaded"
                case "rejected":
                    return "Rejected"
                case "clicked":
                    return "Download Launched";
                case "backup_clicked":
                    return "Backup Download Launched";
                default: 
                    return row.action
            }
        }},
        {field: "bcid", headerName: "BCID", flex: 0.75 },
        {field: "user", headerName: "CUSTOMER", flex: 1, valueGetter: ({ row }) => {return `${row.user_data?.profile_data.firstname} ${row.user_data?.profile_data.lastname}`} }, // customer?
        {field: "company", headerName: "COMPANY", flex: 1, valueGetter: ({ row }) => {return row.user_data?.hlf[0].company_name} },
        {field: "market", headerName: "MARKET", flex: 0.80},
        {field: "actor", headerName: "Performed by", flex: 1, valueGetter: ({ row }) => {return `${row.actor.profile_data.firstname} ${row.actor.profile_data.lastname}`} },
        {field: "approved_by_user_data", headerName: "approved by", flex: 1, valueGetter: ({ row }) => {return row.approved_by ? `${row.approved_by?.profile_data?.firstname} ${row.approved_by?.profile_data?.lastname}` : '-'} },
        {field: "confirmed_by", headerName: "confirmed by", flex: 1, valueGetter: ({ row }) => {return row.confirmed_by ? `${row.confirmed_by?.profile_data?.firstname} ${row.confirmed_by?.profile_data?.lastname}` : '-'} },
        {field: "action", headerName: "action type", flex: 2, valueGetter: ({row}) => {
            switch(row.action) {
                case "created":
                    return "Download Requested"
                case "approved_one":
                    return "Download Request Approved"
                case "approved_two":
                    return "Download Request Confirmed"
                case "burned":
                    return "Burn Approved"
                case "marked_as_downloaded":
                    return "Downloaded"
                case "rejected":
                    return "Rejected"
                case "clicked":
                    return "Clicked";
                case "backup_clicked":
                    return "Backup Clicked";
                default: 
                    return row.action
            }
        }},
        
    ], [dataGridProps]);


    return (
        <List title="Download & Burn">
            {init}
            <DataGrid getRowId={({ aftcontract_id, timestamp }) => `${aftcontract_id}${timestamp}`} {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};

