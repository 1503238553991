import React, { useEffect, useState } from 'react'


import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import './Register.scss'
import { useForm } from "@refinedev/react-hook-form";
import { Controller, FieldValues } from "react-hook-form";
import { LayoutWrapper, useRegister } from "@refinedev/core";
import { RequestProvider } from "shared-libs/src/providers/RequestProvider";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LogoWhtexch from '../assets/logo-whtexch.png'

type RegisterVariables = {
    email: string;
    password: string;
    profile_model_id: number,
    profile_data: {
        firstname: string;
        lastname: string;
        phone: string;
    }
    otp_now: string;
    otp_secret: string;
};

export const FormOTP: React.FC<{ values: FieldValues, showOTP: any }> = ({ values, showOTP }) => {
    const [qrImageUrl, setQrImageUrl] = useState("")
    const [qrCode, setQrCode] = useState("")
    const [messageType, setMessageType] = useState(0)
    const [message, setMessage] = useState("")
    const qrImageDimension = 200
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
        getValues
    } = useForm();

    const { mutate: userRegister } = useRegister<RegisterVariables>({
        v3LegacyAuthProviderCompatible: true
    });

    useEffect(() => {
        if (qrImageUrl === "") {
            RequestProvider.getOTP(values.email)
                .then((response) => {
                    //console.log(response.data)
                    setQrImageUrl("data:image/png;base64, " + response.data.qrcode)
                    setQrCode(response.data.code)
                }).catch(error => {
                    //console.log(error)
                })
        }
    }, [values.email, qrImageUrl])

    const onFinish = () => {
        const currentValues = getValues()
        userRegister({
            email: values.email,
            password: values.password,
            profile_model_id: 100,
            profile_data: {
                firstname: values.firstname,
                lastname: values.lastname,
                phone: values.phone,
            },
            otp_now: currentValues.otp_now,
            otp_secret: qrCode,
        }, {
            onSuccess: (data) => {
                RequestProvider.requestEmailVerification().then((response) => {
                    // console.log(response)
                    setMessageType(1)
                }).catch((response) => {
                    // TODO: gestire il caso di OTP sbagliato? Rimandarlo al form di registrazione o mostrare un messagio per un nuovo tentativo?
                    setMessageType(5)
                    setMessage(response.data.message)
                })
            },
            onError: (response) => {
                setMessageType(5);
                setMessage(response.message)
            }
        });
    }
    return (
        <Box>
            <FormControl className='login-form register-form-admin'>
                <Box className='login-form-admin-container'>

                    {(!isSubmitSuccessful || messageType == 0) && <Box component="form" className='register-form' onSubmit={handleSubmit(onFinish)}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography>
                                    To complete the registration it is necessary to activate two-factor authentication via One
                                    Time Password. Use the qr code or enter the code in the Google Authenticator application.
                                    Once activated, enter the OTP code to complete the registration.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {qrCode ? <Typography>Code: {qrCode}</Typography> : <Skeleton variant={"rectangular"} />}
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="otp-qr-placeholder">
                                    {qrImageUrl ?
                                        <img src={qrImageUrl} height={qrImageDimension} alt={"QR Code"} /> :
                                        <Skeleton height={qrImageDimension} width={qrImageDimension} variant={"rectangular"} />
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="otp_now"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        let error = (errors.otp_now) ? { error: true, helperText: "required field" } : {}
                                        return (
                                            <TextField fullWidth
                                                id="register-otp_now"
                                                label="Insert OTP"
                                                variant="outlined"
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid className='form-register-button' item xs={12}>
                                <Button color='secondary' size='medium' type="button" onClick={() => {
                                    showOTP(false);
                                }}>Back</Button>
                                <Button color='primary' size='medium' type="submit">Submit</Button>
                            </Grid>
                        </Grid>
                    </Box>}
                    {messageType === 1 && <Card className='registration-card'>
                        <CardContent>
                            <Typography>
                                Please check your email to verify your account. Once verified, our team will contact you shortly
                                to start the registration process.
                            </Typography>
                        </CardContent>
                    </Card>}
                    {messageType === 5 && <Card className='registration-card warning'>
                        <CardContent>
                            <Typography>
                                {message != "" ? message : "Unable to complete registration. Contact support for more information."}
                            </Typography>
                            <Button onClick={() => {
                                setMessageType(0);
                            }}>RETRY</Button>
                        </CardContent>
                    </Card>}
                </Box>
            </FormControl>
        </Box>
    )
}

export function Register() {
    const [showOTP, setShowOTP] = useState(false)
    useEffect(() => {
        document.title = 'Whtexch - Register';
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
        getValues,
        setError
    } = useForm();
    return (
        <LayoutWrapper Sider={() => <></>}>
            <Box className='logo-admin-container'>
                <img src={LogoWhtexch} alt='Logo' />
            </Box>
            <Box className='login-form-admin-container'>
                {!showOTP && <FormControl className='login-form login-form-admin'>
                    <Box component="form" className='register-form' onSubmit={handleSubmit(() => {
                        //console.log('handleSubmit')
                        //console.log(Object.keys(errors).length, errors, isSubmitSuccessful, isValid, isValidating, isSubmitting, isSubmitted, isDirty, dirtyFields)
                        // workaround: at first click of submit the form is valid but isSubmitSuccessful is false
                        const values = getValues();
                        let hasErrors = false;
                        if (values.password != values.confirm_password) {
                            setError('confirm_password', { message: 'Confirm password is not equal to Password' });
                            hasErrors = true;
                        }
                        if (values.email != values.confirm_email) {
                            setError('confirm_email', { message: 'Confirm email is not equal to Email' });
                            hasErrors = true;
                        }
                        if (!hasErrors) {
                            if (isSubmitSuccessful || Object.keys(errors).length <= 0) {
                                setShowOTP(true)
                            }
                        }
                    })}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    name="firstname"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        let error = (errors.firstname) ? { error: true, helperText: "required field" } : {}
                                        return (
                                            <TextField fullWidth
                                                id="register-name"
                                                label="Name *"
                                                variant="outlined"
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Controller
                                    name="lastname"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        let error = (errors.lastname) ? { error: true, helperText: "required field" } : {}
                                        return (
                                            <TextField fullWidth
                                                id="register-lastname"
                                                label="Last Name *"
                                                variant="outlined"
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        let error = (errors.phone) ? { error: true, helperText: "required field" } : {}
                                        return (
                                            <TextField fullWidth
                                                id="register-phone"
                                                label="Telephone Number *"
                                                type="tel"
                                                variant="outlined"
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="email"
                                    control={control}
                                    /* rules={{required: true, validate: (params) => {console.log(params);return false;}, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}} */
                                    rules={{ required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                                    render={({ field }) => {
                                        let error = (errors.email) ? { error: true, helperText: "required field" } : {}
                                        return (
                                            <TextField fullWidth
                                                id="register-email"
                                                label="Email *"
                                                variant="outlined"
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="confirm_email"
                                    control={control}
                                    /* rules={{required: true, validate: (params) => {console.log(params);return false;}, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}} */
                                    rules={{ required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                                    render={({ field }) => {
                                        let error = (errors.confirm_email) ? {
                                            error: true,
                                            helperText: errors.confirm_email.message?.toString()
                                        } : {}
                                        return (
                                            <TextField fullWidth
                                                id="register-email"
                                                label="Confirm Email *"
                                                variant="outlined"
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: true,
                                        pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                                    }}
                                    render={({ field }) => {
                                        let error = (errors.password) ? {
                                            error: true,
                                            helperText: 'field must have at least two uppercase letters, one special case letter, two digits, three lowercase letter and at least a length of 8'
                                        } : {}
                                        return (
                                            <TextField fullWidth
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                id="register-password"
                                                label="Password *"
                                                type={showPassword ? 'text' : 'password'}
                                                variant="outlined"
                                                className='form-password-input'
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="confirm_password"
                                    control={control}
                                    rules={{
                                        required: true,
                                        pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                                    }}
                                    render={({ field }) => {
                                        let error = (errors.confirm_password) ? {
                                            error: true,
                                            helperText: errors.confirm_password.message?.toString()
                                        } : {}
                                        return (
                                            <TextField fullWidth
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                id="register-confirm_password"
                                                label="Confirm Password *"
                                                type={showPassword ? 'text' : 'password'}
                                                variant="outlined"
                                                className='form-password-input'
                                                {...error}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid className='form-register-button' item xs={12}>
                                <Box></Box>
                                <Button color='success' size='medium' type="submit">Continue</Button>
                                <Button color='secondary' size='medium' type="button" className='button-link' >
                                    <Link style={{ color: 'white', textDecoration: 'none' }} className='link' to={"/"}>Back </Link>

                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </FormControl>
                }
                {showOTP && <FormOTP values={getValues()} showOTP={setShowOTP} />}
            </Box>
        </LayoutWrapper>
    );

}