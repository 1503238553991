import { useShow } from "@refinedev/core";

import {
    Show,
    NumberField,
    DateField,
    TextFieldComponent as TextField,
    BooleanField,
} from "@refinedev/mui";

import { Typography, Grid, Box } from "@mui/material";
import React, {useEffect} from "react";

export const NewsShow = () => {
    useEffect(() => {
        document.title = 'Whtexch - News';
    }, []);
    const {queryResult} = useShow();
    const {data, isLoading} = queryResult;

    const record = data?.data;

    return (
        <Show title={<Typography variant="h5">Back</Typography>} isLoading={isLoading}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> News ID </Typography>
                </Grid>
                <Grid item xs={10}>
                    <NumberField value={record?.id ?? ""}/>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Title </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField value={record?.title}/>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Is Public </Typography>
                </Grid>
                <Grid item xs={10}>
                    <BooleanField value={record?.is_public}/>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Created </Typography>
                </Grid>
                <Grid item xs={10}>
                    <DateField value={record?.created} format={"YYYY/MM/DD HH:MM:ssZ"}/>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Updated </Typography>
                </Grid>
                <Grid item xs={10}>
                    <DateField value={record?.updated} format={"YYYY/MM/DD HH:MM:ssZ"}/>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontWeight="bold"> Body </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Box sx={{maxWidth: '85vw'}} dangerouslySetInnerHTML={{__html: record?.body}}/>
                </Grid>
            </Grid>
        </Show>
    );
};
