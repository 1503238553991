import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { CircularDeterminate, DateField, List, NumberField, useDataGrid } from "@refinedev/mui";
import React, { useEffect } from "react";

import { Done, DoneAll, RemoveDone } from '@mui/icons-material';
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { useCustomMutation, useNotification } from "@refinedev/core";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { User } from "shared-libs/src/interfaces";
import { useCurrencyStore } from "hooks/useCurrency";
import { isLabeledStatement } from "typescript";
import { renderSeedFromBcid } from "pages/bank_buy_requests";


const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.value} style={{marginBottom: 0}}/>;
}

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}


export const BankAudit = () => {

    const { getValue, init } = useCurrencyStore()

    const { open } = useNotification()

    useEffect(() => {
        document.title = 'Whtexch - Bank';
    }, []);
    const { dataGridProps, tableQueryResult: { refetch }, filters, setFilters } = useDataGrid({
        pagination: {
            mode: "off"
        },
        resource: 'bank_logs',
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            queryKeyHashFn: () => `bank_audit_log`,
            // refetchInterval: 5000,
        },
    });

    useEffect(() => {
        if (filters.length) {
            setFilters([])
        }
    }, [filters])
    useEffect(() => {
        console.log('aaaa')
        setInterval(() => {
            console.log(`filters.length = ${filters.length}`)
            refetch()
        }, 10000)
    }, [])

    const columns = React.useMemo<GridColumns<{
        bcid_user_data?: User,
        audit_type: string,
        rejected: string,
        is_executed: number,
        actor: User,
        bcid: string,
        seed: string,
        confirmed_by_user_data: User,
        profile_data: string,
        approved_by_user_data: User,
    }>>(() => [
        { field: "audit_id", headerName: "ID", flex: 1, headerAlign: "left" },
        { field: "user", headerName: "User", flex: 1, renderCell: ({ row }) => `${row.bcid_user_data?.profile_data?.firstname} ${row.bcid_user_data?.profile_data?.lastname}` },
        { field: "bcid", headerName: "BCID", flex: 0.5 },
        { field: "movement_description", headerName: "Seed", flex: 1.5, valueGetter: ({ row: { bcid, seed } }) => seed ? renderSeedFromBcid(seed, `${bcid}`) : '-', },
        { field: "movement_type", headerName: "Type", flex: 0.5 },
        { field: 'audit_status', headerName: "Status", flex: 1 },
        { field: "timestamp", headerName: "Date & time (UTC)", flex: 1.5, type: 'date', renderCell: dateRender },
        {field: "actor", headerName: "Performed by", flex: 1, valueGetter: ({ row }) => {return `${row.actor.profile_data.firstname} ${row.actor.profile_data.lastname}`} },
        {field: "approved_by_user_data", headerName: "approved by", flex: 1, valueGetter: ({ row }) => {return row.approved_by_user_data ? `${row.approved_by_user_data?.profile_data?.firstname} ${row.approved_by_user_data?.profile_data?.lastname}` : '-'} },
        {field: "confirmed_by_user_data", headerName: "confirmed by", flex: 1, valueGetter: ({ row }) => {return row.confirmed_by_user_data ? `${row.confirmed_by_user_data?.profile_data?.firstname} ${row.confirmed_by_user_data?.profile_data?.lastname}` : '-'} },
        {field: "audit_type", headerName: "action type", flex: 1},
        {field: "wht_amount", headerName:"Amount", flex: 1, renderCell: ({value}) => {return  getValue(value)}},
        
        
    ], [dataGridProps]);


    return (
        <List>
            {init}
            <DataGrid getRowId={({ movement_id, audit_type, audit_id }) => `${movement_id}${audit_type}${audit_id}`}  {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};

