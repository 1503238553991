import { Edit, EditButton } from '@refinedev/mui'
import React, { useCallback, useEffect, useState } from 'react'
import { ISquare } from '.';
import { useForm } from '@refinedev/react-hook-form';
import { Alert, Badge, Box, Button, Card, Checkbox, CircularProgress, Divider, FormControl, Grid, Switch, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useCustom, useCustomMutation, useModal, useShow, useUpdate } from '@refinedev/core';
import { error } from 'console';
import { DateCalendar } from '@mui/x-date-pickers'
import { LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment';
import { LoadingButton } from '@mui/lab';
import { GridRow } from '@mui/x-data-grid';
import OTPModal from 'components/OtpModal';



const isMarketOpenOnThisDay = (customOpeningDays, getDefaultOpenStatus, currentSelection) => {
    if (customOpeningDays.data?.data?.result === null && getDefaultOpenStatus(currentSelection) == false) {
        return false
    }
    if (customOpeningDays.data?.data?.result === false) {
        return false;
    }

    if ((customOpeningDays.data?.data?.result === null && getDefaultOpenStatus(currentSelection) == true)) {
        return true;
    }
    if (customOpeningDays.data?.data?.result === true) {
        return true;
    }
}

export function UpdateSquare() {

    const { saveButtonProps, formState: { errors }, control, register, handleSubmit, refineCore: { id, queryResult: showData } } = useForm<ISquare>({
        warnWhenUnsavedChanges: true,
    });

    const { mutate, isLoading } = useUpdate()

    const { mutateAsync: setDefaultOpeningDays, ...defaultOpeningDays } = useCustomMutation({});

    const [saveData, setSaveData] = useState<{ [key: string]: any }>({})
    const [cb, setCb] = useState<(data: any) => void>();

    const [currentSelection, setCurrentSelection] = React.useState<Moment | null>(moment());

    const [refresh, setRefresh] = useState<number>();
    const modal = useModal();


    const openOnThisDay = useCallback(() => (otp) => {

        currentSelection && setCustomOpeningday({
            url: '/exchange/square/set_custom_opening_days',
            method: 'post',
            values: {
                day: currentSelection.format('YYYYMMDD'),
                is_open: true,
                square_id: id,
                otp
            }
        }, {
            onSuccess: () => {
                setRefresh(+new Date)
                const day = currentSelection.get('D');
                setMonthOpenings({ ...monthOpenings, [day]: true })
            }
        })


    }, [currentSelection])
    const closeOnThisDay = useCallback(() => (otp) => {
        currentSelection && setCustomOpeningday({
            url: '/exchange/square/set_custom_opening_days',
            method: 'post',
            values: {
                day: currentSelection.format('YYYYMMDD'),
                is_open: false,
                square_id: id,
                otp
            }
        }, {
            onSuccess: () => {
                setRefresh(+new Date)
                const day = currentSelection.get('D');
                setMonthOpenings({ ...monthOpenings, [day]: false })
            }
        })
    }, [currentSelection])
    const save = useCallback(() => async (otp) => {

        const { opening_days, ...values } = saveData;
        if (!id) {
            return
        }

        const { } = await setDefaultOpeningDays({
            url: '/exchange/square/set_default_opening_days',
            method: 'post',
            values: {
                ...opening_days,
                square_id: id,
                otp
            }
        })
        return mutate({ resource: 'squares', id, values })


    }, [saveData])



    const { mutate: getCustomOpeningday, ...customOpeningDays } = useCustomMutation();
    const { mutate: setCustomOpeningday, ...uteteCustomOpeningDays } = useCustomMutation();
    const [currentSelectionMonth, setCurrentSelectionMonth] = React.useState<Moment | null>(moment());
    const [monthOpenings, setMonthOpenings] = useState({})


    useEffect(() => {
        if (currentSelection) {
            getDefaultOpenStatus(currentSelection);
            getCustomOpeningday({
                method: 'post',
                url: '/exchange/square/get_custom_opening_days',
                values: {
                    square_id: id,
                    day: currentSelection.format('YYYYMMDD')
                }
            })
        }
    }, [currentSelection, uteteCustomOpeningDays.data?.data, refresh])

    useEffect(() => {


        // get the last day of the selected month
        const lastDay = currentSelectionMonth?.endOf('month').daysInMonth()
        if (lastDay && currentSelectionMonth) {

            const fetchData = async () => {
                const _monthOpenings = {}
                for (let i = 1; i <= lastDay; i++) {

                    const result = await customOpeningDays.mutateAsync({
                        method: 'post',

                        url: '/exchange/square/is_open_day',
                        values: {
                            square_id: id,
                            day: currentSelectionMonth.date(i).format('YYYYMMDD')
                        }
                    })
                    _monthOpenings[i] = result.data.result
                }
                setMonthOpenings(_monthOpenings)
            }

            fetchData().catch(err => {
                console.error(err)
            })
        }




    }, [currentSelectionMonth?.month()])


    const getDefaultOpenStatus = (date: Moment) => {
        const weekday = date.isoWeekday() - 1;
        return showData?.data?.data.opening_days[`d${weekday}`] == 1;
    }


    const onFinish = async data => {
        setSaveData(data)
        setCb(save)
        modal.show()
    }



    return <Box component={'form'}>
        <Edit headerButtons={[]} footerButtons={[<EditButton {...saveButtonProps}
            disabled={saveButtonProps.disabled || isLoading || defaultOpeningDays.isLoading}
            onClick={handleSubmit(onFinish)} type='submit'>Save</EditButton>]}>
            <Grid container>
                <Typography style={{ paddingBottom: 40 }} variant='h4'>Default openings</Typography>
            </Grid>
            <Grid container>
                <Grid xs={12}>
                    <Alert color='info'>All times displayed on this page are considered to be in Coordinated Universal Time (UTC) unless otherwise specified. Please be aware that any variations in time zones or daylight saving time adjustments are not accounted for. It is recommended to verify the time zone when necessary.</Alert>
                </Grid>
                <Grid xs={1}>
                    <FormControl>
                        Opening:
                        <Controller name='opening_time'
                            control={control}
                            render={({ field }) => {
                                let error = (errors.emopening_time) ? { error: true, helperText: "required field" } : {}
                                return (
                                    <TextField fullWidth
                                        {...register('opening_time', {
                                            setValueAs: (value) => parseInt(value)
                                        })}
                                        id="opening_time"
                                        inputProps={{
                                            type: 'number',
                                            pattern: '[0-9]+',
                                            min: 0, max: 24, step: 1
                                        }}

                                        variant="outlined"
                                        {...error}
                                        {...field}
                                        onChange={(element) => {
                                            field.onChange(parseInt(element.target.value))
                                        }}
                                    />
                                )
                            }} />
                    </FormControl>
                </Grid>
                <Grid xs={2}>
                    <FormControl>
                        Closing:
                        <Controller name='closing_time'
                            control={control}
                            render={({ field }) => {
                                let error = (errors.closing_time) ? { error: true, helperText: "required field" } : {}
                                return (
                                    <TextField fullWidth
                                        id="closing_time"
                                        {...register('closing_time', {
                                            valueAsNumber: true
                                        })}
                                        inputProps={{
                                            type: 'number',
                                            pattern: '[0-9]+',
                                            min: 1, max: 24, step: 1
                                        }}
                                        variant="outlined"
                                        {...error}
                                        {...field}
                                        onChange={(element) => {
                                            field.onChange(parseInt(element.target.value))
                                        }}
                                    />
                                )
                            }} />
                    </FormControl>
                </Grid>
                {[0, 1, 2, 3, 4, 5, 6].map(el => (<Grid xs={1}>
                    <FormControl>
                        {((day) => {
                            switch (day) {
                                case 0: return 'Monday';
                                case 1: return 'Tueday';
                                case 2: return 'Wednesday';
                                case 3: return 'Thursday';
                                case 4: return 'Friday';
                                case 5: return 'Saturday';
                                case 6: return 'Sunday';
                                default: return '';
                            }
                        })(el)}
                        <Controller name={'opening_days.d' + el}
                            control={control}
                            shouldUnregister

                            render={({ field }) => {
                                // let error = (errors?.opening_days_d0) ? { error: true, helperText: "required field" } : {}
                                return (
                                    <Checkbox
                                        id={"opening_days.d" + el}
                                        {...register('')}
                                        {...field}
                                        checked={field.value === 1}
                                        onChange={(evt) => {
                                            field.onChange(evt.target.checked ? 1 : 0)
                                        }}
                                        name=''

                                    />
                                )
                            }} />
                    </FormControl>

                </Grid>))}
            </Grid>
            <Typography style={{ paddingBottom: 40, paddingTop: 40 }} variant='h4'>Custom openings</Typography>
            <Grid container>
                <Grid xs={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateCalendar value={currentSelection}
                            onMonthChange={(month) => {
                                setMonthOpenings({})
                                setCurrentSelectionMonth(month)
                            }}
                            onChange={(value, _selectionState) => {
                                setCurrentSelection(value);
                            }} />
                    </LocalizationProvider>

                </Grid>
                <Grid xs={8} direction={'column'}>
                    <Grid xs={12} direction={'row'}>
                {currentSelection && <>
                            <Grid direction={'row'} container xs={500}>

                                <Grid xs={2}><strong>Current date:</strong></Grid>
                                <Grid xs={2}>{currentSelection?.format('DD/MM/YYYY')}</Grid>
                                <Grid xs={1}><strong>Open: </strong></Grid>
                                <Grid xs={1}>{customOpeningDays.isLoading ? <CircularProgress size={20} /> : (
                                    isMarketOpenOnThisDay(customOpeningDays, getDefaultOpenStatus, currentSelection) ? 'YES' : 'NO')}</Grid>


                        {!isMarketOpenOnThisDay(customOpeningDays, getDefaultOpenStatus, currentSelection) && <LoadingButton loading={uteteCustomOpeningDays.isLoading || customOpeningDays.isLoading} variant='contained' onClick={() => {
                                    setCb(openOnThisDay);
                                    modal.show()
                        }}>OPEN MARKET ON THIS DAY</LoadingButton>}

                        {isMarketOpenOnThisDay(customOpeningDays, getDefaultOpenStatus, currentSelection) && <LoadingButton loading={uteteCustomOpeningDays.isLoading || customOpeningDays.isLoading} variant='contained' onClick={() => {
                                    setCb(closeOnThisDay);
                                    modal.show()
                        }}>CLOSE MARKET ON THIS DAY</LoadingButton>}

                    </Grid>
                </>}
                    </Grid>
                    <Grid xs={10} direction={'row'}>
                        <Grid xs={4}>

                            <strong>Month Closings:</strong>

                            {(() => {
                                return null;
                            })()}
                        </Grid>
                        <Grid>

                            {Object.keys(monthOpenings).filter(day => !monthOpenings[day]).map(el => currentSelectionMonth?.date(parseInt(el))?.format('DD/MM')).join(', ')}
                            {Object.keys(monthOpenings).length == 0 && <CircularProgress size={10} />}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Edit >
        <OTPModal confirmText='Confirm' message='Confirm' modal={modal} onConfirm={async (otp) => {
            await (cb && cb(otp))
        }} title='' />
    </Box>

}
