import {createTheme} from "@mui/material";
import { LightTheme } from "@refinedev/mui";

declare module '@mui/material/styles' {
    interface Palette {
        tertiary: Palette['primary'];
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
    }

}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        create: true;
        actionDetailPage: true;
        actionTable: true;
    }
}

export const overridedLightTheme = createTheme({
    ...LightTheme,
    palette: {
        ...LightTheme.palette,
        primary: {
            main: "#1f2937",
        },
        secondary: {
            main: "#1f2937",
            contrastText: '#f6f7f9',
        },
        info: {
            main: '#00aded',
            contrastText: '#000000',
        },
        success: {
          main: '#025002',
        },
        tertiary: {
            ...LightTheme.palette.secondary,
            main: '#ffffff',
            contrastText: '#f6f7f9'
        },
        warning: {
          main: '#edb002',
        },
        background: {
            default: '#ebeef2'
        },
    },
    components: {
        ...LightTheme.components,
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            variants: [
                {
                    props: { variant: 'create' },
                    style: {
                        color: '#334155',
                        padding: '6px 26px',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        backgroundColor: '#f6f7f9',
                        '&:hover': {
                            backgroundColor: '#f6f7f9'
                        }
                    },
                },
                {
                    props: { variant: 'actionTable' },
                    style: {
                        color: '#334155',
                        borderRadius: '100px',
                        width: '30px',
                        height: '30px',
                        '&:hover': {
                            color: '#fff',
                            backgroundColor: '#334155'
                        }
                    },
                },
                {
                    props: { variant: 'actionDetailPage' },
                    style: {
                        color: '#334155',
                        padding: '6px 20px',
                        fontSize: '11px',
                        fontWeight: '600',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        backgroundColor: '#f6f7f9',
                        '&:hover': {
                            backgroundColor: '#d7d7d7'
                        },
                        '& .MuiSvgIcon-root': {
                            width: '15px'
                        }
                    },
                },
            ],
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...(ownerState.variant === 'actionDetailPage' && ownerState.color === 'secondary' && {
                        backgroundColor: '#64c5bc !important',
                        color: '#ffffff  !important',
                        '&:hover': {
                            backgroundColor: '#3b9f95  !important'
                        }
                    }),
                    ...(ownerState.variant === 'actionDetailPage' && ownerState.color === 'error' && {
                        backgroundColor: '#c77b7b !important',
                        color: '#ffffff  !important',
                        '&:hover': {
                            backgroundColor: '#c35656  !important'
                        }
                    }),
                    ...(ownerState.variant === 'actionDetailPage' && ownerState.color === 'warning' && {
                        backgroundColor: '#f1cd61 !important',
                        color: '#ffffff  !important',
                        '&:hover': {
                            backgroundColor: '#efb300  !important'
                        }
                    }),
                    ...(ownerState.variant === 'actionDetailPage' && ownerState.color === 'info' && {
                        backgroundColor: '#86bf86 !important',
                        color: '#ffffff  !important',
                        '&:hover': {
                            backgroundColor: '#5f9f5f  !important'
                        }
                    }),
                })
            }
        }, 
        MuiDrawer: {
            styleOverrides: {
              paperAnchorDockedLeft: {
                backgroundColor: "#1f2937",
              },
              paper: {
                '& .MuiTypography-root': {
                    color: "#90a0b7"
                },
                '& .MuiSvgIcon-root': {
                    color: "#90a0b7",
                    width: '16px'
                }
              }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    top: '53px',
                    lineHeight: '1.3'
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginBottom: '50px',
                }
            }
        },
        MuiCardHeader : {
            styleOverrides: {
                action: {
                    margin: 0
                }
            }
        },
        MuiTypography: {
            defaultProps: {
              variantMapping: {
                body2: 'span',
              },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: '#d9dadd'
                }
            }
        },
        // @ts-ignore
        MuiDataGrid: {
            styleOverrides: {
                columnHeaderTitleContainer: {
                    justifyContent: 'center !important',
                },
                columnHeaderTitle: {
                    whiteSpace: 'break-spaces',
                    lineHeight: '1.3em',
                    textTransform: 'uppercase',
                    fontSize: '11px',
                    fontWeight: '700',
                },
                columnHeader: {
                    color: '#000',
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    justifyContent: 'center',
                    '&:focus, &:focus-within': {
                      outline: 'none'
                    }
                },
                row: {
                    margin: '0 !important'
                },
                columnSeparator: {
                    color: 'transparent'
                },
                cell : {
                    fontSize: '15px',
                    justifyContent: 'center',
                    '&:focus, &:focus-within': {
                      outline: 'none'
                    },
                },
                footerContainer: {
                    borderTop: 'none'
                },
            }
        },
    },
    typography: {
        fontFamily: [
            "Nunito",
            "Arial",
            "sans-serif",
        ].join(","),
        h4: {
            fontSize: '20px',
            marginBottom: '10px'
        },
        body2: {
            marginBottom: '15px',
            fontSize: '15px'
        }
    }
})