import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Button} from "@mui/material";
import React, {useState} from "react";
import {useCustomMutation, useNotification} from "@refinedev/core";

export const SearchNewMarsterpieces = ({
                                           refreshCallback = () => {
                                           }
                                       }) => {
    const {open: notification} = useNotification();
    const [disabled, setDisabled] = useState(false)
    const {mutate: discovery} = useCustomMutation()
    // cerco nuove opere da mettere nel
    const handleSearchNewMasterpiece = () => {
        setDisabled(true)
        discovery({
            url: '/tokenizer/discover_masterpieces',
            method: "post",
            values: {}
        }, {
            onSuccess: ({data}) => {
                setDisabled(false)
                const totalResult = data?.result.length
                if (notification) {
                    notification({
                        message: `${totalResult} masterpieces found`,
                        type: 'success',
                    });
                    if (totalResult > 0) {
                        refreshCallback()
                    }
                }
            },
            onError: () => {
                setDisabled(false)
            }

        })
    }
    return (
        <Button className='search-button'
                variant="actionDetailPage"
                disabled={disabled}
                onClick={handleSearchNewMasterpiece}
                endIcon={<RefreshIcon/>}>
            <Box>Search new masterpieces</Box>
        </Button>
    )
}