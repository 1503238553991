import React, {useEffect, useState} from "react";
import {useForm} from "@refinedev/react-hook-form";
import { Controller, useController, useWatch } from "react-hook-form";
import { useCreate, useCustom, useCustomMutation, useList, useModal, useNavigation, useNotification } from "@refinedev/core";
import { Create, NumberField } from "@refinedev/mui";
import { Autocomplete, Box, FormLabel, FormGroup, FormControl, Grid, TextField, Typography, Alert, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableRow, TableCell } from "@mui/material";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";
import {SearchNewMarsterpieces} from "./SearchNewMarsterpieces";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { NumericFormat } from 'react-number-format'
import './create.scss'
import { LoadingButton } from "@mui/lab";

export const AftCreate = () => {
    useEffect(() => {
        document.title = 'Whtexch - New Aft';
    }, []);
    const [masterpieces, setMasterpieces] = useState<string[]>([])
    const [originators, setOriginators] = useState<any>([])
    const [originatorsLabel, setOriginatorsLabel] = useState<any>({})
    const [disableSave, setDisableSave] = useState(false)
    const [totalToken, setTotalToken] = useState(1)
    const [price, setPrice] = useState(0);
    const [multiplier, setMultiplier] = useState(1);
    const [needSearchMasterpieces, setNeedSearchMasterpieces] = useState(true)
    const {
        saveButtonProps,
        refineCore: { formLoading, },
        control,
        setValue,
        formState: {errors},
        getValues,
        setError,
        clearErrors,
        handleSubmit
    } = useForm();

    if (Object.keys(errors)?.length) {
        console.error(errors)
    }
    const {list} = useNavigation()
    const { open } = useNotification()
    const {mutate: start_mint} = useCustomMutation()
    const { mutate: validate, isLoading: validating, data: validationData } = useCustomMutation()
    const {mutate: create} = useCreate();
    const onFinish = (data) => {
        const { order_min_token_quantity_aft, price, premarket_afts_on_sale, premarket_price_for_token_eur,
            premarket_duration_date, premarket_tokens_on_sale_threshold, premarket_price_for_aft,
            multiplier, number_of_afts, order_min_token_quantity_aft_secondary, delete_file,
            add_to_exchange, originator_bcid, ...params } = data
        if (params.premarket_price_for_token != 1000) {
            throw new Error("La base iniziale deve valere 1000.")
        }

        if (!premarket_duration_date) {
            setError('premarket_duration_date', { message: 'Invalid premarket closing date' })
        }


        if ((premarket_duration_date - 1) * 500000 <= number_of_afts) {
            setError('premarket_limit_date', { message: 'The premarket closing date is too close' })
            return;
        }

        const amendedParams = {
            ...params,
            premarket_limit_date: params.premarket_limit_date.replaceAll('-', '').substr(0, 8) + "000000",
            premarket_tokens_on_sale: parseInt(("" + params.premarket_tokens_on_sale).replace(/\,/g, '')),
            // use min value
            order_min_token_increment: parseInt(("" + params.order_min_token_quantity).replace(/\,/g, '')),
            order_min_token_increment_secondary: parseInt(("" + params.order_min_token_quantity_secondary).replace(/\,/g, '')),


            order_min_token_quantity: parseInt(("" + params.order_min_token_quantity).replace(/\,/g, '')),
            order_min_token_quantity_secondary: parseInt(("" + params.order_min_token_quantity_secondary).replace(/\,/g, '')),
            premarket_price_for_token: parseInt(("" + params.premarket_price_for_token).replace(/\,/g, '')),



            originator_name: params.originator_name || '',
            work_of_ingenuity: params.work_of_ingenuity || '',
            author: params.author || '',
            public_act: params.public_act || '',
            public_act_protocol_no: params.public_act_protocol_no || '',
            notary: params.notary || '',
            delete_file: delete_file == '1',
            add_to_exchange: add_to_exchange == '1',
            pieces_for_token: parseInt(pieces_for_token),

            originator_bcid: parseInt(originator_bcid),
            number_of_tokens: parseInt(number_of_tokens),
        }
        console.log(amendedParams)

        setDisableSave(true)
        create({
            resource: "aft",
            values: amendedParams,
            successNotification: false
        }, {
            onSuccess: (data, variables, context) => {
                start_mint({
                    url: `/tokenizer/masterpiece/start_mint`,
                    method: "post",
                    values: {
                        masterpiece_filename: params.masterpiece_filename
                    }
                }, {
                    onSuccess: () => {
                        list('aft')
                    },
                    onError: ({ message }) => {
                        setDisableSave(false)
                        // open?.({ message, type: 'error' })
                    }
                })
            },
            onError: (data, variables, context) => {
                setDisableSave(false)
            },
        })
    }

    const url = "/tokenizer/masterpiece/validate_number_of_pieces"


    const {mutate: searchMasterpieces} = useCustomMutation()
    const {data: dataUsers, isSuccess: isSuccessOriginator} = useList({
        resource: "users",
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions
        }
    });

    useEffect(() => {
        if (needSearchMasterpieces) {
            const _masterpieces = []
            searchMasterpieces({
                url: `/tokenizer/search_masterpieces`,
                method: "post",
                values: {}
            }, {
                onSuccess: ({data}) => {
                    setNeedSearchMasterpieces(false)
                    data?.result?.forEach((item) => {
                        // escludi le opere che sono correntemente usate
                        if (!item.is_started_tokenizer) {
                            // @ts-ignore
                            _masterpieces.push(item?.masterpiece_filename)
                        }
                    })
                }
            })

            setMasterpieces(_masterpieces)
        }
    }, [needSearchMasterpieces])
    useEffect(() => {
        if (isSuccessOriginator) {
            const _originators = []
            const _originatorsLabel = {}
            dataUsers?.data.forEach((item) => {
                if (item?.hlf && item?.hlf.length > 0) {
                    // TODO cambiare la gestione quando saranno supportati wallet multipli
                    let bcidData = item?.hlf[0]
                    // @ts-ignore
                    _originators.push(bcidData.bcid)
                    // @ts-ignore
                    _originatorsLabel[bcidData.bcid] = 'Company: ' + (item.profile_data.company_name ?? 'n.a.') + " (" + (item.profile_data.lastname + " " + item.profile_data.firstname) + ")";
                }
            })
            setOriginators(_originators)
            setOriginatorsLabel(_originatorsLabel)
        }
    }, [isSuccessOriginator])

    const VALUE = 0.01

    const premarket_price_for_aft = useWatch({ control, name: "premarket_price_for_aft", });
    const pieces_for_token = useWatch({ control, name: "pieces_for_token", });
    const premarket_limit_date = useWatch({ control, name: 'premarket_limit_date' });
    const premarket_tokens_on_sale = useWatch({ control, name: 'premarket_tokens_on_sale' });
    const number_of_tokens = useWatch({ control, name: 'number_of_tokens' });
    const order_min_token_quantity_aft = useWatch({ control, name: 'order_min_token_quantity_aft' });
    const order_min_token_quantity_aft_secondary = useWatch({ control, name: 'order_min_token_quantity_aft_secondary' });
    const masterpiece_filename = useWatch({ control, name: 'masterpiece_filename' })

    useEffect(() => {
        if (number_of_tokens && order_min_token_quantity_aft) {
            if (number_of_tokens % (order_min_token_quantity_aft / pieces_for_token) == 0 && (order_min_token_quantity_aft % pieces_for_token) == 0) {
                clearErrors('order_min_token_quantity_aft')
            } else {
                setError('order_min_token_quantity_aft', { message: 'Needs to be a divisor of the total number of units' })
            }
        }
    }, [order_min_token_quantity_aft, number_of_tokens])
    useEffect(() => {
        if (number_of_tokens && order_min_token_quantity_aft_secondary) {
            if (number_of_tokens % (order_min_token_quantity_aft_secondary / pieces_for_token) == 0 && (order_min_token_quantity_aft_secondary % pieces_for_token) == 0) {
                clearErrors('order_min_token_quantity_aft_secondary')
            } else {
                setError('order_min_token_quantity_aft_secondary', { message: 'Needs to be a divisor of the total number of units' })
            }
        }
    }, [order_min_token_quantity_aft_secondary, number_of_tokens]);

    useEffect(() => {
        if (price && multiplier) {
            setValue('number_of_afts', price * multiplier)
            if (pieces_for_token) {
                setValue('number_of_tokens', price * multiplier / pieces_for_token);
                setTotalToken(price * multiplier / pieces_for_token);
            }
            setValue('premarket_price_for_aft', 1 / multiplier)
        }
    }, [price, multiplier, pieces_for_token]);

    useEffect(() => {
        if (premarket_price_for_aft && pieces_for_token) {
            setValue('premarket_price_for_token', premarket_price_for_aft * pieces_for_token / VALUE)
            setValue('premarket_price_for_token_eur', premarket_price_for_aft * pieces_for_token)
        }
    }, [premarket_price_for_aft, pieces_for_token]);


    useEffect(() => {
        if (order_min_token_quantity_aft && pieces_for_token) {
            setValue('order_min_token_quantity', order_min_token_quantity_aft / pieces_for_token)
        }
    }, [order_min_token_quantity_aft, pieces_for_token]);


    useEffect(() => {
        if (order_min_token_quantity_aft_secondary && pieces_for_token) {
            setValue('order_min_token_quantity_secondary', order_min_token_quantity_aft_secondary / pieces_for_token)
        }
    }, [order_min_token_quantity_aft_secondary, pieces_for_token]);

    useEffect(() => {
        if (premarket_limit_date) {

            const temp_date = new Date(premarket_limit_date)
            const diffDays = ((temp_date.getTime() - (new Date).getTime()) / 1000 / 86400) >> 0
            if (diffDays > 0) {
                setValue('premarket_duration_date', diffDays)
            } else {
                setValue('premarket_duration_date', '')
            }
        }
    }, [premarket_limit_date]);

    useEffect(() => {
        if (premarket_tokens_on_sale && pieces_for_token) {
            setValue('premarket_afts_on_sale', (parseInt(premarket_tokens_on_sale.replaceAll(',', ''))) * pieces_for_token)
        }
    }, [premarket_tokens_on_sale, pieces_for_token]);
    const { show, close, visible } = useModal()

    useEffect(() => {
        if (validationData?.data?.suggestions?.length >= 1 && validationData?.data.suggestions[0] !== price * multiplier && !visible) {
            show()
        }
    }, [validationData?.data?.suggestions])
    const getValidationColor = () => {
        if (validationData?.data.suggestions.length == 1 && validationData?.data.suggestions[0] == price * multiplier) {
            return 'success';
        }
        if (validationData?.data.suggestions.length >= 1) {
            return 'warning';
        }
        if (validationData?.data.suggestions.length == 0) {
            return 'error';
        }

        return undefined
    }

    return (
        
        <React.Fragment>
            <Dialog open={visible} onClose={close}>
                <DialogTitle>Suggestions</DialogTitle>
                <DialogContent>
                    The selected image cannot be minted using this value, try one of the following:

                    <Table>
                        <TableBody>
                            {validationData?.data.suggestions.map((el: number) => {
                                const newMultiplier = el / price
                                return <TableRow>
                                <TableCell align="center">{
                                    <Button style={{ textDecoration: 'underline' }} variant="text" onClick={() => {
                                            setMultiplier(newMultiplier)
                                            setValue('multiplier', newMultiplier)

                                            setValue('pieces_for_token', newMultiplier * 10)
                                        close()
                                        }} >{`Afts: ${(el).toLocaleString('en-US')} multiplier: ${newMultiplier}`}</Button>
                                    }</TableCell></TableRow>
                            })}
                        </TableBody>
                    </Table>

                </DialogContent>
                <DialogActions>
                    <Button onClick={close} >Close</Button>
                </DialogActions>

            </Dialog>
            <Box sx={{displax: 'flex'}} className="page-title-container">
                <Box>
                    <Typography variant="h1">AFT</Typography>
                    <Typography variant="h2">Create</Typography>
                </Box>
            </Box>

            <Create title={<Typography variant="h5">Back</Typography>} 
                    breadcrumb=""
                    isLoading={formLoading}
                    saveButtonProps={{...saveButtonProps, onClick: handleSubmit(onFinish), disabled: disableSave}}
                    headerButtons={<SearchNewMarsterpieces refreshCallback={() => {
                        setNeedSearchMasterpieces(true)
                    }}/>}
                    footerButtons={({defaultButtons}) => {
                        return (
                            <>
                                {disableSave && <HourglassBottomIcon className={'loop-rotate-slow'}/>}
                                {defaultButtons}
                            </>
                        )
                    }}
            >
                <Box>
                    <Box component="form" sx={{display: "flex", flexDirection: "column"}} autoComplete="off">
                        <FormControl>
                            <FormGroup>
                                <FormLabel component="legend">Platform Data</FormLabel>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="paltform manager"
                                            label="Platform manager"
                                            value={"WHTEXCH SPA"}
                                            InputLabelProps={{ shrink: true }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="value_of_coin"
                                            label="WHTEX (euro countervalue)"
                                            value={0.01}
                                            InputLabelProps={{ shrink: true }}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel component="legend">Metadata</FormLabel>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="originator_name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    fullWidth
                                                    id="originator_name"
                                                    label="Originator"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.originator_name}
                                                    helperText={(errors as any)?.originator_name?.message}
                                                    disabled={disableSave}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="work_of_ingenuity"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    fullWidth
                                                    id="work_of_ingenuity"
                                                    label="Original Masterpiece"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.work_of_ingenuity}
                                                    helperText={(errors as any)?.work_of_ingenuity?.message}
                                                    disabled={disableSave}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="author"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    fullWidth
                                                    id="author"
                                                    label="Author"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.author}
                                                    helperText={(errors as any)?.author?.message}
                                                    disabled={disableSave}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="public_act"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    fullWidth
                                                    id="public_act"
                                                    label="Public Act"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.public_act}
                                                    helperText={(errors as any)?.public_act?.message}
                                                    disabled={disableSave}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="public_act_protocol_no"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    fullWidth
                                                    id="public_act_protocol_no"
                                                    label="Public Act Protocol Number"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.public_act_protocol_no}
                                                    helperText={(errors as any)?.public_act_protocol_no?.message}
                                                    disabled={disableSave}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="notary"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    fullWidth
                                                    id="notary"
                                                    label="Notary"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.notary}
                                                    helperText={(errors as any)?.notary?.message}
                                                    disabled={disableSave}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            
                            <FormGroup>
                                <FormLabel component="legend">Tokenization Data</FormLabel>
                                
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="masterpiece_filename"
                                            control={control}
                                            rules={{required: {value: true, message: "required field"}}}
                                            render={({field}) => (
                                                <Autocomplete
                                                    {...field}
                                                    options={masterpieces}
                                                    onChange={(_, value) => {
                                                        field.onChange(value);
                                                    }}
                                                    loading={needSearchMasterpieces}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Masterpiece"
                                                            margin="normal"
                                                            InputLabelProps={{shrink: true}}
                                                            error={!!(errors as any)?.masterpiece_filename}
                                                            helperText={(errors as any)?.masterpiece_filename?.message}
                                                            required
                                                            disabled={disableSave}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="aftcontract_id"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                pattern: { value: /^[a-z0-9]*$/, message: 'May only contain lowecase letters an numbers. No space, no symbols, no uppercase' },
                                                maxLength: { value: 20, message: 'Max Length 20' }
                                            }}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    fullWidth
                                                    id="aftcontract_id"
                                                    label="AFT Display Name"
                                                    InputLabelProps={{shrink: true}}
                                                    error={!!(errors as any)?.aftcontract_id}
                                                    helperText={(errors as any)?.aftcontract_id?.message}
                                                    required
                                                    disabled={disableSave}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="originator_bcid"
                                            control={control}
                                            rules={{required: {value: true, message: "required field"}}}
                                            render={({field}) => (
                                                <Autocomplete
                                                    {...field}
                                                    options={originators}
                                                    onChange={(_, value) => {
                                                        field.onChange(value);
                                                    }}
                                                    getOptionLabel={(option) => {
                                                        return originatorsLabel[option] ?? "";
                                                    }}
                                                    loading={!isSuccessOriginator}
                                                    renderInput={(params) => {
                                                        return <TextField
                                                            {...params}
                                                            label="Originator"
                                                            margin="normal"
                                                            InputLabelProps={{shrink: true}}
                                                            error={!!(errors as any)?.originator_bcid}
                                                            helperText={(errors as any)?.originator_bcid?.message}
                                                            required
                                                            disabled={disableSave}
                                                        />
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>


                                    <Grid item xs={4}>
                                        <Controller
                                            name="price"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 1, message: "Price > 0" }
                                            }}
                                            render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    customInput={TextField}
                                                    margin="normal"
                                                    required
                                                    disabled={disableSave}
                                                    fullWidth
                                                    value={field.value}
                                                    id="price"
                                                    thousandSeparator
                                                    label="Initial Price"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.price}
                                                    helperText={(errors as any)?.price?.message}
                                                    onValueChange={({ value, floatValue, formattedValue }) => {
                                                        if (floatValue && floatValue >= 0) {
                                                            setPrice(floatValue)
                                                        }
                                                    }}
                                                    onChange={(element) => {
                                                        field.onChange(element.target.valueAsNumber)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Controller
                                            name="multiplier"
                                            control={control}
                                            defaultValue={"1"}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 0, message: "Multiplier > 0" }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    defaultValue={1}
                                                    disabled={disableSave}
                                                    fullWidth
                                                    id="multiplier"
                                                    label="Multiplier"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.multiplier}
                                                    helperText={(errors as any)?.multiplier?.message}
                                                    onChange={(element) => {
                                                        if (element.target.value === '') {
                                                            field.onChange('')
                                                        }
                                                        if (!Number.isNaN(parseFloat(element.target.value))) {
                                                            field.onChange(parseFloat(element.target.value))
                                                        }
                                                        if (element?.target?.value && parseFloat(element.target.value) >= 0) {
                                                            setMultiplier(parseFloat(element.target.value))
                                                        }
                                                    }}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        step: 0.1,
                                                        pattern: '[0-9\.\,]*',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>


                                    <Grid item xs={4}>
                                        <Controller
                                            name="number_of_afts"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 1, message: "minimum 1 unit" },
                                                max: { value: 2e7, message: "Maximum number of AFTs is 20.000.000" }
                                            }}
                                            render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    thousandSeparator
                                                    customInput={TextField}
                                                    margin="normal"
                                                    required
                                                    value={field.value}
                                                    fullWidth
                                                    id="number_of_afts"
                                                    label="Total number of AFTs "
                                                    InputProps={{
                                                        disabled: true,
                                                        endAdornment: <LoadingButton
                                                            color={getValidationColor()}
                                                            loading={validating} disabled={!(price * multiplier) || !masterpiece_filename} onClick={() => {
                                                                validate({
                                                                    url,
                                                                    method: 'post',
                                                                    values: {
                                                                        masterpiece_filename,
                                                                        number_of_pieces: price * multiplier,
                                                                        price
                                                                    }
                                                                })
                                                            }}>Validate</LoadingButton>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.number_of_afts}
                                                    helperText={(errors as any)?.number_of_afts?.message}
                                                    onChange={(element) => {
                                                        // field.onChange(parseInt(element.target.value))
                                                    }}

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="pieces_for_token"
                                            control={control}
                                            rules={{
                                                required: {value: true, message: "required field"},
                                                min: {value: 1, message: "minimum 1 piece"}
                                            }}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled={disableSave}
                                                    fullWidth
                                                    id="pieces_for_token"
                                                    label="Unit Size (N AFTs per Units)"
                                                    InputLabelProps={{shrink: true}}
                                                    error={!!(errors as any)?.pieces_for_token}
                                                    helperText={(errors as any)?.pieces_for_token?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 1
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Controller
                                            name="premarket_price_for_aft"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled
                                                    fullWidth
                                                    id="premarket_price_for_aft"
                                                    label="AFT Initial Countervalue (EUR)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.premarket_price_for_aft}
                                                    helperText={(errors as any)?.premarket_price_for_aft?.message}
                                                    type={"number"}
                                                    onChange={(element) => {
                                                        field.onChange(parseFloat(element.target.value))
                                                    }}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="number_of_tokens"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 1, message: "minimum 1 unit" },
                                                validate: (value) => {
                                                    if (parseInt(value) !== parseFloat(value)) {
                                                        return "Must be integer"
                                                    }

                                                    return true;
                                                }
                                            }}
                                            render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    thousandSeparator
                                                    customInput={TextField}
                                                    margin="normal"
                                                    required
                                                    disabled
                                                    value={field.value}
                                                    fullWidth
                                                    id="number_of_tokens"
                                                    label="Total number of Units"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.number_of_tokens}
                                                    helperText={(errors as any)?.number_of_tokens?.message}

                                                    onChange={(element) => {
                                                        field.onChange(parseInt(element.target.value))
                                                    }}

                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Controller
                                            name="premarket_price_for_token"
                                            control={control}
                                            rules={{
                                                required: {value: true, message: "required field"},
                                                min: { value: 1000, message: "La base deve valere 1000" },
                                                max: { value: 1000, message: "La base deve valere 1000" }
                                            }}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled
                                                    fullWidth
                                                    id="premarket_price_for_token"
                                                    label="Unit Price in Pre-Market (WHTEXs)"
                                                    InputLabelProps={{shrink: true}}
                                                    error={!!(errors as any)?.premarket_price_for_token}
                                                    helperText={(errors as any)?.premarket_price_for_token?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 1
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>

                                        <Controller
                                            name="premarket_price_for_token_eur"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled
                                                    fullWidth
                                                    id="premarket_price_for_token_eur"
                                                    label="Unit Price in Pre-Market (EUR)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.premarket_price_for_token_eur}
                                                    helperText={(errors as any)?.premarket_price_for_token_eur?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 1
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Controller
                                            name="premarket_limit_date"
                                            control={control}
                                            rules={{
                                                required: {value: true, message: "required field"},
                                                min: {value: 1, message: "minimum 1 day"}
                                            }}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    itemType="date"
                                                    disabled={disableSave}
                                                    fullWidth
                                                    id="premarket_limit_date"
                                                    label="Pre-Market (deadline)"
                                                    InputLabelProps={{shrink: true}}
                                                    error={!!(errors as any)?.premarket_limit_date}
                                                    helperText={(errors as any)?.premarket_limit_date?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'date',
                                                        pattern: '[0-9]+',
                                                        min: 1
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="premarket_duration_date"
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 1, message: "minimum 1 day" }
                                            }}
                                            control={control}
                                            render={({ field }) => (

                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    itemType="date"
                                                    disabled={true}
                                                    fullWidth
                                                    id="premarket_duration_date"
                                                    label="Pre-Market (duration)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.premarket_limit_date || !!(errors as any)?.premarket_duration_date}
                                                    helperText={(errors as any)?.premarket_limit_date?.message || (errors as any)?.premarket_duration_date?.message}
                                                    type={"number"}

                                                />
                                            )} />
                                    </Grid>



                                    <Grid item xs={4}>
                                        <Controller
                                            name="premarket_tokens_on_sale"
                                            control={control}
                                            rules={{
                                                required: {value: true, message: "required field"},
                                                min: {value: 0, message: "only positive value"},
                                                max: {
                                                    value: totalToken,
                                                    message: `must be less than or equal to the number of units (${totalToken})`
                                                }
                                            }}
                                            render={({field}) => (
                                                <NumericFormat
                                                    customInput={TextField}
                                                    {...field}
                                                    thousandSeparator
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    value={field.value}
                                                    id="premarket_tokens_on_sale"
                                                    label="Minimun Threshold (Units)"
                                                    InputLabelProps={{shrink: true}}
                                                    error={!!(errors as any)?.premarket_tokens_on_sale}
                                                    helperText={(errors as any)?.premarket_tokens_on_sale?.message}

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="premarket_afts_on_sale"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 0, message: "only positive value" },
                                                max: {
                                                    value: totalToken * pieces_for_token,
                                                    message: `must be less than or equal to the number of units (${totalToken})`
                                                }
                                            }}
                                            render={({ field }) => (
                                                <NumericFormat
                                                    customInput={TextField}
                                                    {...field}
                                                    thousandSeparator
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    disabled
                                                    value={field.value}
                                                    id="premarket_afts_on_sale"
                                                    label="Minimun Threshold (AFTs)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.premarket_afts_on_sale}
                                                    helperText={(errors as any)?.premarket_afts_on_sale?.message}

                                                />
                                            )}
                                        />
                                    </Grid>   
                                    {/* <Grid item xs={2}>
                                        <Controller
                                            name="order_min_token_increment_secondary"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 0, message: "only positive value" }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled={disableSave}
                                                    fullWidth
                                                    id="order_min_token_increment_secondary"
                                                    label="Minimum Increment (Units) (Secondary market)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.order_min_token_increment_secondary}
                                                    helperText={(errors as any)?.order_min_token_increment_secondary?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid> */}
                                </Grid>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel component="legend">Minimun Lot Definition</FormLabel>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="order_min_token_quantity_aft"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 0, message: "only positive value" }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled={disableSave}
                                                    fullWidth
                                                    id="order_min_token_quantity_aft"
                                                    label="Pre-Market Minimum Lot (AFTs)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.order_min_token_quantity_aft}
                                                    helperText={(errors as any)?.order_min_token_quantity_aft?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="order_min_token_quantity"
                                            control={control}
                                            rules={{
                                                required: {value: true, message: "required field"},
                                                min: { value: 1, message: "only >= 1" }
                                            }}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled
                                                    fullWidth
                                                    id="order_min_token_quantity"
                                                    label="Pre-market Minimum Lot (Units)"
                                                    InputLabelProps={{shrink: true}}
                                                    error={!!(errors as any)?.order_min_token_quantity}
                                                    helperText={(errors as any)?.order_min_token_quantity?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={2}>
                                        <Controller
                                            name="order_min_token_increment"
                                            control={control}
                                            rules={{
                                                required: {value: true, message: "required field"},
                                                min: {value: 0, message: "only positive value"}
                                            }}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled={disableSave}
                                                    fullWidth
                                                    id="order_min_token_increment"
                                                    label="Minimum Increment (units)"
                                                    InputLabelProps={{shrink: true}}
                                                    error={!!(errors as any)?.order_min_token_increment}
                                                    helperText={(errors as any)?.order_min_token_increment?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <Controller
                                            name="order_min_token_quantity_aft_secondary"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 0, message: "only positive value" }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled={disableSave}
                                                    fullWidth
                                                    id="order_min_token_quantity_aft_secondary"
                                                    label="Market Minimum Lot (AFTs)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.order_min_token_quantity_aft_secondary}
                                                    helperText={(errors as any)?.order_min_token_quantity_aft_secondary?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="order_min_token_quantity_secondary"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "required field" },
                                                min: { value: 1, message: "only >= 1" }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    margin="normal"
                                                    required
                                                    disabled
                                                    fullWidth
                                                    id="order_min_token_quantity_secondary"
                                                    label="Market Minimum Lot (Units)"
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!(errors as any)?.order_min_token_quantity_secondary}
                                                    helperText={(errors as any)?.order_min_token_quantity_secondary?.message}
                                                    type={"number"}
                                                    inputProps={{
                                                        type: 'number',
                                                        pattern: '[0-9]+',
                                                        min: 0
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel component="legend">Additional Data</FormLabel>
                                
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="add_to_exchange"
                                            control={control}
                                            rules={{required: {value: true, message: "required field"}}}
                                            defaultValue={1}
                                            render={({field}) => (
                                                <Autocomplete
                                                    {...field}
                                                    options={[0, 1]}
                                                    onChange={(_, value) => {
                                                        field.onChange(value);
                                                    }}
                                                    getOptionLabel={(option) => (option) ? "yes" : "no"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Add to Exchange"
                                                            margin="normal"
                                                            required
                                                            disabled={disableSave}
                                                            InputLabelProps={{shrink: true}}
                                                            error={!!(errors as any)?.add_to_exchange}
                                                            helperText={(errors as any)?.add_to_exchange?.message}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="delete_file"
                                            control={control}
                                            rules={{required: {value: true, message: "required field"}}}
                                            defaultValue={1}
                                            render={({field}) => (
                                                <Autocomplete
                                                    {...field}
                                                    options={[0, 1]}
                                                    onChange={(_, value) => {
                                                        field.onChange(value);
                                                    }}
                                                    getOptionLabel={(option) => (option) ? "yes" : "no"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Delete File"
                                                            margin="normal"
                                                            required
                                                            disabled={disableSave}
                                                            InputLabelProps={{shrink: true}}
                                                            error={!!(errors as any)?.delete_file}
                                                            helperText={(errors as any)?.delete_file?.message}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Box>
            </Create>
        </React.Fragment>
    );
};