import React, {useEffect, useState} from "react";
import { useGetIdentity, useNavigation, useNotification } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";

import {
    Box,
    Button,
    FormControl,
    Grid,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";


import {RequestProvider} from "shared-libs/src/providers";

/**
 *
 * @param user
 * @constructor
 */
export const ResetPasswordForm = ({user}) => {
    const {show} = useNavigation()
    const {open} = useNotification();
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [qrImageUrl, setQrImageUrl] = useState("")
    const [qrCode, setQrCode] = useState("")
    const qrImageDimension = 200
    const dataToSend = {email: '', authentication_code: '', password: '', otp_secret: '', otp_now: ''}
    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues,
        setError,
        setValue
    } = useForm();
    const catchFunction = (response) => {
        if (open) {
            setButtonDisabled(false)
            open({
                message: response?.data?.message ?? response?.error ?? response?.message ?? 'An error has occurred. Please, contact our customer care',
                type: "error"
            });
        }
    }
    useEffect(() => {
        if (user?.email) {
            RequestProvider.requestForChangeSignin(user.email).then((response) => {
                // console.log(response)
                if (response?.data?.result) {
                    dataToSend.email = user?.email
                    RequestProvider.getOTP(dataToSend.email)
                        .then((response) => {
                            //console.log(response.data)
                            setQrImageUrl("data:image/png;base64, " + response.data.qrcode)
                            setQrCode(response.data.code)
                        }).catch(error => {
                    })
                } else {
                    catchFunction(response)
                }
            }).catch(response => catchFunction(response))
        }
    }, [user?.email])
    useEffect(() => {
        setValue('email', user?.email)
    }, [user?.email])
    const onFinish = () => {
        setButtonDisabled(true)
        const values = getValues()
        // console.log(values)
        RequestProvider.changeSignin(values.email, values.password, values.otp_now, qrCode, values.authentication_code).then((response) => {
            if (response?.data?.user?.user_id) {
                if (open) {
                    open({
                        message: 'password changed!',
                        type: "success"
                    });
                    setTimeout(() => {
                        show('customers', '')
                    }, 5000)
                }
            } else {
                catchFunction(response)
            }
        }).catch(response => catchFunction(response))
    }
    return (
        <Box component={"form"} onSubmit={(e) => {

            let hasErrors
            const values = getValues();

            if (values.password != values.confirm_password) {
                setError('confirm_password', { message: 'Confirm password is not equal to Password' });
                hasErrors = true;
            }
            if (!hasErrors) {
                handleSubmit(onFinish)(e).catch(catchFunction);
            }
        }}>
            <FormControl className='reset-login-form'>
                <Controller
                    name="email"
                    control={control}
                    defaultValue={user?.email}
                    rules={{required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/}}
                    render={({field}) => {
                        let error = (errors.email) ? {error: true, helperText: "required field"} : {}
                        return (
                            <TextField fullWidth
                                       id="login-email"
                                       label="Email"
                                       variant="outlined"
                                       disabled={true}
                                       {...error}
                                       {...field}
                            />
                        )
                    }}
                />

                <>
                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: true,
                            pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                        }}
                        render={({field}) => {
                            let error = (errors.password) ? {
                                error: true,
                                helperText: 'field must have at least two uppercase letters, one special case letter, two digits, three lowercase letter and at least a length of 8'
                            } : {}
                            return (
                                <TextField fullWidth
                                           id="register-password"
                                           label="Password"
                                           type="password"
                                           variant="outlined"
                                           className='form-password-input'
                                           disabled={buttonDisabled}
                                           {...error}
                                           {...field}
                                />
                            )
                        }}
                    />
                    <Controller
                        name="confirm-password"
                        control={control}
                        rules={{
                            required: true,
                            pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                        }}
                        render={({ field }) => {
                            let error = (errors.confirm_password) ? {
                                error: true,
                                helperText: errors.confirm_password?.message ? errors.confirm_password?.message.toString() : 'field must have at least two uppercase letters, one special case letter, two digits, three lowercase letter and at least a length of 8'
                            } : {}
                            return (
                                <TextField fullWidth
                                    id="register-confirm-password"
                                    label="confirm-password"
                                    type="password"
                                    variant="outlined"
                                    className='form-confirm-password-input'
                                    disabled={buttonDisabled}
                                    {...error}
                                    {...field}
                                />
                            )
                        }}
                    />
                    <Typography marginBottom={'40px'}>To complete the registration it is necessary to activate
                        two-factor
                        authentication via One Time Password.<br/>Use the qr code or enter the code in the
                        Google Authenticator application.<br/>Once activated, enter the OTP code to complete the
                        registration.</Typography>
                    <Grid container spacing={5} textAlign={'center'}>
                        <Grid item xs={12}>
                            {qrCode ? <Typography>Code: {qrCode}</Typography> :
                                <Skeleton variant={"rectangular"}/>}
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="otp-qr-placeholder">
                                {qrImageUrl ?
                                    <img src={qrImageUrl} height={qrImageDimension} alt={"QR Code"}/> :
                                    <Skeleton height={qrImageDimension} width={qrImageDimension}
                                              variant={"rectangular"}/>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Controller
                        name="otp_now"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            let error = (errors.otp_now) ? {error: true, helperText: "required field"} : {}
                            return (
                                <TextField fullWidth
                                           id="register-otp_now"
                                           label="Insert OTP"
                                           variant="outlined"
                                           disabled={buttonDisabled}
                                           {...error}
                                           {...field}
                                />
                            )
                        }}
                    />
                    <Typography marginBottom={'40px'}>Insert the authentication code received by
                        email</Typography>
                    <Controller
                        name="authentication_code"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            let error = (errors.authentication_code) ? {
                                error: true,
                                helperText: "required field"
                            } : {}
                            return (
                                <TextField fullWidth
                                           id="login-authentication_code"
                                           label="Authentication Code"
                                           variant="outlined"
                                           disabled={buttonDisabled}
                                           {...error}
                                           {...field}
                                />
                            )
                        }}
                    />
                </>
                <FormControl>
                    <Button color='secondary' type="submit" disabled={buttonDisabled}>Change</Button>
                </FormControl>
            </FormControl>
        </Box>
    );
}