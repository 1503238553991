import { DateField, NumberField, useDataGrid } from "@refinedev/mui";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { IAft, IAftLite, ITradingAudit } from "shared-libs/src/interfaces";
import React, { useState } from "react";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";
import { OrderMatchesDialog } from "components/OrderMatchesDialog";
import { useCurrencyStore } from "hooks/useCurrency";
import { useOne } from "@refinedev/core";
import { useTranslation } from "react-i18next";


export const UserAction = ({ user_id, hlf }) => {

    const { getValue, init, currency } = useCurrencyStore()
    const [bcid, setBcid] = useState(hlf[0]?.bcid)

    let { dataGridProps, tableQueryResult: { isLoading, isRefetching } } = useDataGrid<IAft>({
        resource: "trading_audit",
        syncWithLocation: false,
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.queryOptions,
            queryKeyHashFn: () => `${bcid} trading_audit`
        },
        permanentFilter: [
            {
                field: "bcid",
                operator: "eq",
                value: bcid,
            }
        ],
    });

    const balanceWht = useOne({
        resource: "balance_wht",
        id: "",
        meta: {
            filters: [
                { field: 'bcid', operator: 'eq', value: bcid },
                { field: 'user_id', operator: 'eq', value: user_id },
            ]
        }
    })

    const balanceAft = useOne({
        resource: "balance_aft",
        id: "",
        meta: {
            filters: [
                { field: 'bcid', operator: 'eq', value: bcid },
                { field: 'user_id', operator: 'eq', value: user_id },
            ]
        }
    })
    if (balanceAft?.data?.data && balanceWht?.data?.data) {
        console.log("balance", balanceAft?.data?.data, balanceWht?.data?.data)
    }






    const columns = React.useMemo<GridColumns<ITradingAudit & { closed_reason: string }>>(
        () => [
            {
                field: "id",
                headerName: ("ID"),
                headerAlign: "center",
                align: 'center',
                type: 'int',
                flex: 1,
            },
            {
                field: "timestamp",
                headerName: ("DATE & TIME (UTC)"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                flex: 2,
                valueFormatter: ({value}) => {
                    return value && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8)
                },
                renderCell: ({ value }) => value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"} /> : ""
            },
            {
                field: "aftcontract_id",
                headerName: ("Masterpiece Id"),
                headerAlign: "center",
                align: 'center',
                minWidth: 160,
                flex: 1
            },
            {
                field: "market",
                headerName: ("market"),
                headerAlign: "center",
                align: 'center',
                minWidth: 50,
                flex: 1.25,
            },
            {
                field: "order.type",
                headerName: ("order type"),
                headerAlign: "center",
                align: 'center',
                minWidth: 50,
                flex: 1,
                valueGetter: (params) => {
                    return params.row.order.type
                }
            },
            {
                field: "entry_type",
                headerName: ("order status"),
                headerAlign: "center",
                align: "center",
                flex: 2,
                // valueGetter: ({ row: { order_status, closed_reason, not_executed, order_size } }) => {
                //     switch (order_status) {
                //         case 'o': return order_size != not_executed ? "Partially Executed" : "Open";
                //         case 'c': return closed_reason === 'retired' ? 'Cancelled' : "Closed";
                //         case 'a': return "Archived";
                //     }
                // }
            },
            {
                field: "order.init_quantity",
                headerName: ("order size"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                valueGetter: (params) => {
                    return params.row.order.init_quantity
                },
                flex: 1,
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "quantity",
                headerName: ("executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : "-"
                }
            },
            {
                field: "not_executed",
                headerName: ("not executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: (params) => {
                    return params.row.order.quantity
                },
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : "-"
                }
            },
            {
                field: "avg_price",
                headerName: ("Average Purchase Price"),
                headerAlign: "center",
                align: 'center',
                type: 'int',
                flex: 1.25,
                valueGetter: (params) => {
                    return getValue(params.row.order.avg_price)
                }
            },
            {
                field: "price_for_unit",
                headerName: ("limit price"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row }) => {
                    return getValue(row.order.price_for_unit)
                },
                renderCell: function render({ value }) {
                    return value
                }
            },
            {
                field: "expiration_date",
                headerName: ("validity period"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                minWidth: 50,
                flex: 2,
                valueFormatter: ({ value }) => {
                    return value && value != 'GTC' && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8) + " " + [value.substring(8, 10), value.substring(10, 12), value.substring(12, 14),].join(':')
                },
                valueGetter: ({ row: { order: { expiration_date } } }) => {
                    return expiration_date?.length ? expiration_date : "GTC"
                },
                renderCell: ({ value }) => value === 'GTC' ? 'GTC' : (value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"} /> : "")
            },
            // {
            //     field: "last_price",
            //     headerName: ("Execution price"),
            //     headerAlign: "center",
            //     align: 'center',
            //     type: 'number',
            //     minWidth: 50,
            //     flex: 1,
            //     renderCell: function render({ value }) {
            //         return value ? <NumberField value={value} /> : ""
            //     }
            // },
        ], [currency]
    );
    const [detail, setDetail] = useState<IAftLite>();
    const { t } = useTranslation()

    const dataToFields = (data, fields) => {
        return fields.reduce((acc, curr) => {
            return { ...acc, [curr]: mapFieldToValue(data, curr) }
        }, {});
    }
    const mapFieldToValue = (data: { available, committed, fees_due }, name) => {
        switch (name) {
            case 'available':
                return data.available;
            case 'committed':
                return data.committed;
            case 'owned':
                return data.available + data.committed;
            default:
                return data[name]

        }
    }

    const fields = ["owned", "committed", "available"]



    const bcidSelect = <FormControl style={{ float: 'right', width: '100px' }}>
        <InputLabel id="demo-simple-select-label">BCID</InputLabel>
        <Select label="BCID" value={bcid} onChange={({ target: { value } }) => setBcid(value)}>
            {hlf.map(({ bcid }) => <MenuItem style={{ textAlign: 'right' }} value={bcid}>{bcid}</MenuItem>)}
        </Select></FormControl>
    const fontSize = 'tot-whtx-12'
    return (
        <Card sx={{mb: '30px'}}>
            {init}
            <CardHeader className="panel-header" title={<>Trading History {bcidSelect}</>} />
            <CardContent>
                <Box className={""} sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                    <Box className="tot-whtx-12" sx={{ display: 'flex', flex: 1, justifyContent: 'space-evenly', gap: '20px' }}>
                        {Object.entries(dataToFields(balanceWht?.data?.data ?? {}, fields)).map((value: [any, any]) => {
                            return (
                                (fields.includes('all') || (fields.includes(value[0]))) &&
                                <Box sx={{ textAlign: 'center' }} key={value[0]}>
                                    <Tooltip className={`column-label ${fontSize}`} sx={{ pb: 1 }}
                                        title={t('whtex.' + value[0] + "_tooltip")}
                                        placement="top">
                                        <Box className="whtex-total-box-title">{t('whtex.' + value[0])}</Box>
                                    </Tooltip>
                                    <Box className={`whtex-total-box-value ${fontSize}`}>
                                        <Typography className={`${fontSize}`}>{getValue(value[1] || 0)}</Typography>
                                    </Box>
                                </Box>
                            )
                        })
                        }
                        {/* TOTAL OWN UNITS QUANTITY */}

                        <Box sx={{ textAlign: 'center' }} key={'aft_owned'}>
                            <Tooltip className={`column-label ${fontSize}`} sx={{ pb: 1 }}
                                title={t("whtex.aft_owned_tooltip")}
                                placement="top">
                                <Box className="whtex-total-box-title">{t('whtex.aft_owned')}</Box>
                            </Tooltip>
                            <Box className={`whtex-total-box-value ${fontSize}`}>
                                <Typography className={`${fontSize}`}>{balanceAft?.data?.data?.owned || 0}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </CardContent>
            <CardContent sx={{pl: '20px', pr: '20px'}}>
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    loading={isLoading || isRefetching}
                    autoHeight
                    onRowClick={({ row }) => setDetail(row)}
                    disableColumnSelector={true}
                    hideFooterSelectedRowCount={true}
                    headerHeight={80}
                    getRowSpacing={(params) => {
                        return {top: 4, bottom: 4}
                    }}
                    {...GlobalConfig.dataGridConfig}
                />
            </CardContent>
        </Card>
    )
}