import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CardHeader, Typography } from "@mui/material"
import { DataGrid, GridColumns } from "@mui/x-data-grid"
import { useCustomMutation } from "@refinedev/core";
import { DateField, useDataGrid } from "@refinedev/mui";
import React from "react";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";

export default function ListWithErrors({ }) {
    const { dataGridProps: dataGridPropsMasterpieceErrors } = useDataGrid({
        resource: "masterpieces",
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            refetchInterval: 5000,
            queryKeyHashFn: () => 'is_started_tokenizer=1&is_error=1',
            queryHash: 'is_started_tokenizer=1&is_error=1'
        },
        filters: {
            permanent: [
                {
                    field: "is_started_tokenizer",
                    operator: "eq",
                    value: "1",
                },
                {
                    field: "is_error",
                    operator: "eq",
                    value: "1"
                }
            ]
        }
    })

    const { mutate: restore, isLoading, variables } = useCustomMutation<{ masterpiece_filename: string }>()
    const handleRelaunch = (masterpiece_filename) => {
        restore({
            url: '/tokenizer/masterpiece/restore_mint',
            method: 'post',
            values: {
                masterpiece_filename
            }
        })
    }
    const handleRestore = (masterpiece_filename) => {
        restore({
            url: '/tokenizer/masterpiece/reset_if_error',
            method: 'post',
            values: {
                masterpiece_filename
            }
        })
    }




    const columnsMasterpiecesErrors = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "masterpiece_filename", headerName: "Masterpiece Filename", flex: 1,
            },
            {
                field: "created", headerName: "Date of Upload", type: "date", flex: 1,
                renderCell: ({ value }) => <DateField format="DD/MM/YYYY" value={value} />
            },
            {
                field: "updated", headerName: "Last Update (UTC)", type: "date", flex: 1,
                renderCell: ({ value }) => <DateField format="DD/MM/YYYY HH:mm:ss" value={value} />
            },
            {
                field: "error_description", headerName: "Error", flex: 4,
            },
            {
                field: 'action', flex: 1.5, renderCell: ({ row }) => {
                    return <>
                        {/* <LoadingButton loading={isLoading} onClick={() => {
                            handleRelaunch(row.masterpiece_filename)
                        }} >Relaunch</LoadingButton> */}
                        <LoadingButton loading={isLoading} onClick={() => {
                        handleRestore(row.masterpiece_filename)
                        }} >Reset</LoadingButton>
                    </>
                }
            }
        ], [dataGridPropsMasterpieceErrors]);
    console.log('dataGridPropsMasterpieceErrors.rows', dataGridPropsMasterpieceErrors.rows)


    return <>{!dataGridPropsMasterpieceErrors.loading && dataGridPropsMasterpieceErrors.rows.length > 0 ?
        <Card sx={{ mb: 4 }} title={'AFT in error'}>
            <CardHeader title={'AFT in error'} />
            <CardContent>
                <DataGrid getRowId={(row) => row.masterpiece_filename} {...dataGridPropsMasterpieceErrors}
                    columns={columnsMasterpiecesErrors} autoHeight {...GlobalConfig.dataGridConfig}
                    hideFooter={true} />
            </CardContent>
        </Card>
        : null}</>




}