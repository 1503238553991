import { Card, CardHeader, CardContent, Typography, Alert } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { Show, ShowButton, useDataGrid } from '@refinedev/mui'
import React, { useMemo } from 'react'
import { GlobalConfig } from 'shared-libs/src/GlobalConfig'
import { Status } from 'shared-libs/src/models'

export default function BCIDHistory({ bcid }) {

    const { dataGridProps } = useDataGrid({
        resource: "bcid_history",
        filters: {
            permanent: [
                { field: 'bcid', operator: 'eq', value: parseInt(`${bcid}`) }
            ]
        }
    })
    const dataGridCommonProps = {
        disableColumnSelector: true,
        autoHeight: true,
        ...GlobalConfig.dataGridConfig
    }



    const columns = useMemo<GridColumns>(() => [
        { flex: 1, field: 'id', headerName: 'ID', type: "number" },
        { flex: 1, field: 'profile_data.firstname', headerName: "First Name", valueGetter: ({ row: { profile_data } }) => profile_data.firstname },
        { flex: 1, field: 'profile_data.lastname', headerName: "Last Name", valueGetter: ({ row: { profile_data } }) => profile_data.lastname },
        { flex: 1, field: 'profile_data.company_name', headerName: "Company Name", valueGetter: ({ row: { profile_data } }) => profile_data.company_name },
        { flex: 1, field: 'user_status', headerName: "Status", renderCell: ({ row }) => Status.getLabel(row.user_status) },

        {
            flex: 1, field: '', headerName: '', renderCell: ({ row }) => {
                const { id } = row
                return <ShowButton recordItemId={id} />
            }
        }

    ], [])

    if (dataGridProps.rowCount == 0) {
        return null;
    }


    return <Card sx={{ mb: '30px' }}>
        <CardHeader className="panel-header" title={"BCID History: " + bcid} />
        <CardContent sx={{ pl: '20px', pr: '20px' }}>
            <Alert color='info'><Typography variant='body2'>The BCID {bcid} has been transfered to this account. This is the history of the BCID</Typography></Alert>
            <DataGrid getRowId={({ id }) => id} {...dataGridProps} columns={columns} {...dataGridCommonProps} />
        </CardContent>
    </Card>


}