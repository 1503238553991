import { IconButton, List, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useCustomMutation, useModal } from '@refinedev/core';
import { useDataGrid } from '@refinedev/mui'
import React, { useCallback, useEffect, useState } from 'react'
import { GlobalConfig } from 'shared-libs/src/GlobalConfig';
import { Done, DoneAll, DoNotDisturb } from '@mui/icons-material';
import { User } from 'shared-libs/src/interfaces';
import OTPModal from 'components/OtpModal';

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}



export default function BurnList() {


    const { dataGridProps, tableQueryResult: { refetch, ...tableQueryResult } } = useDataGrid({
        resource: 'burn_requests',
        filters: {
            permanent: [
                { field: 'status', value: 101, operator: 'eq' }
            ]
        }
    })

    const { mutate: approve, isLoading } = useCustomMutation()

    const handleApproveButton = (aftcontract_id, bcid, otp) => {
        approve({
            url: '/aftoriginal/burn',
            method: 'post',
            values: {
                aftcontract_id,
                bcid,
                otp
            }
        }, {
            onSuccess: () => {
                return refetch()
            },
            onError: () => {
                refetch()
            }
        })
    }
    const [currentData, setCurrentData] = useState<{ aftcontract_id?: string, bcid?: number }>({})
    const modal = useModal();
    useEffect(() => {
        if (currentData?.aftcontract_id && currentData?.bcid) {
            modal.show()
        }
    }, [currentData])






    const columns: GridColumns<{
        aftcontract_id: string,
        bcid: number,
        bcid_data: User,
        created?: string,
        updated?: string,
        artwork?: any
    }> = React.useMemo(() => [
        { field: "aftcontract_id", valueGetter: ({ row }) => row.artwork?.work_of_ingenuity || row?.aftcontract_id, headerName: "Masterpiece ID", flex: 1, type: "number", headerAlign: "left" },
        { field: "bcid_data", headerName: "User", flex: 1, renderCell: ({ row }) => `${row.bcid_data?.profile_data?.firstname} ${row.bcid_data?.profile_data?.lastname}` },
        {
            headerName: "Actions",
            field: "actions",
            renderCell: function render(params) {
                return <IconButton disabled={isLoading} onClick={() => {
                    setCurrentData({ aftcontract_id: params.row?.aftcontract_id, bcid: params?.row?.bcid })
                }
                } ><Done /></IconButton>
            },
            ...GlobalConfig.actionColumnConfig
        },
    ], []);

    return (<>
        <OTPModal confirmText='Confirm' message='Are you sure you want to burn?' modal={modal} onConfirm={async (otp) => {
            handleApproveButton(currentData?.aftcontract_id, currentData?.bcid, otp)
        }} title='OTP' />
        <List>
            <DataGrid getRowId={({ aftcontract_id, bcid }) => `${aftcontract_id}-${bcid}`}  {...dataGridProps} {...dataGridCommonProps} columns={columns} loading={tableQueryResult.isLoading || isLoading || tableQueryResult.isRefetching} />
        </List>
    </>
    );
}