import { Edit } from "@refinedev/mui";
import { Box, TextField, Switch, Typography, Stack } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useResource, useUpdate } from "@refinedev/core";
import { IAnnouncements } from "shared-libs/src/interfaces";
import { useEffect, useRef } from "react";
import BundledEditor from "shared-libs/src/components/BundledEditor";
import '../pages.scss'

export const AnnouncementsEdit = () => {
    useEffect(() => {
        document.title = 'Whtexch - Announcements';
    }, []);
    const {
        saveButtonProps,
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm();
    const { mutate: update } = useUpdate<IAnnouncements>();
    const { id } = useResource()

    const editorRef = useRef(null);

    const onFinish = (params) => {
        // @ts-ignore
        params.content = editorRef?.current.getContent()
        return update({
            resource: "announcements",
            id: params.announcements_id,
            values: params,
            mutationMode: "pessimistic"
        })
    }

    return (
        <Edit recordItemId={id ? parseInt(id.toString()) : undefined} title={<Typography variant="h5">Back</Typography>} saveButtonProps={{ ...saveButtonProps, onClick: handleSubmit(onFinish) }} canDelete={true} headerButtons={() => null} >
            <Box
                component="form"
                autoComplete="off"
                className={"input-form"}
            >
                <TextField
                    {...register("announcement_id", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label="Announcements ID"
                    name="Announcements ID"
                    disabled
                />

                <TextField
                    {...register("title", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Title"
                    name="title"
                />


                <Controller
                    name={"content"}
                    control={control}
                    render={({ field }) => {
                        return <BundledEditor
                            {...field}
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={field.value}
                        />
                    }
                    }
                />

                <TextField
                    {...register("valid_until", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.valid_until}
                    helperText={(errors as any)?.valid_until?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="datetime-local"
                    label="Valid Until"
                    name="valid_until"
                />

            </Box>
        </Edit>
    );
};
