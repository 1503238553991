import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CardHeader, Icon, Typography } from "@mui/material"
import { DataGrid, GridColumns } from "@mui/x-data-grid"
import { useCustomMutation, useNotification } from "@refinedev/core";
import { DateField, useDataGrid } from "@refinedev/mui";
import React, { useState } from "react";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";

const DEFAULT_REFETCH_INTERVAL = 5000

export default function ListCompletedToAddToExchange({ onAddComplete }) {
    const [refetchInterval, setRefetchInterval] = useState<number | false>(DEFAULT_REFETCH_INTERVAL)

    const { open } = useNotification()
    const { dataGridProps: dataGridPropsMasterpieceErrors, tableQueryResult: { refetch } } = useDataGrid({
        resource: "masterpieces",
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            refetchInterval,
            cacheTime: 0,
            queryKeyHashFn: () => 'is_started_tokenizer=1&is_ended_tokenizer=1&is_error=0&is_added_to_exchange=0',
            queryHash: 'is_started_tokenizer=1&is_ended_tokenizer=1&is_error=0&is_added_to_exchange=0'
        }, 
        filters: {
            permanent: [
                {
                    field: "is_started_tokenizer",
                    operator: "eq",
                    value: "1",
                },
                {
                    field: "is_ended_tokenizer",
                    operator: "eq",
                    value: "1"
                },
                {
                    field: "is_error",
                    operator: "eq",
                    value: "0"
                }, {
                    field: 'is_added_to_exchange',
                    operator: 'eq',
                    value: "0"
                }

            ]
        }
    })

    const { mutate: addToExchange, isLoading, variables } = useCustomMutation<{ masterpiece_filename: string }>()
    const handleAddToExchange = (masterpiece_filename, premarket_limit_date) => {
        setRefetchInterval(false)
        window.onbeforeunload = function () {
            open?.({ message: 'Operation still in progress', type: 'error' })
            return "Opertion still in progress. ";
        }
        addToExchange({
            url: '/tokenizer/masterpiece/add_to_exchange',
            method: 'post',
            values: {
                masterpiece_filename,
                premarket_limit_date
            }
        }, {
            onSuccess: () => {
                onAddComplete();
                window.onbeforeunload = null;
                setRefetchInterval(DEFAULT_REFETCH_INTERVAL)
                refetch()
            }
        })
    }




    const columnsMasterpiecesErrors = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "masterpiece_filename", headerName: "Masterpiece Filename", flex: 1,
            },
            {
                field: "aftcontract_id", headerName: "Masterpiece ID", flex: 1, valueGetter: ({ row }) => row.artwork?.work_of_ingenuity || row.aftcontract_id
            },
            {
                field: "created", headerName: "Date of Upload", type: "date", flex: 1,
                renderCell: ({ value }) => <DateField format="DD/MM/YYYY" value={value} />
            },
            {
                field: "updated", headerName: "Last Update (UTC)", type: "date", flex: 1,
                renderCell: ({ value }) => <DateField format="DD/MM/YYYY HH:mm:ss" value={value} />
            },
            {
                field: "auto_add_after", headerName: "Scheduled to be added at (UTC)", type: "date", flex: 1,
                renderCell: ({ value }) => <DateField format="DD/MM/YYYY HH:mm:ss" value={value} />
            },
            {
                field: 'action', renderCell: ({ row }) => {
                    return <LoadingButton loading={isLoading} onClick={() => {
                        handleAddToExchange(row.masterpiece_filename, row.premarket_limit_date)
                    }} >ADD</LoadingButton>
                }
            }
        ], [dataGridPropsMasterpieceErrors]);
    console.log('dataGridPropsMasterpieceErrors.rows', dataGridPropsMasterpieceErrors.rows)


    return <>{!dataGridPropsMasterpieceErrors.loading && dataGridPropsMasterpieceErrors.rows.length > 0 ?
        <Card sx={{ mb: 4 }} title={'Completed - Ready to add to exchange'}>
            <CardHeader title={'Completed - Ready to add to exchange'} />
            <CardContent>
                <DataGrid getRowId={(row) => row.masterpiece_filename} {...dataGridPropsMasterpieceErrors}
                    columns={columnsMasterpiecesErrors} autoHeight {...GlobalConfig.dataGridConfig}
                    hideFooter={true} />
            </CardContent>
        </Card>
        : null}</>




}