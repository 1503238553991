import { Button, IconButton, List, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useCustomMutation, useModal } from '@refinedev/core';
import { DateField, useDataGrid } from '@refinedev/mui'
import React, { useEffect, useState } from 'react'
import { GlobalConfig } from 'shared-libs/src/GlobalConfig';
import { Done, DoneAll, DoNotDisturb } from '@mui/icons-material';
import { User } from 'shared-libs/src/interfaces';

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}


const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY" value={params.value} />;
}

export default function DownloadedAndBurnedList({ }) {
    const { dataGridProps, tableQueryResult: { refetch, ...tableQueryResult } } = useDataGrid({
        resource: 'preview_requests',
        pagination: {
            mode: 'off'
        },
        filters: {
            permanent: [
                { field: 'status', value: 120, operator: 'eq' }
            ]
        },
        sorters: {
            initial: [
                { field: 'updated', order: 'desc' }
            ]
        },

    })





    const columns = React.useMemo<GridColumns<{
        aftcontract_id: string,
        bcid: number,
        is_executed: number,
        bcid_data?: User,
        approved_one_by_data?: User,
        approved_two_by_data?: User,
        created: string,
        updated: string,
        artwork?: any
    }>>(() => [
        { field: "aftcontract_id", valueGetter: ({ row }) => row.artwork?.work_of_ingenuity || row?.aftcontract_id, headerName: "Masterpiece ID", flex: 1, type: "number", headerAlign: "left" },
        { field: "tokenizer_error_description", headerName: "Error", flex: 1, headerAlign: "left" },
        { field: "bcid_data", headerName: "User", flex: 1, renderCell: ({ row }) => `${row.bcid_data?.profile_data?.firstname} ${row.bcid_data?.profile_data?.lastname}` },
        { field: "approved_one_by_data", headerName: "Approved by", flex: 1, renderCell: ({ row }) => row.approved_one_by_data ? `${row.approved_one_by_data?.profile_data?.firstname} ${row.approved_one_by_data?.profile_data?.lastname}` : '-' },
        { field: "approved_two_by_data", headerName: "Confirmed by", flex: 1, renderCell: ({ row }) => row.approved_two_by_data ? `${row.approved_two_by_data?.profile_data?.firstname} ${row.approved_two_by_data?.profile_data?.lastname}` : '-' },
        { field: "created", headerName: "Created", flex: 1, renderCell: dateRender },
        { field: "updated", headerName: "Last update", flex: 1, renderCell: dateRender },


    ], []);

    return (<>

        <List>
            <DataGrid getRowId={({ aftcontract_id, bcid }) => `${aftcontract_id}-${bcid}`}  {...dataGridProps} {...dataGridCommonProps} columns={columns} loading={tableQueryResult.isLoading || tableQueryResult.isRefetching} />
        </List>
    </>
    );
}