import { HttpError, useCustomMutation, useUpdate } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { Box, TextField, Autocomplete, FormControl, Typography, Grid, Tooltip } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import {IUser} from "shared-libs/src/interfaces";
import React, { useEffect, useState } from "react";
import { WhitelistManager } from "components/WhitelistManager";
const roles: { [key: number]: [string, string] } = {
    1: ['Market participant', 'Permesso di trading. Utente verificato sia dai manager che dal board'],
    20: ['Manager', 'Permesso di validare un utente (controllo gestione)'],
    21: ['Board Member', 'Permesso di validare un utente (controllo consiglio amministrazione)'],
    22: ['News Admin', 'AMMINISTRATORE CHE può gestire news'],
    23: ['Ticket Assistant', 'AMMINISTRATORE CHE può gestire i ticket'],
    30: ['WHT movements validator', 'Verifica i movimenti in wht degli utenti'],
    31: ['WHT movements appover above threshold', 'Approva i movimenti in WHT già validati che superano la soglia di alert'],
    40: ['AFT Minter', 'Può mintare nuove opere'],
    99: ['Admin', 'Ruolo assegato a tutti gli admin'],
    100: ['Super Admin', 'Può fare tutto.'],
}
export const UserEdit: React.FC = () => {
    useEffect(() => {
        document.title = 'Whtexch - Edit User/Customer';
    }, []);
    const { mutate: update, error, } = useUpdate<IUser>();
    const {
        refineCore: {formLoading, queryResult},
        saveButtonProps,
        register,
        control,
        handleSubmit,
        formState: {errors, isDirty, dirtyFields},
    } = useForm<IUser, HttpError, IUser>({ defaultValues: { profile_data: {} } });
    const userId = queryResult?.data?.data.user_id || ""
    const profileModelId = queryResult?.data?.data?.profile_model_id
    const [profileData, setProfileData] = useState<any>({})
    const { mutate, data } = useCustomMutation({});

    useEffect(() => {
        if (data) {
            setProfileData(data?.data)
        }
    }, [data])


    useEffect(() => {
        if (profileModelId) {
            mutate({
                url: '/user/get_profile_data_fields',
                values: { "profile_model_id": profileModelId },
                method: 'post'
            })
        }
    }, [profileModelId])

    const { mutate: roleManager } = useCustomMutation({})
    const onFinish = (params) => {
        if (!isDirty) {
            return
        }
        const currentRoles = queryResult?.data?.data.roles || []
        Object.keys(dirtyFields).forEach(k => {
            if (k == 'roles') {
                const newRoles = params['roles'] || [];
                currentRoles?.filter(el => !newRoles.includes(el)).map(el => {
                    // role to delete
                    roleManager({
                        url: '/user/role/remove',
                        method: 'post',
                        values: {
                            user_id: queryResult?.data?.data.user_id,
                            role_id: el
                        }
                    })
                })
                newRoles?.filter(el => !currentRoles.includes(el)).sort().map(el => {
                    // role to add
                    roleManager({
                        url: '/user/role/add',
                        method: 'post',
                        values: {
                            user_id: queryResult?.data?.data.user_id,
                            role_id: el
                        }
                    })
                })

            } else {
                if (k === 'profile_data') {
                    Object.entries(params[k]).map(([k1, v1]) => {
                        update({
                            resource: "users",
                            id: userId,
                            values: { field: k1, value: v1 },
                            mutationMode: "pessimistic"
                        })
                    })
                } else {
                    update({
                        resource: "users",
                        id: userId,
                        values: { field: k, value: params[k] },
                        mutationMode: "pessimistic"
                    })
                }
            }
        });
    }
    const showField = (fieldName) => {
        return profileData.optionals && profileData.required && (profileData.optionals.includes(fieldName) || profileData.required.includes(fieldName))
    }
    return (
        <Box sx={{maxWidth: '900px', margin: '0 auto'}} >
            <Edit  title={<Typography variant="h5">Back</Typography>}  isLoading={formLoading} saveButtonProps={{...saveButtonProps, onClick: handleSubmit(onFinish)}}
                mutationMode={"pessimistic"} headerButtons={() => null }>
                <Box
                    component="form"
                    sx={{display: "flex", flexDirection: "column"}}
                    autoComplete="off"
                >
                    <FormControl>
                        <Grid  sx={{maxWidth: '800px', margin: '0 auto'}} container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="user_id"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        {...register("user_id", {disabled: true})}
                                        margin="normal"
                                        fullWidth
                                        id="user_id"
                                        label="User ID"
                                        defaultValue={""}
                                    />
                                )}
                            />
                        </Grid>
                            {showField('firstname') && <Grid item xs={6}>
                            <Controller
                                control={control}
                                    name="profile_data.firstname"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        {...register("profile_data.firstname", { required: "Name is required" })}
                                        error={!!errors?.profile_data?.firstname}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="firstname"
                                        label="Name"
                                        defaultValue={""}
                                    />
                                )}
                            />
                            </Grid>}
                            {showField('lastname') && <Grid item xs={6}>
                            <Controller
                                control={control}
                                    name="profile_data.lastname"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        {...register("profile_data.lastname", { required: "Last Name is required" })}
                                        error={!!errors?.profile_data?.lastname}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="lastname"
                                        label="Last Name"
                                        defaultValue={""}
                                    />
                                )}
                            />
                            </Grid>}
                            {showField('phone') && <Grid item xs={6}>
                            <Controller
                                control={control}
                                    name="profile_data.phone"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        {...register("profile_data.phone", { required: "Telephone is required" })}
                                        error={!!errors?.profile_data?.phone}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="phone"
                                        label="Telephone"
                                        defaultValue={""}
                                    />
                                )}
                            />
                            </Grid>}

                            {showField('iban') && <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="profile_data.iban"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("profile_data.iban", { required: "Name is required" })}
                                            error={!!errors?.profile_data?.iban}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="iban"
                                            label="IBAN"
                                            defaultValue={""}
                                        />
                                    )}
                                />
                            </Grid>}
                            {showField('tax_number') && <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="profile_data.tax_number"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("profile_data.tax_number", { required: "Last Name is required" })}
                                            error={!!errors?.profile_data?.tax_number}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="profile_data.tax_number"
                                            label="Tax Number"
                                            defaultValue={""}
                                        />
                                    )}
                                />
                            </Grid>}

                            {showField('company_name') && <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="profile_data.company_name"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("profile_data.company_name", { required: "Name is required" })}
                                            error={!!errors?.profile_data?.company_name}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="company_name"
                                            label="Company Name"
                                            defaultValue={""}
                                        />
                                    )}
                                />
                            </Grid>}
                            {showField('company_website') && <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="profile_data.company_website"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("profile_data.company_website", { required: "Last Name is required" })}
                                            error={!!errors?.profile_data?.company_website}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="profile_data.company_website"
                                            label="Company Website"
                                            defaultValue={""}
                                        />
                                    )}
                                />
                            </Grid>}

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="roles"
                                    render={({ field: { value, ...field } }) => {
                                        return (

                                            <Autocomplete<number, true, false>
                                                multiple={true}
                                                // {...field}
                                                disabled={value?.includes(1)}
                                                {...register("roles", {})}
                                                {...field}
                                                fullWidth
                                                getOptionLabel={(opt) => {
                                                    return roles[`${opt}`][0]
                                                }}
                                                options={Object.keys(roles).map((el) => {
                                                    return parseInt(el)
                                                })}
                                                id="roles"
                                                onChange={(evt, value) => {
                                                    field.onChange(value);
                                                }}
                                                value={value || []}
                                                renderOption={(props, option) => {
                                                    return <Tooltip title={roles[`${option}`][0]}><Typography {...props}>{roles[`${option}`][0]}</Typography></Tooltip>
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Roles" />}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="email"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        {...register("email", {required: "Email is required"})}
                                        error={!!errors?.email}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        defaultValue={""}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="email_is_verified"
                                rules={{required: "Email verified is required"}}
                                defaultValue={0}
                                render={({field}) => (
                                    <Autocomplete
                                        {...field}
                                        disabled
                                        options={[0, 1]}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(option) => (option) ? "yes" : "no"}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Email Verified"
                                                margin="normal"
                                                variant="outlined"
                                                disabled
                                                error={!!errors.email_is_verified}
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />
                            
                        </Grid>


                        <Grid item xs={6}>    
                            <Controller
                                control={control}
                                name="comments"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        {...register("comments")}
                                        error={!!errors?.comments}
                                        margin="normal"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        id="comments"
                                        label="Comments"
                                        defaultValue={""}
                                    />
                                )}
                            />
                            </Grid>
                            <Grid item xs={12}>
                                {queryResult?.data?.data?.hlf.filter(el => el.readonly == false).map(({ bcid }) => (
                                    <WhitelistManager title="Whitelist" bcid={bcid} />
                                ))}

                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>
            </Edit>
        </Box>
    );
};