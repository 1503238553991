import React, {useEffect} from "react";
import {useDataGrid, DateField, TagField, List, ShowButton} from "@refinedev/mui";

import {Box, Typography, Card, CardContent, CardHeader} from "@mui/material";
import {DataGrid, GridColumns} from "@mui/x-data-grid";

import {IUser} from "shared-libs/src/interfaces";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {Role, Status} from 'shared-libs/src/models';
import {GlobalConfig} from "shared-libs/src/GlobalConfig";

const roleRender = function render(params) {
    let returnElements: any = []
    params.value.forEach((role_id) => {
        returnElements.push(
            <TagField value={Role.getLabel(role_id)} className={`role_${role_id}`}/>
        )
    })
    return <div style={{ overflowX: 'auto' }}>{returnElements}</div>
}

const emailVerifiedRender = function render(params) {
    return params.value ? <MarkEmailReadIcon color={"success"}/> : <UnsubscribeIcon color={"warning"}/>
}

const userStatusRender = function render(params) {
    return <TagField value={Status.getLabel(params.value)} className={`status_${params.value}`}/>
}

const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY" value={params.value}/>;
}
const userIdRemote: any = {};
const userRender = function render(params) {
    let user = userIdRemote?.data?.find((item) => item.id === params.value)
    return userIdRemote.loading ? <>Loading...</> : <>{user?.profile_data.firstname} {user?.profile_data.lastname}</>;
}


const columnsCustomer: GridColumns<IUser> = [
    { field: "user_id", headerName: "User ID", flex: 1, type: "number", headerAlign: "left"},
    { field: "profile_data.company_name", headerName: "Company Name", flex: 1, valueGetter: ({ row: { profile_data } }) => profile_data.company_name },
    { field: "profile_data.firstname", headerName: "Name", flex: 1, valueGetter: ({ row: { profile_data } }) => profile_data.firstname },
    { field: "profile_data.lastname", headerName: "Last Name", flex: 1, valueGetter: ({ row: { profile_data } }) => profile_data.lastname },
    { field: "user_status", headerName: "User Status", flex: 1, renderCell: userStatusRender },
    {
        field: "email_is_verified",
        headerName: "Email Is Verified",
        flex: 1,
        align: "center",
        renderCell: emailVerifiedRender
    },
    {field: "under_review_by", headerName: "Under Review By", flex: 1, disableColumnMenu: true, renderCell: userRender},
    {field: "created", headerName: "Create date", flex: 1, renderCell: dateRender, type: "date"},
    {
        headerName: "Actions",
        field: "actions",
        renderCell: function render(params) {
            return <ShowButton variant="actionTable" hideText recordItemId={params.row.user_id}/>;
        },
        ...GlobalConfig.actionColumnConfig
    },
]

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}

export const UsersList: React.FC = () => {
    const validRoles = [Role.Super_Admin, Role.Admin_Ticket_Assistant, Role.Admin_Board_Validator, Role.Admin_News_Writer, Role.Admin_Manager_Validator, Role.Generic_Admin]
    useEffect(() => {
        document.title = 'Whtexch - Administrators';
    }, []);
    const {dataGridProps} = useDataGrid<IUser>({
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            refetchInterval: 10000,
            queryKeyHashFn: () => `adminslist`
        },
        filters: {
            permanent: [
                {
                    operator: "or",
                    value: validRoles.map(role => ({
                        field: 'roles',
                        operator: 'contains',
                        value: role
                    })),
                }
            ]
        }
    });



    const columns = React.useMemo<GridColumns<IUser>>(() => {
        return [
            { field: "user_id", headerName: "User ID", flex: 1, type: "number", headerAlign: "left" },
            { field: "firstname", headerName: "Name", flex: 1, valueGetter: ({ row }) => row?.profile_data.firstname },
            { field: "lastname", headerName: "Last Name", flex: 1, valueGetter: ({ row }) => row?.profile_data.lastname },
            { field: "user_status", headerName: "User Status", flex: 1, renderCell: userStatusRender },
            {
                field: "email_is_verified",
                headerName: "Email Is Verified",
                flex: 1,
                align: "center",
                renderCell: emailVerifiedRender
            },
            { field: "created", headerName: "Create date", flex: 1, renderCell: dateRender },
            { field: "roles", headerName: "Roles", flex: 5, renderCell: roleRender },
            {
                headerName: "Actions",
                field: "actions",
                renderCell: function render(params) {
                    return <ShowButton variant="actionTable" hideText recordItemId={params.row.user_id} />;
                },
                ...GlobalConfig.actionColumnConfig
            },
        ];
    }, [dataGridProps])
    return (
        <React.Fragment>
            <Box sx={{displax: 'flex'}} className="page-title-container">
                <Box>
                    <Typography variant="h1">Administrators</Typography>
                </Box>
            </Box>
            <Card sx={{mb: 2}}>
                <CardHeader className="panel-header" title="List"></CardHeader>
                <CardContent className="card-whtex">
                    <DataGrid {...dataGridProps} rows={dataGridProps?.rows?.filter(el => validRoles.filter(role => el?.roles?.includes(role)).length > 0)} {...dataGridCommonProps} columns={columns} key={'adminlist'} />
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export const CustomerList: React.FC = () => {
    useEffect(() => {
        document.title = 'Whtexch - Users';
    }, []);
    const {dataGridProps} = useDataGrid<IUser>({
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            staleTime: 1000,
            queryKeyHashFn: () => `customerslist`
        },
        filters: {
            permanent: [
                {
                    operator: 'or',
                    value: [{
                        field: 'roles',
                        operator: 'contains',
                        value: null
                    }, {
                        field: 'roles',
                        operator: 'contains',
                        value: Role.MarketPartecipant
                    }],
                }
            ]
        }
    });
    const {dataGridProps: dataGridPropsUser} = useDataGrid<IUser>({
        resource: 'users',
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions
        },
        filters: {
            permanent: [
                {
                    operator: "or",
                    value: [{
                        field: 'roles',
                        operator: 'contains',
                        value: Role.Super_Admin
                    }, {
                        field: 'roles',
                        operator: 'contains',
                        value: Role.Admin_Ticket_Assistant
                    }, {
                        field: 'roles',
                        operator: 'contains',
                        value: Role.Admin_Board_Validator
                    }, {
                        field: 'roles',
                        operator: 'contains',
                        value: Role.Admin_News_Writer
                    }, {
                        field: 'roles',
                        operator: 'contains',
                        value: Role.Admin_Manager_Validator
                    }],
                }
            ]
        }
    });
    userIdRemote.data = dataGridPropsUser.rows
    userIdRemote.loading = dataGridPropsUser.loading
    return (
        <React.Fragment>
            <Box sx={{displax: 'flex'}} className="page-title-container">
                <Box>
                    <Typography variant="h1">Users</Typography>
                </Box>
            </Box>
            <Card sx={{mb: 2}}>
                <CardHeader className="panel-header" title="List"></CardHeader>
                <CardContent className="card-whtex">
                    <DataGrid {...dataGridProps} {...dataGridCommonProps} columns={columnsCustomer} key='customerslist' />
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

