import { CrudFilter, CrudFilters, useList } from "@refinedev/core"
import { useDataGrid } from "@refinedev/mui";
import { AftActionButton } from "./AftActionButton";

interface IAftFilesProps {
    aftcontract_id: string
}
const getLabel = type => {
    switch (type) {
        case 'whitepaper': return 'Whitepaper';
        case 'valuation': return 'Valuation & Expert Report';
        default: return type;
    }
}

const getType = filename => {
    return filename.split('.')[0];
}

export function AftFiles({ aftcontract_id }: IAftFilesProps) {
    const filters: CrudFilters = [
        {
            field: "aftcontract_id",
            operator: "eq",
            value: aftcontract_id,
        }
    ];

    const { data, refetch } = useList({
        resource: 'documents',
        queryOptions: { enabled: !!aftcontract_id },

        filters
    });
    const handleRefetch = () => {
        refetch()
    }



    return <>{data?.data.map(el => {
        const type = getType(el.filename);

        return <AftActionButton onRefetch={handleRefetch} aft_id={aftcontract_id} type={type} label={getLabel(type)} />
    })}
        <AftActionButton onRefetch={handleRefetch} aft_id={aftcontract_id} type={''} label={<i>Add new file</i>} />

    </>

}