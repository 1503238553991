import React, {useEffect} from "react";
import {useDataGrid, DateField, BooleanField, ShowButton, EditButton, List} from "@refinedev/mui";
import { Box, Stack } from "@mui/material";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import {INews} from "shared-libs/src/interfaces";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";

const dateRender = function render(params) {
    return <DateField format="YYYY/MM/DD" value={params.value}/>;
}

const columns: GridColumns<INews> = [
    {field: "news_id", headerName: "News ID", flex: 1, type: "number", headerAlign: "left"},
    {field: "title", headerName: "Title", flex: 1},
    {
        field: "body", headerName: "Body", flex: 1, renderCell: (params) => {
            return <Box sx={{}} dangerouslySetInnerHTML={{ __html: params.value }} />
        }
    },
    {
        field: "is_public", headerName: "Is Public", flex: 1, align: "center", renderCell: (params) => {
            return <BooleanField value={params.value}/>
        }
    },
    {field: "created", headerName: "Created At", flex: 1, type: "date", renderCell: dateRender},
    {
        headerName: "Actions",
        field: "actions",
        minWidth: 200,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        renderCell: function render(params) {
            return <Stack gap={2} direction={"row"}>
                <ShowButton variant="actionTable" hideText recordItemId={params.row.news_id}/>
                <EditButton variant="actionTable" hideText recordItemId={params.row.news_id}/>
            </Stack>;
        },
    },
];

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}

export const NewsList: React.FC = () => {
    useEffect(() => {
        document.title = 'Whtexch - News';
    }, []);
    const {dataGridProps} = useDataGrid<INews>({
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.adminQueryOptions
        }
    });
    return (
        <List canCreate={true} createButtonProps={{variant: 'actionDetailPage'}}>
            <DataGrid {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};