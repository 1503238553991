import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Paper, Table, TableContainer, Typography } from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useModal } from "@refinedev/core";
import { useCurrencyStore } from "hooks/useCurrency";
import { useEffect } from "react";
import { IAftLite } from "shared-libs/src/interfaces";

import { useSyncExternalStore } from 'react'

const dateRender = value => value && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8) + " " + [value.substring(8, 10), value.substring(10, 12), value.substring(12, 14),].join(':')

export const OrderMatchesDialog = ({ order, setOrder }: { order: IAftLite, setOrder: any }) => {



    const detailsModal = useModal();


    const { currency, toggleCurrency, getValue, init } = useCurrencyStore()

    useEffect(() => {
        if (order && !detailsModal.visible) {
            detailsModal.show()
        }

    }, [order])


    const matches = order.matches


    const budget = order.order_size * order.price_for_unit


    const budgetSpent = matches.reduce((total, match) => total + (match.number_of_token * match.price_for_unit), 0)
    const budgetSaved = matches.reduce((total, match) => total + (match.number_of_token * order.price_for_unit), 0) - budgetSpent

    const savings = Math.trunc(budgetSaved / budgetSpent * 100)

    const budgetStillAllocated = order.price_for_unit * order.not_executed;





    return <Dialog fullWidth open={detailsModal.visible} onClose={() => { setOrder(null) }}>
        {init}
        <DialogTitle>Details</DialogTitle>
        <DialogContent>

            <TableContainer component={Box}>
                <Typography component={'div'} sx={{ paddingLeft: 2 }}>Currency: <Button variant="text" color="info" onClick={toggleCurrency}>{currency}</Button></Typography>

                <Typography variant="subtitle1" component="div" sx={{ padding: 2 }}>
                    <Table>
                        <TableBody>
                            <TableRow><TableCell>Order Initial Size:</TableCell><TableCell> {order.order_size}</TableCell></TableRow>
                            <TableRow><TableCell>Order Matched:</TableCell><TableCell> {order.order_size - order.not_executed}</TableCell></TableRow>
                            <TableRow><TableCell>Order Not executed yet:</TableCell><TableCell> {order.not_executed || '-'}</TableCell></TableRow>
                            <TableRow><TableCell>Price Per Unit:</TableCell><TableCell> {getValue(order.price_for_unit)}</TableCell></TableRow>
                            <TableRow><TableCell>Initial Budget Allocated:</TableCell><TableCell> {getValue(budget)} ({order.order_size} x {order.price_for_unit})</TableCell></TableRow>
                            <TableRow><TableCell>Budget spent:</TableCell><TableCell> {getValue(budgetSpent)} {savings > 0 ? `( saved ${savings}%)` : null}</TableCell></TableRow>
                        </TableBody>
                    </Table>
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ padding: 2 }}>
                    Executions:

                    <LinearProgress color="success" variant="determinate" value={order.matched} />

                </Typography>
                {order.matches.length > 0 && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Units</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>WHT</TableCell>
                            <TableCell>€</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.matches.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{dateRender(row.creation_date)}</TableCell>
                                <TableCell>{row.number_of_token}</TableCell>
                                <TableCell>{row.price_for_unit}</TableCell>
                                <TableCell>{row.price_for_unit * row.number_of_token}</TableCell>
                                <TableCell>{row.price_for_unit * row.number_of_token * 0.01}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>}
                {order.matches.length == 0 && <Typography component={'i'} sx={{ padding: 4 }}>No matches</Typography>}
                {budgetStillAllocated > 0 && <Typography variant="subtitle1" component="div" sx={{ padding: 2 }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Budget left</TableCell>
                                <TableCell>{getValue(budgetStillAllocated)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Typography>}
            </TableContainer>




        </DialogContent>
        <DialogActions>
            <Box>
                <Button variant="outlined" onClick={() => { setOrder(null) }}>Close</Button>
            </Box>
        </DialogActions>
    </Dialog>
}