import { Edit } from "@refinedev/mui";
import { Box, TextField, Switch, Typography, Stack } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useUpdate } from "@refinedev/core";
import {INews} from "shared-libs/src/interfaces";
import {useEffect, useRef} from "react";
import BundledEditor from "shared-libs/src/components/BundledEditor";
import '../pages.scss'

export const NewsEdit = () => {
    useEffect(() => {
        document.title = 'Whtexch - News';
    }, []);
    const {
        saveButtonProps,
        register,
        control,
        formState: {errors},
        handleSubmit
    } = useForm();
    const {mutate: update} = useUpdate<INews>();

    const editorRef = useRef(null);

    const onFinish = (params) => {
        // @ts-ignore
        params.body = editorRef?.current.getContent()
        return update({
            resource: "news",
            id: params.news_id,
            values: params,
            mutationMode: "pessimistic"
        })
    }

    return (
        <Edit title={<Typography variant="h5">Back</Typography>} saveButtonProps={{...saveButtonProps, onClick: handleSubmit(onFinish)}} canDelete={true} headerButtons={() => null} >
            <Box
                component="form"
                autoComplete="off"
                className={"input-form"}
            >
                <TextField
                    {...register("news_id", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    type="number"
                    label="News ID"
                    name="News ID"
                    disabled
                />

                <TextField
                    {...register("title", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    label="Title"
                    name="title"
                />

                <Controller
                    control={control}
                    name="is_public"
                    render={({field}) => (
                        <Stack direction={"row"} gap={4} className={"stack-switch"}>
                            <Typography variant={"body1"} className={"label"}>Is Public</Typography>
                            <Switch id={"is_public"}
                                    {...field}
                                    checked={field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked);
                                    }}
                            />
                        </Stack>
                    )}
                />

                <Controller
                    name={"body"}
                    control={control}
                    render={({field}) => {
                        return <BundledEditor
                            {...field}
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={field.value}
                        />
                    }
                    }
                />

            </Box>
        </Edit>
    );
};
